import { Box, Flex, SimpleGrid } from "@kvib/react"
import { useTranslation } from "react-i18next"
import PopoverikonMedTooltip from "../../../../core/components/PopoverikonMedTooltip"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"
import VerdiMedForklaring from "../../../components/VerdiMedForklaring"
import { useKodelister } from "../../../kodelister/useKodelister"
import { hentUtfraKodeliste } from "../../../kodelister/utils"
import useCurrentBygning from "../../hooks/useCurrentBygning"
import { Bruksenhet } from "../../types/DetaljertEiendom"
import { formaterBruksareal } from "../utils/bygningerUtils"
import { formaterEtasjetekst } from "../utils/etasjeUtil"

const NøkkelinfoOmAlleBruksenheterIBygning = ({ byggId }: { byggId: string }) => {
  const { dineBruksenheterIBygning } = useCurrentBygning(byggId)

  const { t } = useTranslation(["bruksenhetskort", "nøkkelbegreper"])
  const { data: kodelister } = useKodelister()

  if (!kodelister || !dineBruksenheterIBygning) return null

  const sortertSåBruksenheterIHovedetasjeKommerFørst = (a: Bruksenhet, b: Bruksenhet) => {
    const aIsH = a.bruksenhetsnummer.startsWith("H") ? 0 : 1
    const bIsH = b.bruksenhetsnummer.startsWith("H") ? 0 : 1
    return aIsH - bIsH
  }

  const hjelpetekstBruksenhetsnummer = t("bruksenhetsnummer_forklaring", { ns: "bruksenhetskort" })
  const unummerertBruksenhetForklaring = t("unummerertBruksenhet_forklaring", {
    ns: "bruksenhetskort",
  })
  const helpetekstBruksenhetstype = t("bruksenhetstype_forklaring", { ns: "bruksenhetskort" })
  const bruksarealForEnBruksenhetForklaring = t("bruksareal_for_en_bruksenhet_forklaring", {
    ns: "bruksenhetskort",
  })
  const etasjeForInngangHjelpetekst = t("inngang_etasje_forklaring", { ns: "bruksenhetskort" })

  return (
    <>
      {dineBruksenheterIBygning
        .sort(sortertSåBruksenheterIHovedetasjeKommerFørst)
        .map(bruksenhet => (
          <Box
            key={bruksenhet.bruksenhetsnummer + bruksenhet.bruksenhetsId}
            bg="green.50"
            mt="8px"
            p="16px"
            borderRadius="8px"
            aria-label={t("bruksenhetskort_arialabel", {
              ns: "bruksenhetskort",
              bruksenhetsnummer: bruksenhet.bruksenhetsnummer,
            })}
          >
            <SimpleGrid
              autoRows="1fr 1fr"
              templateColumns={{
                base: "1fr",
                sm: "1fr 1fr",
              }}
              gap={3}
            >
              <VerdiMedForklaring
                label={t("bruksenhetstype_overskrift", { ns: "bruksenhetskort" })}
                posthogName="bruksenhetstype_info_clicked"
                forklaring={helpetekstBruksenhetstype}
              >
                <Brødtekst fontWeight="bold" as={Flex} alignItems="center">
                  {hentUtfraKodeliste(
                    bruksenhet.bruksenhetstype,
                    kodelister.bruksenhettypeKodeliste,
                  )}
                  {bruksenhet.erUnummerert && (
                    <PopoverikonMedTooltip
                      tooltipLabel={t("hva_betyr_type_arialabel", {
                        ns: "nøkkelbegreper",
                        type: "unummerert bruksenhet",
                      })}
                      tooltipAriaLabel={t("hva_betyr_dette", { ns: "nøkkelbegreper" })}
                      iconButtonAriaLabel={t("hva_betyr_type_arialabel", {
                        ns: "nøkkelbegreper",
                        type: "unummerert bruksenhet",
                      })}
                      icon="help"
                    >
                      <>{unummerertBruksenhetForklaring}</>
                    </PopoverikonMedTooltip>
                  )}
                </Brødtekst>
              </VerdiMedForklaring>
              <VerdiMedForklaring
                label={t("bruksenhetsnummer_overskrift", { ns: "bruksenhetskort" })}
                posthogName="bruksenhetsnummer_info_clicked"
                forklaring={hjelpetekstBruksenhetsnummer}
              >
                <Brødtekst fontWeight="bold">
                  {bruksenhet.erUnummerert ? "-" : bruksenhet.bruksenhetsnummer}
                </Brødtekst>
              </VerdiMedForklaring>
              <VerdiMedForklaring
                label={t("inngang_etasje_overskrift", { ns: "bruksenhetskort" })}
                posthogName="etasje_for_inngang_info_clicked"
                forklaring={etasjeForInngangHjelpetekst}
              >
                <Brødtekst fontWeight="bold">{formaterEtasjetekst(bruksenhet)}</Brødtekst>
              </VerdiMedForklaring>
              <VerdiMedForklaring
                label={t("bruksareal_for_en_bruksenhet_overskrift", { ns: "bruksenhetskort" })}
                posthogName="bruksareal_bruksenhet_info_clicked"
                forklaring={bruksarealForEnBruksenhetForklaring}
              >
                <Brødtekst fontWeight="bold">{formaterBruksareal(bruksenhet.bruksareal)}</Brødtekst>
              </VerdiMedForklaring>
            </SimpleGrid>
          </Box>
        ))}
    </>
  )
}

export default NøkkelinfoOmAlleBruksenheterIBygning
