import { Box, Button, Flex, Logo, VStack } from "@kvib/react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import useHandleLogout from "../../../auth/hooks/useHandleLogout"
import useIsInnlogget from "../../hooks/useIsInnlogget"
import { useScreenView } from "../../hooks/useScreenview"
import { Brødtekst } from "../../text/components/Brødtekst"
import { freezeScroll, unfreezeScroll } from "../../utils/navbarUtils"
import Section from "../layout/Section"
import { Brukerinfo } from "./Brukerinfo"

export const NavBar = () => {
  const navigate = useNavigate()
  const { isDesktop } = useScreenView()
  const { mutate: logOut } = useHandleLogout()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const { isSuccess, data: isInnloggetData } = useIsInnlogget()
  const isInnlogget = isSuccess && isInnloggetData
  const { t } = useTranslation(["navbar", "nøkkelbegreper"])

  useEffect(() => {
    if (isDesktop) setMenuIsOpen(false)
  }, [isDesktop])

  useEffect(() => {
    if (menuIsOpen) {
      freezeScroll()
    } else {
      unfreezeScroll()
    }
  }, [menuIsOpen])

  const handleNavigate = (path: string) => {
    setMenuIsOpen(false)
    navigate(path)
  }

  const LogoMedLenke = () => (
    <Box
      as="button"
      aria-label="Kartverket-logo"
      onClick={() => handleNavigate("/")}
      cursor="pointer"
    >
      <Box aria-hidden>
        <Logo variant="horizontal" size={148} />
      </Box>
    </Box>
  )

  const DesktopNavBar = () => (
    <Section pt="24px" pb="24px" borderBottomColor="gray.200" borderBottomWidth="1px">
      <Flex justifyContent="space-between" alignItems="center" gap="64px">
        <LogoMedLenke />
        {isInnlogget && (
          <Flex gap="32px">
            <Button
              colorScheme="green"
              rightIcon="help"
              variant="tertiary"
              onClick={() => navigate("/hjelp")}
              p={0}
              iconAriaIsHidden
            >
              {t("hjelp", { ns: "navbar" })}
            </Button>
            <Brukerinfo />
            <Button
              variant="secondary"
              leftIcon="logout"
              data-cy="logg-ut-knapp"
              onClick={() => logOut()}
              iconAriaIsHidden
            >
              {t("logg_ut", { ns: "navbar" })}
            </Button>
          </Flex>
        )}
      </Flex>
    </Section>
  )

  const MobileNavBar = () => (
    <Box
      bg="white"
      pos={menuIsOpen ? "fixed" : "relative"}
      zIndex={1000}
      borderBottomColor="gray.200"
      borderBottomWidth="1px"
      h={menuIsOpen ? "100vh" : "auto"}
      w="100vw"
      overflowY={menuIsOpen ? "scroll" : "auto"}
    >
      <Section pt="16px" pb="16px" bg="inherit">
        <Flex justifyContent="space-between" alignItems="center" gap="64px">
          <LogoMedLenke />
          {isInnlogget && (
            <Button
              variant="secondary"
              rightIcon={menuIsOpen ? "close" : "menu"}
              iconAriaIsHidden
              onClick={() => setMenuIsOpen(!menuIsOpen)}
              minW="80px"
            >
              {t("meny", { ns: "navbar" })}
            </Button>
          )}
        </Flex>
      </Section>
      {menuIsOpen && isInnlogget && <MobileMenu />}
    </Box>
  )

  const MobileMenu = () => (
    <Section bg="inherit">
      <VStack alignItems="flex-start" gap="48px" pt="32px">
        <VStack alignItems="flex-start" gap="8px">
          <Brødtekst fontWeight="bold" aria-hidden>
            Logget inn som
          </Brødtekst>
          <Brukerinfo />
        </VStack>
        <VStack alignItems="flex-start" gap="8px">
          <MenuButton
            icon="home"
            label={t("mine_eiendommer", { ns: "nøkkelbegreper" })}
            onClick={() => handleNavigate("/")}
          />
          <MenuButton
            icon="help"
            label={t("hjelp", { ns: "navbar" })}
            onClick={() => handleNavigate("/hjelp")}
          />
        </VStack>
        <Box w="100%" borderTopColor="green.500" borderTopWidth="1px" p="12px 0">
          <MenuButton
            icon="logout"
            label={t("logg_ut", { ns: "navbar" })}
            onClick={() => logOut()}
          />
        </Box>
      </VStack>
    </Section>
  )

  const MenuButton = ({
    icon,
    label,
    onClick,
  }: {
    icon: "logout" | "help" | "home"
    label: string
    onClick: () => void
  }) => (
    <Button leftIcon={icon} p="0" variant="tertiary" onClick={onClick} iconAriaIsHidden>
      {label}
    </Button>
  )

  return isDesktop ? <DesktopNavBar /> : <MobileNavBar />
}
