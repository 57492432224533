import { Accordion, Flex } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { useEiendom } from "../../api/useEiendom"
import useCurrentBygning from "../../hooks/useCurrentBygning"
import { AccordionItemBygningskort } from "./AccordionItemBygningskort"
import EgenskaperVedBygning from "./EgenskaperVedBygning"
import NøkkelinfoOmAlleBruksenheterIBygning from "./NøkkelinfoOmAlleBruksenheterIBygning"
import OmBygningen from "./OmBygningen"

export const BygningskortAccordion = ({ bygningsId }: { bygningsId: string }) => {
  const { currentBygning, dineBruksenheterIBygning } = useCurrentBygning(bygningsId)
  const { data: eiendom } = useEiendom()
  const { t } = useTranslation(["bygningskort", "bruksenhetskort"])

  if (!eiendom || !currentBygning || !dineBruksenheterIBygning) return null

  const erEierseksjon = eiendom.matrikkelenhetstype === "Eierseksjon"

  const componentsOrder = erEierseksjon
    ? [
        <AccordionItemBygningskort
          key="1"
          value="1"
          accordionTittel={t("dine_bruksenheter_i_bygningen_accordion_overskrift", {
            count: dineBruksenheterIBygning.length,
            ns: "bruksenhetskort",
          })}
        >
          <NøkkelinfoOmAlleBruksenheterIBygning byggId={currentBygning.byggId} />
        </AccordionItemBygningskort>,
        <AccordionItemBygningskort key="2" value="2" accordionTittel={t("bygningskort_overskrift")}>
          <OmBygningen bygning={currentBygning} />
        </AccordionItemBygningskort>,
        <AccordionItemBygningskort
          key="3"
          value="3"
          accordionTittel={t("egenskaper_ved_bygningen_accordion_overskrift")}
        >
          <EgenskaperVedBygning bygning={currentBygning} />
        </AccordionItemBygningskort>,
      ]
    : [
        <AccordionItemBygningskort key="4" value="4" accordionTittel={t("bygningskort_overskrift")}>
          <OmBygningen bygning={currentBygning} />
        </AccordionItemBygningskort>,
        <AccordionItemBygningskort
          key="5"
          value="5"
          accordionTittel={t("egenskaper_ved_bygningen_accordion_overskrift")}
        >
          <EgenskaperVedBygning bygning={currentBygning} />
        </AccordionItemBygningskort>,
        <AccordionItemBygningskort
          key="6"
          value="6"
          accordionTittel={t("dine_bruksenheter_i_bygningen_accordion_overskrift", {
            count: dineBruksenheterIBygning.length,
            ns: "bruksenhetskort",
          })}
        >
          <NøkkelinfoOmAlleBruksenheterIBygning byggId={currentBygning.byggId} />
        </AccordionItemBygningskort>,
      ]

  return (
    <Flex
      flexDir="column"
      columnGap={{ base: "16px", md: "48px", lg: "24px" }}
      flexWrap={{ base: "wrap", xl: "nowrap" }}
      justifyContent="space-between"
      m="16px"
    >
      <Accordion multiple collapsible defaultValue={["4", "1"]}>
        {componentsOrder}
      </Accordion>
    </Flex>
  )
}
