import { Stack } from "@kvib/react"
import { useTranslation } from "react-i18next"
import ErrorHandler from "../../../../core/error-handling/ErrorHandler"
import { useKodelister } from "../../../kodelister/useKodelister"
import { Bygning, Bygningsnøkkelinfo } from "../types/Bygning"
import { hentBygningstype } from "../utils/bygningstypeUtil"
import { hentAlleBygningsstatuser } from "../utils/hentBygningsstatus"
import Bygningsstatus from "./Bygningsstatus"
import { FelterIBygningskort } from "./FelterIBygningskort"

const OmBygningen = ({ bygning }: { bygning: Bygning }) => {
  const { t } = useTranslation(["bygningskort", "nøkkelbegreper"])
  const { data: kodelister, isError, isLoading, isSuccess, error } = useKodelister()

  if (isError) return <ErrorHandler apiError={error} />

  if (isLoading || !isSuccess) return null

  const identifikatorForBygning: Bygningsnøkkelinfo[] = [
    {
      title: t("bygningstype_felt"),
      id: "bygningstype",
      arialabel: t("hva_betyr_type_arialabel", { ns: "nøkkelbegreper", type: "bygningstype" }),
      forklaringAvAttributt: t("bygningstype_forklaring"),
      evaluate: (bygning: Bygning) => hentBygningstype[bygning.bygningstype],
    },
    {
      title: t("bygningsnummer_felt"),
      id: "bygningsnummer",
      arialabel: t("hva_betyr_type_arialabel", { ns: "nøkkelbegreper", type: "bygningsnummer" }),
      forklaringAvAttributt: t("bygningsnummer_forklaring"),
      evaluate: (bygning: Bygning) => bygning.bygningsnummer,
    },
    {
      title: t("bygningsstatus_felt"),
      id: "bygningsstatuser",
      arialabel: t("hva_betyr_type_arialabel", { ns: "nøkkelbegreper", type: "bygningsstatus" }),
      forklaringAvAttributt: t("bygningsstatus_forklaring"),
      evaluate: (bygning: Bygning) => {
        const statusHistorikk = hentAlleBygningsstatuser(bygning, kodelister)
        return Array.isArray(statusHistorikk)
          ? statusHistorikk.map(({ år, status }) => (år ? `${år}: ${status}` : status))
          : statusHistorikk
      },
    },
  ]

  const hentRiktigVisningForAttributt = (attributt: Bygningsnøkkelinfo) => {
    if (attributt.id === "bygningsstatuser") {
      return <Bygningsstatus key={attributt.id} bygning={bygning} attributt={attributt} />
    } else {
      return <FelterIBygningskort key={attributt.id} bygning={bygning} attributt={attributt} />
    }
  }

  return (
    <Stack gap={3} mt="12px">
      {identifikatorForBygning.map(attributt => hentRiktigVisningForAttributt(attributt))}
    </Stack>
  )
}

export default OmBygningen
