import {
  Breadcrumb,
  BreadcrumbCurrentLink,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps,
  Flex,
  Icon,
} from "@kvib/react"
import { NavLink, useLocation, useMatches } from "react-router"
import useIsInnlogget from "../../hooks/useIsInnlogget"
import { useScreenView } from "../../hooks/useScreenview"

import { useTranslation } from "react-i18next"

export const NavBreadcrumb = (props: BreadcrumbProps) => {
  const location = useLocation()
  const matches = useMatches()
  const { t } = useTranslation("errormeldinger")
  const loggedIn = useIsInnlogget()
  const { isDesktop } = useScreenView()
  const breadcrumbFontSize = isDesktop ? "md" : "sm"

  if (loggedIn.isPending) return null

  const isInnlogget = loggedIn.isSuccess && loggedIn.data

  const activeRoutes = matches.filter(match => match.handle)
  const breadcrumb = matches.filter(match => match.handle != "protected")

  return (
    <Breadcrumb
      h="24px"
      size={breadcrumbFontSize}
      colorPalette="green"
      separator={
        <Flex justifyContent="center" alignContent="center" w="8px" h="100%">
          <Icon icon="chevron_right" weight={400} ariaIsHidden color="{colors.green.500}" />
        </Flex>
      }
      {...props}
    >
      {breadcrumb.map((route, index) => {
        if (typeof route.handle === "string")
          return (
            <BreadcrumbItem key={route.pathname} gap={1} as={Flex}>
              {index === 0 && <Icon icon="home" filled ariaIsHidden color="{colors.green.500}" />}
              {location.pathname === route.pathname ? (
                <BreadcrumbCurrentLink>
                  {activeRoutes.some(match => match.handle === "protected") && !isInnlogget
                    ? t("fant_ikke_side")
                    : route.handle}
                </BreadcrumbCurrentLink>
              ) : (
                <BreadcrumbLink asChild>
                  <NavLink to={route.pathname}>{route.handle}</NavLink>
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
          )
      })}
    </Breadcrumb>
  )
}
