const keys = {
  "nb-NO": {
    hva_betyr_de_forskjellige_datafeltene: "Hva betyr de forskjellige datafeltene?",
    eiendom: "Eiendom",
    bygning: "Bygning",
    bruksenhet: "Bruksenhet",
    datafelter_eiendom: [
      {
        tittel: "Adresse",
        avsnitt: ["Adressen er den/de offisielle vegadressen(e) til bygningen(e) på eiendommen."],
      },
      {
        tittel: "Arealmerknader",
        avsnitt: [
          "En arealmerknad betyr at en eller flere av grensene til eiendommen er registrert med en usikkerhet. Dette gjør at arealet er usikkert eller ikke kan beregnes. For en fullstendig oversikt over din eiendom kan du bestille et matrikkelbrev fra kommunen, dette er gebyrbelagt.",
          "De ulike merknadene:",
        ],
        arealmerknader: [
          {
            tittel: "Hjelpelinje vannkant",
            avsnitt: [
              "Eiendom hvor en eller flere grenser er mot vann, kan være registrert med hjelpelinje av typen vannkant. Arealet som beregnes vil derfor være usikkert.",
            ],
          },
          {
            tittel: "Hjelpelinje veikant",
            avsnitt: [
              "Eiendommer hvor en eller flere grenser er mot en vei, kan være registrert med hjelpelinje av typen veikant. Arealet som beregnes vil derfor være usikkert.",
            ],
          },
          {
            tittel: "Noen fiktive hjelpelinjer",
            avsnitt: [
              "En eller flere, men ikke alle, grenser er registrert som fiktiv hjelpelinje. Det betyr at grensen som er registrert ikke nødvendigvis stemmer med virkeligheten. Arealet som beregnes vil derfor være usikkert.",
            ],
          },
          {
            tittel: "Kun fiktive hjelpelinjer",
            avsnitt: [
              "Alle grensene er registrert som fiktive hjelpelinjer. Det betyr at grensen som er registrert ikke nødvendigvis stemmer med virkeligheten, for eksempel at teigen er registrert som en sirkel. Det er derfor ikke mulig å beregne areal på eiendommen.",
            ],
          },
          {
            tittel: "Punktfeste",
            avsnitt: [
              "Hvis du har et punktfeste beregnes ikke et areal fordi det kun defineres med ett punkt. Eventuelle avtaler om bruk av areal registreres ikke i Eiendomsregisteret (matrikkelen) for punktfester.",
            ],
          },
          {
            tittel: "Har punktfeste",
            avsnitt: [
              "Det er en eller flere punktfester på eiendommen. Punktfestene er som øyer avgrenset av hjelpelinje punktfeste. Teigen med punktfester får ikke fratrekk for disse ved beregning av areal. Arealet til eiendommen vil derfor være usikkert.",
            ],
          },
          {
            tittel: "Omtvistet eller usikker",
            avsnitt: [
              "En eller flere av grensene er merket omtvistet, er usikre eller en teigen er merket med tvist. Dette er en markering av at det er uavklart eller omtvistet hvor grensen går. På en oppmålingsforretning kan det oppstå tvil om hvor en eksisterende grense går, eller partene har ulik oppfatning av grenseforløpet. Hvis de ikke kommer til enighet beskrives dette i protokollen og grenselinjen(e) blir markert som usikker eller omtvistet. Arealet vil derfor være usikkert inntil grenseforløpet er avklart. Du må ta kontakt med kommunen der eiendommen ligger dersom du ønsker en ny oppmålingsforretning.",
            ],
          },
        ],
      },
      {
        tittel: "Bygningstyper på eiendommen",
        avsnitt: [
          "På detaljsiden for eiendommen din kan du se de ulike type bygninger som finnes på eiendommen. Bygningstypen er definert utifra bygningens hovedfunksjon. Dette defineres i Norsk standard for bygningstype (NS3457:1995). For bygninger med flere typer bruk vil den største delen av bygningen som regel definere bygningstypen.",
        ],
      },
      {
        tittel: "Eiendomsareal",
        avsnitt: [
          "Arealet beregnes på grunnlag av registrerte eiendomsgrenser i matrikkelen. Det kan være mangler i grenseinformasjonen som gjør at arealet ikke stemmer, er usikkert eller det ikke er mulig å beregne areal. For eierseksjoner uten registrert eksklusivt uteareal vil det ikke være areal.",
        ],
        lenker: [
          {
            tekst: "Les mer om feil i eiendomsgrenser ",
            lenke:
              "https://www.kartverket.no/eiendom/eiendomsgrenser/feil-grenser-i-eiendomskartet",
          },
        ],
      },
      {
        tittel: "Eierandel",
        avsnitt: [
          "En eierandel sier hvor mye av en eiendom du er registrert tinglyst eier (eller fester) av. Eierandelen er oppgitt som en brøk der 1/1 betyr at du eier 100% av eiendommen. Eierandelen hentes fra grunnboken. Hvis eierforholdet ikke er tinglyst, vil ikke eierandelene vises.",
        ],
      },
      {
        tittel: "Eksklusivt uteareal",
        avsnitt: [
          "Eksklusivt uteareal er areal du har rett til å disponere tilknyttet seksjonen. For eksempel kan dette være uteområde som hage eller parkering. “Ikke registrert“ kan bety at det ikke er eksklusivt uteareal tilknyttet seksjonen, eller at det ikke er registrert i Eiendomsregisteret (matrikkelen).",
        ],
        lenker: [
          {
            tekst: "Les mer om Eierseksjoner",
            lenke: "https://www.kartverket.no/eiendom/eierseksjoner",
          },
        ],
      },
      {
        tittel: "Kartutsnitt for eiendom",
        avsnitt: [
          "Grensene kan ha ulik nøyaktighet og det kan være feil og mangler i det du ser som din eiendom. Hvis du ikke ser kartutsnitt kan det være fordi eiendommen mangler teig.",
        ],
      },
      {
        tittel: "Matrikkelenhetstype",
        avsnitt: [
          "Matrikkelenhetstype sier noe om eiendommens egenskaper. Det finnes fem ulike matrikkelenhetstyper: grunneiendom, festegrunn, eierseksjon, anleggseiendom og jordsameie. I tillegg kan noen være registret som annen type. Du kan lese mer om dem nedenfor.",
        ],
        matrikkelenhetstyper: [
          {
            tittel: "Grunneiendom",
            avsnitt: [
              "Eiendom som er avgrenset med eiendomsgrenser på jordoverflaten og strekker seg så langt ned og opp som eiendomsretten rekker. En grunneiendom har typisk en eller flere grunneiere og er eksempelvis en landbrukseiendom eller boligeiendom. En grunneiendom har kun eget gårds- og bruksnummer, ikke festenummer og seksjonsnummer. Grunneiendom er den vanligste matrikkelenheten i Norge.",
            ],
          },
          {
            tittel: "Festegrunn",
            avsnitt: [
              "Del av grunneiendom eller jordsameie som noen har festerett til eller som kan festes bort (feste betyr å leie), eller som noen har en tilsvarende eksklusiv og langvarig bruksrett til. Når man fester eier man ikke grunnen selv. Et eksempel kan være et naust eller bolighus som ikke blir disponert av grunneieren selv, men av den som fester. Enten så fester man et areal hvor grensene er avklart, eller punktfeste som ikke har en klar avgrensing. En festegrunn har både gårds- og bruksnummer, og et festenummer i tillegg.",
            ],
          },
          {
            tittel: "Eierseksjon",
            avsnitt: [
              "En eierseksjon er den del av en eiendom som er seksjonert (delt). Den som eier en eierseksjon, eier dermed en eierandel av eiendommen. Hele eiendommen er i sameie med de andre seksjonseierne. Et typisk eksempel er en boligblokk med flere leiligheter, hvor man har en eksklusiv bruksrett til en seksjon i bygget, for eksempel en leilighet. En eierseksjon har et seksjonsnummer som del av sitt matrikkelnummer.",
            ],
          },
          {
            tittel: "Anleggseiendom",
            avsnitt: [
              "En bygning, konstruksjon, eller et avgrenset volum som er utbygd eller kan utbygges. Eiendommen strekker seg ut over eller inn under en annen eiendom. Et parkeringsanlegg som befinner seg under bakken og strekker seg under en annen eiendom er et typisk eksempel på en anleggseiendom.",
            ],
          },
          {
            tittel: "Jordsameie",
            avsnitt: [
              "Et grunnareal som er eies av flere grunneiendommer. Jordsameie er ikke inndelt etter hva hver grunneiendom eier, men alt eies i fellesskap av alle grunneiendommene. Grunneiendommene som er eiere i jordsameier, er ofte gårder (bruk). Jordsameie har en historisk opprinnelse. Et eksempel på jordsameier er utmarksområder, gjerne opp mot fjellene. Jordsameie har eget gårds- og bruksnummer hvis det er registret i matrikkelen. I et jordsameie kan ikke en av gårdeierene selge sin ideelle andel, uten at gården overdras samtidig.",
            ],
          },
          {
            tittel: "Annen type",
            avsnitt: [
              "Eiendommen er av eldre type og er tildelt matrikkelnummer etter tidligere regelverk. Etter tidligere regelverk har det vært mulig å registrere for eksempel bruksretter som egne enheter i matrikkelen og de vil vises som “Annen type“ i dag. Eksempeler på slike varige bruksretter som kan være gitt eget gårds- og bruksnummer, er skog, beite, fiske og vannfall.",
            ],
          },
        ],
      },
      {
        tittel: "Matrikkelnummer",
        avsnitt: [
          "Matrikkelnummeret er et unikt nummer som består av kommunenummer, gårdsnummer, bruksnummer, og eventuelt festenummer og/eller seksjonsnummer.",
        ],
      },
    ],
    datafelter_bygning: [
      {
        tittel: "Bygningsnummer",
        avsnitt: [
          "Et bygningsnummer er bygningens unike nummer og brukes for å identifisere bygningen. Løsningen viser ikke bygningsendringer som tilbygg og påbygg. Registrerte bygningsendringer kan være synlig i andre tjenester for eksempel på Se eiendom og Norgeskart. Disse er markert med en -1, -2 osv. på slutten av bygningsnummeret.",
        ],
      },
      {
        tittel: "Bygningsstatus",
        avsnitt: [
          "En bygning har en eller flere bygningsstatuser som beskriver hvordan bygget er registrert for bruk, bygging eller riving i Eiendomsregisteret (matrikkelen). Statusen avhenger blant annet av hvilket regelverk som gjaldt da bygningen ble oppført. I hovedsak samsvarer bygningsstatusene med reglene i plan- og bygningsloven. Bygninger som er avlyst, revet, brent, flyttet eller hvis bygningsnummeret er utgått, vises ikke i løsningen.",
          "I oversikten finner du de ulike statusene og hva de betyr. Mener du statusen er feil og har dokumentasjon på en annen status, kan du ta kontakt med kommunen for veiledning og oppdatering.",
        ],
        statuser: [
          {
            tittel: "Rammetillatelse",
            avsnitt: [
              "Forhåndsgodkjenning fra kommunen som setter de overordnede rammene for et byggeprosjekt. En rammetillatelse er avhengig av en igangsettingstillatelse før bygging kan starte.",
            ],
          },
          {
            tittel: "Igangsettingstillatelse",
            avsnitt: ["Tillatelse fra kommunen til å starte bygging."],
          },
          {
            tittel: "Midlertidig brukstillatelse",
            avsnitt: [
              "Tillatelse fra kommunen som gir lov til å ta i bruk et byggverk før alt arbeid er fullført.",
            ],
          },
          {
            tittel: "Ferdigattest",
            avsnitt: [
              "Det endelige beviset på at byggeprosjektet er lovlig utført og klart for bruk.",
            ],
          },
          {
            tittel: "Tatt i bruk",
            avsnitt: [
              "Statuskode ofte brukt for bygninger som regnes som ferdigstilt og kan tas i bruk.",
            ],
          },
          {
            tittel: "Meldingssak registrer tiltak",
            avsnitt: [
              "En tillatelse fra kommunen til å starte bygging av et søknadspliktig tiltak. Statusen er utgått og brukes ikke lenger i nye byggeprosjekter.",
            ],
          },
          {
            tittel: "Meldingssak tiltak fullført",
            avsnitt: [
              "Et vedtak fra kommunen på at bygging anses lovlig utført og klart for bruk. Statusen er utgått og brukes ikke lenger i nye byggeprosjekter.",
            ],
          },
          {
            tittel: "Fritatt for søknadsplikt",
            avsnitt: [
              "Brukes for byggeprosjekter som ikke er søknadspliktige, men som skal registreres i Eiendomsregisteret (matrikkelen).",
            ],
          },
          {
            tittel: "Ikke pliktig registrert",
            avsnitt: [
              "Brukes for bygninger som faller utenfor de vanlige kravene til søknad eller godkjenning.",
            ],
          },
          {
            tittel: "Bygning godkjent for rivning / brenning",
            avsnitt: [
              "En tillatelse fra kommunen til å rive eller igangsette brenning av en bygning.",
            ],
          },
        ],
      },
      {
        tittel: "Bygningstype",
        avsnitt: [
          "Alle bygninger har en bygningstype som forklarer bygningens funksjon. Informasjonen er hentet fra Norsk standard for bygningstype (NS3457:1995), som kategoriserer bygninger etter hvilken hovedfunksjon det har.",
        ],
      },
      {
        tittel: "Kartutsnitt med bygning",
        avsnitt: [
          "Kartutsnittet er sentrert på bygningen hvis bygningskoordinaten ligger innenfor bygningsflaten i kartet. Hvis dette ser feil ut kan det være fordi koordinaten er plassert et annet sted enn bygningen. For å rette dette kan du ta kontakt med kommunen der bygningen ligger.",
        ],
      },
      {
        tittel: "Totalt bruksareal",
        avsnitt: [
          "Det totale registrerte bruksarealet internt i bygningen. Dette kan inkludere areal for loft- og kjellerbod, innglasset balkong og lignende.",
        ],
      },
    ],
    datafelter_bruksenhet: [
      {
        tittel: "Bruksareal",
        avsnitt: [
          "Det totale registrerte bruksarealet for din bruksenhet. Dette er areal inne i bygningen, og kan inkludere areal for bod, innglasset balkong og lignende. Uteområder som hage og parkeringsplass regnes ikke med i bruksarealet.",
        ],
      },
      {
        tittel: "Bruksenhetnummer",
        avsnitt: [
          "Alle bruksenheter (som ikke er av typen unummerert) har et bruksenhetsnummer. Bruksenhetsnummeret består av én bokstav og fire tall. Bokstaven forteller om du bor i en hovedetasje (H), loftsetasje (L), underetasje (U) eller kjelleretasje (K). De to første tallene angir hvilken etasje inngangen til bruksenheten ligger i. De to siste tallene angir nummeret i etasjen, som regel satt fra venstre mot høyre.",
        ],
        lenker: [
          {
            tekst: "Les mer om bruksenhetsnummere",
            lenke:
              "https://www.kartverket.no/eiendom/adressering/finn-bruksenhetsnummer-bolignummer",
          },
        ],
      },
      {
        tittel: "Bruksenhetstype",
        avsnitt: [
          "Beskriver bruken av bruksenheten. Kan være følgende typer: Bolig, Ikke godkjent bolig, Fritidsbolig, Annet enn bolig eller Unummerert bruksenhet (benyttes for eksempel for garasje).",
        ],
      },

      { tittel: "Inngang", avsnitt: ["Hvilken etasje inngangsdøren til bruksenheten ligger i."] },
    ],
  },
  "nn-NO": {
    hva_betyr_de_forskjellige_datafeltene: "Hva betyr de forskjellige datafeltene?",
    eiendom: "Eiendom",
    bygning: "Bygning",
    bruksenhet: "Bruksenhet",
    datafelter_eiendom: [
      {
        tittel: "Adresse",
        avsnitt: ["Adressen er den/de offisielle vegadressen(e) til bygningen(e) på eiendommen."],
      },
      {
        tittel: "Arealmerknader",
        avsnitt: [
          "En arealmerknad betyr at en eller flere av grensene til eiendommen er hjelpelinjer.",
        ],
        arealmerknader: [
          {
            tittel: "Hjelpelinje vannkant",
            avsnitt: [""],
          },
          {
            tittel: "Hjelpelinje veikant",
            avsnitt: [""],
          },
          {
            tittel: "Noen fiktive hjelpelinjer",
            avsnitt: [""],
          },
          {
            tittel: "Kun fiktive hjelpelinjer",
            avsnitt: [""],
          },
          {
            tittel: "Punktfeste",
            avsnitt: [""],
          },
          {
            tittel: "Har punktfeste",
            avsnitt: [""],
          },
          {
            tittel: "Omtvistet eller usikker",
            avsnitt: [""],
          },
        ],
      },
      {
        tittel: "Bygningstyper på eiendommen",
        avsnitt: [
          "På detaljsiden for eiendommen din kan du se de ulike type bygninger som finnes på eiendommen. Bygningstypen er definert utifra bygningens hovedfunksjon. Dette defineres i Norsk standard for bygningstype (NS3457:1995). For bygninger med flere typer bruk vil den største delen av bygningen som regel definere bygningstypen.",
        ],
      },
      {
        tittel: "Eiendomsareal",
        avsnitt: [
          "Arealet beregnes på grunnlag av registrerte eiendomsgrenser i matrikkelen. Det kan være mangler i grenseinformasjonen som gjør at arealet ikke stemmer, er usikkert eller det ikke er mulig å beregne areal. For eierseksjoner uten registrert eksklusivt uteareal vil det ikke være areal.",
        ],
      },
      {
        tittel: "Eierandel",
        avsnitt: [
          "En eierandel sier hvor mye av en eiendom du er registrert tinglyst eier (eller fester) av. Eierandelen er oppgitt som en brøk der 1/1 betyr at du eier 100% av eiendommen. Eierandelen hentes fra grunnboken. Hvis eierforholdet ikke er tinglyst vil ikke eierandelene vises.",
        ],
      },
      {
        tittel: "Eksklusivt uteareal",
        avsnitt: [
          "Eksklusivt uteareal er areal du har rett til å disponere tilknyttet seksjonen. For eksempel kan dette være uteområde som hage eller parkering. “Ikke registrert“ kan bety at det ikke er eksklusivt uteareal tilknyttet seksjonen, eller at det ikke er registrert i Eiendomsregisteret (matrikkelen).",
        ],
      },
      {
        tittel: "Kartutsnitt for eiendom",
        avsnitt: [
          "Grensene kan ha ulik nøyaktighet og det kan være feil og mangler i det du ser som din eiendom. Hvis du ikke ser kartutsnitt kan det være fordi eiendommen mangler teig.",
        ],
      },
      {
        tittel: "Matrikkelenhetstype",
        avsnitt: [
          "Matrikkelenhetstype sier noe om eiendommens egenskaper. Det finnes fem ulike matrikkelenhetstyper: grunneiendom, festegrunn, eierseksjon, anleggseiendom og jordsameie. I tillegg kan noen være registret som annen type. Du kan lese mer om dem nedenfor.",
        ],
        matrikkelenhetstyper: [
          {
            tittel: "Grunneiendom",
            avsnitt: [
              "Eiendom som er avgrenset med eiendomsgrenser på jordoverflaten og strekker seg så langt ned og opp som eiendomsretten rekker. En grunneiendom har typisk en eller flere grunneiere og er eksempelvis en landbrukseiendom eller boligeiendom. En grunneiendom har kun eget gårds- og bruksnummer, ikke festenummer og seksjonsnummer. Grunneiendom er den vanligste matrikkelenheten i Norge.",
            ],
          },
          {
            tittel: "Festegrunn",
            avsnitt: [
              "Del av grunneiendom eller jordsameie som noen har festerett til eller som kan festes bort (feste betyr å leie), eller som noen har en tilsvarende eksklusiv og langvarig bruksrett til. Når man fester eier man ikke grunnen selv. Et eksempel kan være et naust eller bolighus som ikke blir disponert av grunneieren selv, men av den som fester. Enten så fester man et areal hvor grensene er avklart, eller punktfeste som ikke har en klar avgrensing. En festegrunn har både gårds- og bruksnummer, og et festenummer i tillegg.",
            ],
          },
          {
            tittel: "Eierseksjon",
            avsnitt: [
              "En eierseksjon er den del av en eiendom som er seksjonert (delt). Den som eier en eierseksjon, eier dermed en eierandel av eiendommen. Hele eiendommen er i sameie med de andre seksjonseierne. Et typisk eksempel er en boligblokk med flere leiligheter, der man eier sin egen seksjon av bygget, for eksempel en leilighet. En eierseksjon har et seksjonsnummer i matrikkelnummeret, dette er med på å indikere at det er en eierseksjon.",
            ],
          },
          {
            tittel: "Anleggseiendom",
            avsnitt: [
              "En bygning, konstruksjon, eller et avgrenset volum som er utbygd eller kan utbygges. Eiendommen strekker seg ut over eller inn under en annen eiendom. Et parkeringsanlegg som befinner seg under bakken og strekker seg under en annen eiendom er et typisk eksempel på en anleggseiendom.",
            ],
          },
          {
            tittel: "Jordsameie",
            avsnitt: [
              "Et grunnareal som er eies av flere grunneiendommer. Jordsameie er ikke inndelt etter hva hver grunneiendom eier, men alt eies i fellesskap av alle grunneiendommene. Grunneiendommene som er eiere i jordsameier, er ofte gårder (bruk). Jordsameie har en historisk opprinnelse. Et eksempel på jordsameier er utmarksområder, gjerne opp mot fjellene. Jordsameie har eget gårds- og bruksnummer hvis det er registret i matrikkelen. I et jordsameie kan ikke en av gårdeierene selge sin ideelle andel, uten at gården overdras samtidig.",
            ],
          },
          {
            tittel: "Annen type",
            avsnitt: [
              "Eiendommen er av eldre type og er tildelt matrikkelnummer etter tidligere regelverk. Etter tidligere regelverk har det vært mulig å registrere for eksempel bruksretter som egne enheter i matrikkelen og de vil vises som “Annen type“ i dag. Eksempeler på slike varige bruksretter som kan være gitt eget gårds- og bruksnummer, er skog, beite, fiske og vannfall.",
            ],
          },
        ],
      },
      {
        tittel: "Matrikkelnummer",
        avsnitt: [
          "Matrikkelnummeret er et unikt nummer som består av kommunenummer, gårdsnummer, bruksnummer, og eventuelt festenummer og/eller seksjonsnummer.",
        ],
      },
    ],
    datafelter_bygning: [
      {
        tittel: "Bygningsnummer",
        avsnitt: [
          "Et bygningsnummer er bygningens unike nummer og brukes for å identifisere bygningen. Løsningen viser ikke bygningsendringer som tilbygg og påbygg. Registrerte bygningsendringer kan være synlig i andre tjenester for eksempel på Se eiendom og Norgeskart. Disse er markert med en -1, -2 osv. på slutten av bygningsnummeret.",
        ],
      },
      {
        tittel: "Bygningsstatus",
        avsnitt: [
          "En bygning har en eller flere bygningsstatuser som beskriver hvordan bygget er registrert for bruk, bygging eller riving i Eiendomsregisteret (matrikkelen). Statusen avhenger blant annet av hvilket regelverk som gjaldt da bygningen ble oppført. I hovedsak samsvarer bygningsstatusene med reglene i plan- og bygningsloven. Bygninger som er avlyst, revet, brent, flyttet eller hvis bygningsnummeret er utgått, vises ikke i løsningen.  I oversikten finner du de ulike statusene og hva de betyr.  Mener du statusen er feil og har dokumentasjon på en annen status, kan du ta kontakt med kommunen for veiledning og oppdatering.",
          "I oversikten finner du de ulike statusene og hva de betyr. Mener du statusen er feil og har dokumentasjon på en annen status, kan du ta kontakt med kommunen for veiledning og oppdatering.",
        ],
        statuser: [
          {
            tittel: "Rammetillatelse",
            avsnitt: [
              "Forhåndsgodkjenning fra kommunen som setter de overordnede rammene for et byggeprosjekt. En rammetillatelse er avhengig av en igangsettingstillatelse før bygging kan starte.",
            ],
          },
          {
            tittel: "Igangsettingstillatelse",
            avsnitt: ["Tillatelse fra kommunen til å starte bygging."],
          },
          {
            tittel: "Midlertidig brukstillatelse",
            avsnitt: [
              "Tillatelse fra kommunen som gir lov til å ta i bruk et byggverk før alt arbeid er fullført.",
            ],
          },
          {
            tittel: "Ferdigattest",
            avsnitt: [
              "Det endelige beviset på at byggeprosjektet er lovlig utført og klart for bruk.",
            ],
          },
          {
            tittel: "Tatt i bruk",
            avsnitt: [
              "Statuskode ofte brukt for bygninger som regnes som ferdigstilt og kan tas i bruk.",
            ],
          },
          {
            tittel: "Meldingssak registrer tiltak",
            avsnitt: [
              "En tillatelse fra kommunen til å starte bygging av et søknadspliktig tiltak. Statusen er utgått og brukes ikke lenger i nye byggeprosjekter.",
            ],
          },
          {
            tittel: "Meldingssak tiltak fullført",
            avsnitt: [
              "Et vedtak fra kommunen på at bygging anses lovlig utført og klart for bruk. Statusen er utgått og brukes ikke lenger i nye byggeprosjekter.",
            ],
          },
          {
            tittel: "Fritatt for søknadsplikt",
            avsnitt: [
              "Brukes for byggeprosjekter som ikke er søknadspliktige, men som skal registreres i Eiendomsregisteret (matrikkelen).",
            ],
          },
          {
            tittel: "Ikke pliktig registrert",
            avsnitt: [
              "Brukes for bygninger som faller utenfor de vanlige kravene til søknad eller godkjenning.",
            ],
          },
          {
            tittel: "Bygning godkjent for rivning / brenning",
            avsnitt: [
              "En tillatelse fra kommunen til å rive eller igangsette brenning av en bygning.",
            ],
          },
        ],
      },
      {
        tittel: "Bygningstype",
        avsnitt: [
          "Alle bygninger har en bygningstype som forklarer bygningens funksjon. Informasjonen er hentet fra Norsk standard for bygningstype (NS3457:1995), som kategoriserer bygninger etter hvilken hovedfunksjon det har.",
        ],
      },
      {
        tittel: "Kartutsnitt med bygning",
        avsnitt: [
          "Kartutsnittet er sentrert på bygningen hvis bygningskoordinaten ligger innenfor bygningsflaten i kartet. Hvis dette ser feil ut kan det være fordi koordinaten er plassert et annet sted enn bygningen. For å rette dette kan du ta kontakt med kommunen der bygningen ligger.",
        ],
      },
      {
        tittel: "Totalt bruksareal",
        avsnitt: [
          "Det totale registrerte bruksarealet internt i bygningen. Dette kan inkludere areal for loft- og kjellerbod, innglasset balkong og lignende.",
        ],
      },
    ],
    datafelter_bruksenhet: [
      {
        tittel: "Bruksareal",
        avsnitt: [
          "Det totale registrerte bruksarealet på din seksjon. Dette er areal inne i bygningen, og kan inkludere areal for loft- og kjellerbod, innglasset balkong og lignende. Uteområder som hage og parkeringsplass regnes ikke med i bruksarealet.",
        ],
      },
      {
        tittel: "Bruksenhetnummer og seksjonsnummer",
        avsnitt: [
          "I tillegg til seksjonsnummeret har seksjonen din et bruksenhetsnummer. Bokstaven og de to første tallene angir hvilken etasje leiligheten ligger i. De to siste tallene angir leilighetens nummer i etasjen, som regel satt fra venstre mot høyre.",
        ],
      },
      {
        tittel: "Bruksenhetstype",
        avsnitt: [
          "Beskriver bruken av bruksenheten. Kan være følgende typer: Bolig, Ikke godkjent bolig, Fritidsbolig, Annet enn bolig eller Unummerert bruksenhet (benyttes for eksempel for garasje).",
        ],
      },

      { tittel: "Inngang", avsnitt: ["Hvilken etasje inngangsdøren til bruksenheten ligger i."] },
    ],
  },
}

export const datafelter = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
