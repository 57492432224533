import { CheckboxGroup, FormLabel, Grid, Stack } from "@kvib/react"
import { UseFormRegisterReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Energikildetype, Oppvarmingstype } from "../../types/Enums"
import CheckboxForEgenregistrering from "./CheckboxForEgenregistrering"
import { Overskrift } from "../../../../core/text/components/Overskrift"

interface Props<T extends typeof Energikildetype | typeof Oppvarmingstype> {
  name: "energikilder" | "oppvarming"
  register: UseFormRegisterReturn<string>
  options: T
}

const Flervalg = <T extends typeof Energikildetype | typeof Oppvarmingstype>({
  name,
  register,
  options,
}: Props<T>) => {
  const { t } = useTranslation(["egenregistrering", "skjemaoverskrifter"])

  const optionMedEksempeltekst = [
    "Biobrensel",
    "AnnenOppvarming",
    "Sentralvarme",
    "Elektrisk",
    "OljeParafin",
  ]

  const formlabelTekst =
    name === "energikilder"
      ? t("registrer_felt_for_bruksenheten.energikilder", { ns: "skjemaoverskrifter" })
      : t("registrer_felt_for_bruksenheten.oppvarming", { ns: "skjemaoverskrifter" })

  const hentOptionMedEksempeltekst = (label: string, key: string) => {
    if (!optionMedEksempeltekst.includes(key)) return label
    const hjelpetekst = t(`hjelpetekst_flervalg.${key}` as never, {
      ns: "egenregistrering",
    })
    return label + hjelpetekst
  }

  return (
    <Stack>
      <FormLabel>
        <Overskrift variant="h4">{formlabelTekst}</Overskrift>
      </FormLabel>
      <CheckboxGroup>
        <Grid ml="12px" gap={2}>
          {Object.entries(options).map(([key, label]) => (
            <CheckboxForEgenregistrering
              key={key}
              label={hentOptionMedEksempeltekst(label, key)}
              register={register}
              name={name}
              feltKey={key}
            />
          ))}
        </Grid>
      </CheckboxGroup>
    </Stack>
  )
}

export default Flervalg
