import { Flex, Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import PopoverikonMedTooltip from "../../../core/components/PopoverikonMedTooltip"
import VerdiMedForklaring from "../../components/VerdiMedForklaring"
import { Arealmerknadskode, arealmerknaderMapper } from "../bygningsdetaljer/utils/arealmerknadUtil"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"

const Arealmerknad = ({ arealmerknader }: { arealmerknader?: Arealmerknadskode[] }) => {
  const { t } = useTranslation(["detaljertInnsyn", "nøkkelbegreper"])

  const unikeArealmerknaderForEiendomsareal = [
    ...new Set(arealmerknader?.map(merknad => arealmerknaderMapper[merknad] || merknad)),
  ]

  return (
    <VerdiMedForklaring
      label={t("arealmerknader_overskrift")}
      posthogName="arealmerknad_info_clicked"
      forklaring={
        t("arealmerknad_begrep_forklaring", {
          ns: "detaljertInnsyn",
        }) as string
      }
    >
      <>
        {arealmerknader ? (
          unikeArealmerknaderForEiendomsareal.map(merknad => (
            <Flex key={merknad} alignItems="center">
              <FeltverdiForEiendomsdetaljer fieldValue={merknad} />
              <PopoverikonMedTooltip
                tooltipLabel={t("hva_betyr_type_arialabel", {
                  ns: "nøkkelbegreper",
                  type: merknad.toLowerCase(),
                })}
                tooltipAriaLabel={t("hva_betyr_type_arialabel", {
                  ns: "nøkkelbegreper",
                  type: merknad,
                })}
                iconButtonAriaLabel={t("hva_betyr_type_arialabel", {
                  ns: "nøkkelbegreper",
                  type: merknad,
                })}
                icon="help"
              >
                {t(
                  `arealmerknadstype_forklaring.${merknad.toLowerCase().replace(/ /g, "_")}` as never,
                  {
                    ns: "detaljertInnsyn",
                  },
                )}
              </PopoverikonMedTooltip>
            </Flex>
          ))
        ) : (
          <Skeleton w="60%" h="24px" />
        )}
      </>
    </VerdiMedForklaring>
  )
}

export default Arealmerknad
