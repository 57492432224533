import { Box, Flex } from "@kvib/react"
import { useTranslation } from "react-i18next"
import VerdiMedForklaring from "../../components/VerdiMedForklaring"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"

const BortfestetDetalj = () => {
  const { t } = useTranslation("detaljertInnsyn")
  return (
    <VerdiMedForklaring
      label={t("bortfestet_detaljer_er_festet_bort_tittel")}
      posthogName="bortfestet_detalj_info_clicked"
      forklaring={t("bortfestet_eiendom_forklaring")}
    >
      <Box as={Flex} alignItems="center">
        <FeltverdiForEiendomsdetaljer fieldValue="Ja" />
      </Box>
    </VerdiMedForklaring>
  )
}

export default BortfestetDetalj
