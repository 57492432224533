import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Icon,
} from "@kvib/react"
import { useTranslation } from "react-i18next"

const InfoOmRegistreringAccordion = () => {
  const { t } = useTranslation("egenregistrering")

  return (
    <Accordion collapsible variant="plain">
      <AccordionItem value="info_om_egenreg_accrodion">
        <AccordionButton color="green.500">
          <Icon icon="info" />
          <p>{t("info_om_registrering.tittel")}</p>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {t("info_om_registrering.avsnitt.0")} <br /> <br />
          {t("info_om_registrering.avsnitt.1")}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default InfoOmRegistreringAccordion
