import { Button, ButtonProps } from "@kvib/react"

interface EgenregistreringKnappProps extends ButtonProps {
  icon: "edit" | "add"
  children: string
}

export const EgenregistreringKnapp = ({
  children,
  icon,
  ...buttonProps
}: EgenregistreringKnappProps) => (
  <Button
    p="0"
    minW="fit-content"
    leftIcon={icon}
    justifyContent="space-between"
    variant="tertiary"
    {...buttonProps}
  >
    {children}
  </Button>
)
