import { Box, BoxProps, Image, ImageProps } from "@kvib/react"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import eiendomSvg from "../../../assets/eiendom.svg"

interface Props {
  tekst: string
  imageProps: ImageProps
  boxProxs: BoxProps
}

const FantIkkeKartutstnittBox = (props: Props) => {
  return (
    <Box
      pos="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p="64px"
      {...props.boxProxs}
    >
      <Brødtekst color="gray.600" textAlign="center">
        {props.tekst}
      </Brødtekst>
      <Image
        src={eiendomSvg}
        position="absolute"
        bottom="0"
        right="0"
        objectFit="contain"
        pl="2%"
        transform="scaleX(-1)"
        opacity=".1"
        {...props.imageProps}
      />
    </Box>
  )
}

export default FantIkkeKartutstnittBox
