import { zodResolver } from "@hookform/resolvers/zod"
import { Field, Spinner } from "@kvib/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useKodelisterBygning } from "../../kodelister/useKodelisterBygning"
import useEgenregistreringBruksenhet from "../api/useEgenregistreringBruksenhet"
import { Avløpstype } from "../types/Enums"
import { AvløpSchema, avløpSchema } from "../types/schema"
import HjelpetekstForFelt from "./skjemakomponenter/HjelpetekstForFelt"
import { InvalidInputAlert } from "./skjemakomponenter/InvalidInputAlert"
import Radioknapper from "./skjemakomponenter/Radioknapper"

const RegistrerAvløp = ({ handleSubmit }: { handleSubmit: (data: AvløpSchema) => void }) => {
  const { t } = useTranslation("egenregistrering")
  const { data: egenregistreringFraBruksenhet } = useEgenregistreringBruksenhet()
  const form = useForm<AvløpSchema>({
    resolver: zodResolver(avløpSchema),
    defaultValues: {
      avløp: egenregistreringFraBruksenhet?.avløp?.data,
      kildemateriale: { kode: egenregistreringFraBruksenhet?.avløp?.metadata.kildemateriale },
    },
  })

  const onSubmit = form.handleSubmit(data => {
    handleSubmit(data)
  })

  const { data: kodelister, isPending, isSuccess } = useKodelisterBygning()

  if (isPending || !isSuccess) return <Spinner />

  const valgmuligheter = Object.entries(Avløpstype).map(([key, label]) => ({
    kode: key,
    presentasjonsnavn: label,
  }))

  return (
    <form id="avløp-form" onSubmit={onSubmit}>
      <Field>
        <HjelpetekstForFelt>{t("hjelpetekst_avløp")}</HjelpetekstForFelt>
        <Radioknapper<AvløpSchema>
          name="avløp"
          label={t("registrer_felt_for_bruksenheten.avløp", { ns: "skjemaoverskrifter" })}
          control={form.control}
          valgmuligheter={valgmuligheter}
        />
        <InvalidInputAlert error={undefined} />
        <Radioknapper<AvløpSchema>
          label="Kilde"
          beskrivelse="Velg hvilken kilde du har brukt for å komme frem til avløp."
          control={form.control}
          name="kildemateriale.kode"
          valgmuligheter={kodelister}
          highlightValgtVerdi={true}
        />
      </Field>
    </form>
  )
}

export default RegistrerAvløp
