import posthog from "posthog-js"
import { ApiError } from "../core/error-handling/ApiError"
import { isEidEiendom } from "../eiendom/detaljert-eiendom/api/eiendom"
import { hentBygningstype } from "../eiendom/detaljert-eiendom/bygningsdetaljer/utils/bygningstypeUtil"
import { vannforsyningkodeMapping } from "../eiendom/detaljert-eiendom/bygningsdetaljer/utils/vannforsyningUtil"
import { api } from "../shared-api/router"
import { Bruker } from "../user/types/Bruker"

export interface Consent {
  isVisible: boolean
  consentGiven: boolean
  showDetailedConsentPage?: boolean
}

interface Payload {
  bruker: Bruker
}

export type Action =
  | { type: "GIVE_CONSENT"; payload: Payload }
  | { type: "DECLINE_CONSENT" }
  | { type: "SHOW_BANNER" }

export const initialConsent = (): Consent => {
  const consent = localStorage.getItem("cookieConsent")
  return {
    isVisible: consent === null,
    consentGiven: consent === "true",
  }
}

const hentDetaljerteEiendommer = async () => {
  const eiendommer = await api.eiendommer.hentEiendommer()
  const detaljerteEiendommer = eiendommer.map(eiendom =>
    api.eiendom.hentEiendom(eiendom.matrikkelenhetId),
  )
  const res = await Promise.all(detaljerteEiendommer)

  return { eiendommer: res }
}

export const reducer = (state: Consent, action: Action): Consent => {
  switch (action.type) {
    case "GIVE_CONSENT": {
      posthog.identify(action.payload.bruker.id.toString())
      posthog.opt_in_capturing()

      hentDetaljerteEiendommer()
        .then(payload => {
          const eiendommer = payload.eiendommer.map(eiendom => ({
            type: eiendom.type,
            matrikkelenhetstype: eiendom.matrikkelenhetstype,
            eierforhold: eiendom.eierforhold,
            erFestetBortTilAndre: eiendom.erFestetBortTilAndre,
            teigMedFlereMatrikkelenheter: eiendom.teigMedFlereMatrikkelenheter,
            kommunenavn: eiendom.kommunenavn,
            areal: eiendom.areal,
            bygninger: isEidEiendom(eiendom)
              ? eiendom.bygningerPåEiendom.map(b => ({
                  bygningstype: hentBygningstype[b.bygningstype],
                  bygningsstatus: b.bygningsstatus,
                  bruksareal: b.bruksarealTotalt,
                  antallEtasjer: b.etasjer.length,
                  etasjer: b.etasjer,
                  energikilder: b.energikilder,
                  avlop: b.avløp,
                  vannforsyning: vannforsyningkodeMapping[b.vannforsyning],
                  oppvarming: b.oppvarming,
                }))
              : null,
            antallTeiger: eiendom.antallTeiger,
            antallFestegrunner: isEidEiendom(eiendom) ? eiendom.festegrunner.length : null,
            antallBygninger: isEidEiendom(eiendom) ? eiendom.bygningerPåEiendom.length : null,
            antallBruksenheter: isEidEiendom(eiendom) ? eiendom.bruksenheterPåEiendom.length : null,
          }))

          posthog.setPersonProperties({
            antallEiendommer: eiendommer.length,
            eiendommer: eiendommer,
            finnesIMatrikkelen: true,
          })
        })
        .catch((err: ApiError) => {
          if (err.type === "FANT_INGEN_EIENDOMMER") {
            posthog.setPersonProperties({ antallEiendommer: 0, finnesIMatrikkelen: true })
          } else if (err.type === "FANT_IKKE_PERSON") {
            posthog.setPersonProperties({ antallEiendommer: 0, finnesIMatrikkelen: false })
          }
        })

      posthog.startSessionRecording()

      localStorage.setItem("cookieConsent", "true")
      return { isVisible: false, consentGiven: true }
    }

    case "DECLINE_CONSENT":
      posthog.stopSessionRecording()
      posthog.opt_out_capturing()

      localStorage.setItem("cookieConsent", "false")
      return { ...state, isVisible: false, consentGiven: false }

    case "SHOW_BANNER":
      return { ...state, isVisible: true, showDetailedConsentPage: true }

    default:
      return state
  }
}
