import { Box, Button, Center, Flex } from "@kvib/react"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import Section from "../core/components/layout/Section"
import StackMedGap from "../core/components/layout/StackMedGap"
import { useScreenView } from "../core/hooks/useScreenview"
import { Brødtekst } from "../core/text/components/Brødtekst"
import { EksternLenke } from "../core/text/components/EksternLenke"
import { Overskrift } from "../core/text/components/Overskrift"
import { InfoCard } from "../infosider/components/InfoCard"
import { useBruker } from "../user/hooks/useBruker"
import CookieTabell from "./components/CookiesTabell"
import { ConsentContext } from "./context/ConsentContext"

const Cookieserklæring = () => {
  const { t } = useTranslation("cookies", { lng: "nn-NO" })
  const { isError } = useBruker()
  const { isMobile } = useScreenView()

  const { dispatch } = useContext(ConsentContext)

  return (
    <Section pb={["20px", "160px"]}>
      <Center>
        <StackMedGap>
          <Overskrift variant="h1">{t("tittel")}</Overskrift>

          <InfoCard cardheader={t("informasjonskapsler.tittel")}>
            <Box>
              {t("informasjonskapsler", { returnObjects: true }).avsnitt.map(tekst => (
                <Box key={tekst}>
                  <Brødtekst>
                    {tekst}
                    <EksternLenke tilAdresse="https://nkom.no/internett/informasjonskapsler-cookies">
                      {t("nødvendige_informasjonskapsler.nkom_lenke")}
                    </EksternLenke>
                  </Brødtekst>
                  <br />
                </Box>
              ))}
            </Box>
            <Overskrift variant="h4">{t("nødvendige_informasjonskapsler.tittel")}</Overskrift>
            <Box>
              {t("nødvendige_informasjonskapsler", { returnObjects: true }).avsnitt.map(tekst => (
                <Box key={tekst}>
                  <Brødtekst>{tekst}</Brødtekst>
                  <br />
                </Box>
              ))}
            </Box>

            <Overskrift variant="h4">{t("webanalyse.tittel")}</Overskrift>
            <Box>
              {t("webanalyse", { returnObjects: true }).avsnitt.map(tekst => (
                <Box key={tekst}>
                  <Brødtekst>{tekst}</Brødtekst>
                  <br />
                </Box>
              ))}
            </Box>
            {!isError && (
              <>
                <Overskrift variant="h4">{t("administrer_cookies.tittel")}</Overskrift>
                <Box>
                  {t("administrer_cookies.avsnitt", { returnObjects: true }).map(tekst => (
                    <Box key={tekst}>
                      <Brødtekst>{tekst}</Brødtekst>
                      <br />
                    </Box>
                  ))}
                </Box>
                <Flex alignItems="end" justifyContent="flex-start">
                  <Button
                    id="administer-cookies"
                    variant="secondary"
                    onClick={() => dispatch({ type: "SHOW_BANNER" })}
                  >
                    {t("administrer_cookies.tittel")}
                  </Button>
                </Flex>
              </>
            )}
          </InfoCard>

          {!isMobile && (
            <InfoCard cardheader={t("oversikt_over_cookies_på_mineiendom")}>
              <CookieTabell />
            </InfoCard>
          )}

          <InfoCard cardheader={t("cookiesforklaring_tittel")}>
            <Box>
              {t("cookiesforklaring", { returnObjects: true }).map(tekst => (
                <Box key={tekst.tittel}>
                  <Overskrift variant="h4">{tekst.tittel}</Overskrift>
                  <Brødtekst>{tekst.avsnitt}</Brødtekst>
                  <br />
                </Box>
              ))}
            </Box>
          </InfoCard>
        </StackMedGap>
      </Center>
    </Section>
  )
}

export default Cookieserklæring
