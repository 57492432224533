import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Flex,
} from "@kvib/react"
import { useScreenView } from "../../../../core/hooks/useScreenview"
import { GåTilEgenregistreringKnapp } from "../../../registrering/components/GåTilEgenregistreringKnapp"
import { useEiendom } from "../../api/useEiendom"
import useCurrentBygning from "../../hooks/useCurrentBygning"
import { getVegadresserPåBygning } from "../utils/bygningerUtils"
import { BygningskortAccordion } from "./BygningskortAccordion"
import BygningstypeOgVegadresseOverskrift from "./BygningstypeOgAdresseOverskrift"

interface Props extends CardProps {
  bygningId: string
}

const Bygningskort = ({ bygningId, children, ...props }: Props) => {
  const { isMobile } = useScreenView()
  const { currentBygning, dineBruksenheterIBygning } = useCurrentBygning(bygningId)
  const { data: eiendom } = useEiendom()

  if (!currentBygning || !dineBruksenheterIBygning || !eiendom || !eiendom.adresser.vegadresser)
    return null

  const vegadresserPåBygning = getVegadresserPåBygning(
    eiendom.adresser.vegadresser,
    dineBruksenheterIBygning.map(enhet => enhet.bruksenhetsId),
  )

  return (
    <Card {...props}>
      {isMobile ? (
        <Accordion collapsible>
          <AccordionItem p={0} as={Card} border="none" value="bygningskort-mobil-accordion">
            <h3>
              <AccordionButton
                p={2}
                minH="120px"
                _hover={{
                  bg: "inherit",
                  borderRadius: "inherit",
                }}
                _expanded={{
                  borderRadius: "inherit",
                }}
              >
                <BygningstypeOgVegadresseOverskrift
                  erSeksjon={eiendom.matrikkelenhetstype === "Eierseksjon"}
                  bygningstype={currentBygning.bygningstype}
                  vegadresse={vegadresserPåBygning}
                />
                <AccordionIcon />
              </AccordionButton>
            </h3>
            <AccordionPanel mb="16px" p={0}>
              {children}
              <Flex m="16px">
                <GåTilEgenregistreringKnapp
                  bygningId={bygningId}
                  bruksenhetId={dineBruksenheterIBygning[0].bruksenhetsId}
                />
              </Flex>
              <Accordion multiple collapsible>
                <BygningskortAccordion bygningsId={bygningId} />
              </Accordion>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : (
        <>
          <CardHeader as={Flex}>
            <BygningstypeOgVegadresseOverskrift
              erSeksjon={eiendom.matrikkelenhetstype === "Eierseksjon"}
              bygningstype={currentBygning.bygningstype}
              vegadresse={vegadresserPåBygning}
            />
            <Flex m="16px">
              <GåTilEgenregistreringKnapp
                bygningId={bygningId}
                bruksenhetId={dineBruksenheterIBygning[0].bruksenhetsId}
              />
            </Flex>
          </CardHeader>
          <CardBody pt="0">
            <BygningskortAccordion bygningsId={bygningId} />
          </CardBody>
        </>
      )}
    </Card>
  )
}

export default Bygningskort
