import { Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { Matrikkelnummer } from "../../eiendomsoversikt/types/Eiendom"
import {
  formaterMatrikkelnummer,
  verboseMatrikkelnummer,
} from "../../eiendomsoversikt/utils/eiendomUtils"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import VerdiMedForklaring from "../../components/VerdiMedForklaring"

const Matrikkelnummerdetalj = ({ matrikkelnummer }: { matrikkelnummer?: Matrikkelnummer }) => {
  const matrikkelnummerId = "matrikkelnummer-" + matrikkelnummer
  const { t } = useTranslation("nøkkelbegreper")

  return (
    <VerdiMedForklaring
      label={t("matrikkelnummer")}
      forklaring={t("matrikkelnummer_forklaring")}
      posthogName="matrikkelnummer_detalj_info_clicked"
    >
      {matrikkelnummer ? (
        <FeltverdiForEiendomsdetaljer
          className="ph-no-capture"
          id={matrikkelnummerId}
          aria-label={verboseMatrikkelnummer(formaterMatrikkelnummer(matrikkelnummer))}
          fieldValue={formaterMatrikkelnummer(matrikkelnummer)}
        />
      ) : (
        <Skeleton w="100%" h="24px" />
      )}
    </VerdiMedForklaring>
  )
}

export default Matrikkelnummerdetalj
