import { Box, BoxProps } from "@kvib/react"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { useEiendom } from "../../detaljert-eiendom/api/useEiendom"
import useCurrentBygning from "../../detaljert-eiendom/hooks/useCurrentBygning"
import { useKodelister } from "../../kodelister/useKodelister"
import { Felt } from "../types/schema"
import { visMatrikkelregistrertVerdi } from "../utils"
import { useTranslation } from "react-i18next"

interface Props extends BoxProps {
  felt: Felt
}

export const MatrikkelVerdi = ({ felt }: Props) => {
  const { currentBygning, dineBruksenheterIBygning } = useCurrentBygning()
  const { data: eiendom } = useEiendom()
  const { data: kodelister } = useKodelister()
  const { t } = useTranslation("egenregistrering")

  if (!eiendom) return null

  if (!currentBygning || !kodelister || !dineBruksenheterIBygning) return null

  return (
    <Box p="12px" w="300px">
      <Brødtekst fontWeight="bold">
        {visMatrikkelregistrertVerdi(felt, currentBygning, dineBruksenheterIBygning, kodelister)}
      </Brødtekst>
      <Brødtekst litenVariant as="i">
        {t("matrikkelverdi_undertittel")}
      </Brødtekst>
    </Box>
  )
}
