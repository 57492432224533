import { Box, Grid, GridProps } from "@kvib/react"
import { Children } from "react"

const EiendomskortContainer = ({ ...props }: GridProps) => {
  const skalVisesSomKolonne = props.flexDirection === "column" || props.flexDir === "column"

  const buttonProps: GridProps = {
    _hover: {
      boxShadow: "{shadows.lg}",
      cursor: "pointer",
    },
    tabIndex: 0,
    transition: "box-shadow 0.2s ease-in-out",
    role: "button",
  }

  const children = Children.toArray(props.children)

  // Destructuring av children
  const [kartutsnitt, ...rest] = children

  return (
    <Grid
      bg="white"
      borderRadius="{radii.base}"
      boxShadow="{shadows.md}"
      gridTemplateColumns={skalVisesSomKolonne ? "1fr" : "40% 1fr"}
      gridTemplateRows={skalVisesSomKolonne ? "1fr 1fr" : "1fr"}
      overflow="hidden"
      {...(props.onClick ? buttonProps : {})}
      {...props}
    >
      {kartutsnitt}
      <Box p={["16px", "32px"]}>{rest}</Box>
    </Grid>
  )
}

export default EiendomskortContainer
