import { zodResolver } from "@hookform/resolvers/zod"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Field,
  Flex,
  FormLabel,
  Spinner,
} from "@kvib/react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { Brødtekst } from "../../../../../core/text/components/Brødtekst"
import { useEiendom } from "../../../../detaljert-eiendom/api/useEiendom"
import { sorterEtterPlankodeDeretterEtasjeummer } from "../../../../detaljert-eiendom/bygningsdetaljer/utils/etasjeUtil"
import useCurrentBygning from "../../../../detaljert-eiendom/hooks/useCurrentBygning"
import { EidDetaljertEiendom } from "../../../../detaljert-eiendom/types/DetaljertEiendom"
import { useKodelisterBygning } from "../../../../kodelister/useKodelisterBygning"
import useEgenregistreringBruksenhet from "../../../api/useEgenregistreringBruksenhet"
import { BruksarealSchema, bruksarealSchema } from "../../../types/schema"
import HjelpetekstForFelt from "../HjelpetekstForFelt"
import InputfeltTall from "../InputfeltTall"
import { InvalidInputAlert } from "../InvalidInputAlert"
import Radioknapper from "../Radioknapper"
import { BruksarealPerEtasjeInput } from "./BruksarealPerEtasjeInput"
import { Overskrift } from "../../../../../core/text/components/Overskrift"

const BruksarealForm = ({ handleSubmit }: { handleSubmit: (data: BruksarealSchema) => void }) => {
  const { bygningId } = useParams()
  const { data: egenregistreringFraBruksenhet } = useEgenregistreringBruksenhet()

  const bruksarealForm = useForm<BruksarealSchema>({
    resolver: zodResolver(bruksarealSchema),
    defaultValues: {
      bruksareal: {
        totaltBruksareal: egenregistreringFraBruksenhet?.bruksareal?.data.totaltBruksareal,
      },
      kildemateriale: {
        kode: egenregistreringFraBruksenhet?.bruksareal?.metadata.kildemateriale,
      },
    },
  })

  const { data: eidEiendom } = useEiendom<EidDetaljertEiendom>()
  const { dineBruksenheterIBygning } = useCurrentBygning()
  const [etasjeBruksarealValues, setEtasjeBruksarealValues] = useState<{
    [key: string]:
      | { bruksareal: number; etasjebetegnelse: { etasjeplanKode: string; etasjenummer: number } }
      | undefined
  }>({})
  const { t } = useTranslation(["egenregistrering", "skjemaoverskrifter"])

  const etasjer = eidEiendom?.bygningerPåEiendom.find(b => b.byggId === bygningId)?.etasjer

  useEffect(() => {
    const etasjeRegistreringer = Object.values(etasjeBruksarealValues).map(value => ({
      bruksareal: value?.bruksareal || 0,
      etasjebetegnelse: value?.etasjebetegnelse || { etasjeplanKode: "", etasjenummer: 0 },
    }))

    bruksarealForm.setValue("bruksareal.etasjeRegistreringer", etasjeRegistreringer)
  }, [etasjeBruksarealValues, bruksarealForm])

  const handleAreaChange = ({
    etasjenummer,
    etasjeplankode,
    areal,
  }: {
    etasjenummer: string
    etasjeplankode: string
    areal: string
  }) => {
    setEtasjeBruksarealValues(previousValues => {
      // Lager en unik nøkkel for denne etasjen
      const key = `${etasjeplankode}+${etasjenummer}`

      const newAreaData = {
        bruksareal: Number(areal),
        etasjebetegnelse: {
          etasjeplanKode: etasjeplankode,
          etasjenummer: Number(etasjenummer),
        },
      }

      // Oppdater verdiene med det nye verdiparet
      return {
        ...previousValues,
        [key]: newAreaData,
      }
    })
  }
  const { data: kodelister, isPending, isSuccess } = useKodelisterBygning()

  if (isPending || !isSuccess) return <Spinner />

  if (!eidEiendom || !dineBruksenheterIBygning || !bygningId) return null

  const onSubmit = bruksarealForm.handleSubmit(data => {
    handleSubmit(data)
  })

  return (
    <form id="bruksareal-form" onSubmit={onSubmit}>
      <Field>
        <Box mt="24px">
          <FormLabel>
            <Overskrift variant="h4">
              {t("registrer_felt_for_bruksenheten.bruksareal_totalt", {
                ns: "skjemaoverskrifter",
              })}
            </Overskrift>
          </FormLabel>
          <Flex mt="16px" gap="8px" alignItems="center">
            <InputfeltTall
              register={bruksarealForm.register("bruksareal.totaltBruksareal", {
                valueAsNumber: true,
              })}
            />
            <Brødtekst>m²</Brødtekst>
          </Flex>
        </Box>
        <Accordion collapsible>
          <AccordionItem borderTop="none" borderBottom="none" value="bruksareal-accordion">
            <AccordionButton pl="0">
              <Flex textAlign="left">
                <Brødtekst>{t("vil_registrere_areal_per_etasje_tittel")}</Brødtekst>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pl={0}>
              <HjelpetekstForFelt colorSceheme="green.50">
                {t("vil_registrere_bruksareal_per_etasje_hjelpetekst")}
              </HjelpetekstForFelt>
              {etasjer && etasjer.length > 0 ? (
                <BruksarealPerEtasjeInput
                  name="bruksareal.etasjeRegistreringer"
                  handleAreaChange={handleAreaChange}
                  etasjer={sorterEtterPlankodeDeretterEtasjeummer(etasjer)}
                />
              ) : (
                <Box>
                  <Brødtekst>{t("hjelpetekst_bruksareal_ingen_registrerte_etasjer")}</Brødtekst>
                </Box>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <InvalidInputAlert error={bruksarealForm.formState.errors.bruksareal?.totaltBruksareal} />
        <Radioknapper<BruksarealSchema>
          label="Kilde"
          beskrivelse="Velg hvilken kilde du har brukt for å komme frem til bruksarealet."
          control={bruksarealForm.control}
          name="kildemateriale.kode"
          valgmuligheter={kodelister}
          highlightValgtVerdi={true}
        />
        <InvalidInputAlert error={bruksarealForm.formState.errors.kildemateriale?.kode} />
      </Field>
    </form>
  )
}

export default BruksarealForm
