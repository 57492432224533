import { Box } from "@kvib/react"
import { Feature, Map, View } from "ol"
import Point from "ol/geom/Point"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import { XYZ } from "ol/source"
import VectorSource from "ol/source/Vector"
import Icon from "ol/style/Icon"
import Style from "ol/style/Style"
import { ViewOptions } from "ol/View"
import { useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import pin from "../../../assets/pin.svg"
import { useScreenView } from "../../core/hooks/useScreenview"
import { useEiendom } from "../../eiendom/detaljert-eiendom/api/useEiendom"
import useCurrentBygning from "../../eiendom/detaljert-eiendom/hooks/useCurrentBygning"
import useTeigpolygoner from "../../interaktivtKart/api/useTeigpolygoner"
import { KART_EXTENT, projeksjon } from "../../interaktivtKart/constants"
import { hentBboxForPolygoner } from "../../interaktivtKart/utils"
import { NorgeskartKnapp } from "./NorgeskartKnapp"
import FantIkkeKartutstnittBox from "./FantIkkeKartutstnittBox"

const KartutsnittMedBygningskoordinat = ({ bygningId }: { bygningId: string }) => {
  const { currentBygning } = useCurrentBygning(bygningId)
  const { data: eiendom } = useEiendom()
  const { data: teigpolygoner, isSuccess, isPending } = useTeigpolygoner(eiendom?.matrikkelnummer)

  const { t } = useTranslation("bygningskort")

  const { isMobile } = useScreenView()

  const mapRef = useRef<HTMLDivElement | null>(null)

  const extent = useMemo(() => {
    if (teigpolygoner && teigpolygoner.length > 0) {
      return hentBboxForPolygoner(teigpolygoner)
    }
    return null
  }, [teigpolygoner])

  const kart = useMemo(() => {
    if (!currentBygning?.representasjonspunkt || !eiendom?.koordinat) return null

    const bygningsmarkør = new Feature({
      geometry: new Point([
        currentBygning.representasjonspunkt.long,
        currentBygning.representasjonspunkt.lat,
      ]),
    })

    const markørStyle = new Style({ image: new Icon({ src: pin, scale: 0.7, anchor: [0.5, 1] }) })

    bygningsmarkør.setStyle(markørStyle)

    const viewConfig: ViewOptions = {
      projection: projeksjon,
      extent: KART_EXTENT,
      center: [eiendom.koordinat.long, eiendom.koordinat.lat],
    }

    const newMap = new Map({
      interactions: [],
      controls: [],
      maxTilesLoading: 4,
      layers: [
        new TileLayer({
          source: new XYZ({
            crossOrigin: "*",
            projection: projeksjon,
            url: `https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/utm33n/{z}/{y}/{x}.png`,
          }),
        }),
        new VectorLayer({
          source: new VectorSource({
            features: [bygningsmarkør],
          }),
        }),
      ],
      view: new View(viewConfig),
    })

    return newMap
  }, [currentBygning, eiendom])

  useEffect(() => {
    if (kart && mapRef.current) {
      kart.setTarget(mapRef.current)
      if (extent) {
        kart.getView().fit(extent, {
          size: kart.getSize(),
          padding: Array(4).fill(50),
          maxZoom: 18,
        })
      }
      return () => kart.setTarget()
    }
  }, [kart, extent])

  if (!currentBygning || !eiendom || !isSuccess || isPending) return null

  if (!currentBygning?.representasjonspunkt) {
    return (
      <FantIkkeKartutstnittBox
        tekst="Bygningen mangler koordinater, og kan derfor ikke vises i kartet."
        imageProps={{
          filter: "grayscale(100%)",
          height: "50%",
        }}
        boxProxs={{
          overflow: "hidden",
          aspectRatio: "4/3",
          bg: "gray.100",
        }}
      />
    )
  }

  return (
    <Box
      className="ph-no-capture"
      pos="relative"
      overflow="hidden"
      aspectRatio="4/3"
      {...(!isMobile && {
        borderRadius: "var(--kvib-radii-md)",
        boxShadow: "var(--kvib-shadows-base)",
      })}
    >
      <div ref={mapRef} id={bygningId} tabIndex={1} style={{ height: "100%", width: "100%" }} />
      {currentBygning.representasjonspunkt && (
        <NorgeskartKnapp koordinat={currentBygning.representasjonspunkt}>
          {t("vis_bygning_i_kart_knapp")}
        </NorgeskartKnapp>
      )}
    </Box>
  )
}

export default KartutsnittMedBygningskoordinat
