import { Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import VerdiMedForklaring from "../../components/VerdiMedForklaring"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"

const Eierandeldetalj = ({ eierandel }: { eierandel: string | undefined }) => {
  const { t } = useTranslation(["detaljertInnsyn", "nøkkelbegreper"])
  const eierandelFerdigFetched = eierandel !== undefined

  return (
    <VerdiMedForklaring
      label={t("din_andel_overskrift")}
      posthogName="din_eierandel_info_click"
      forklaring={t("eierandel_forklaring") as string}
    >
      {eierandelFerdigFetched ? (
        <FeltverdiForEiendomsdetaljer
          fieldValue={eierandel || t("ikke_registrert", { ns: "nøkkelbegreper" })}
        />
      ) : (
        <Skeleton w="60%" h="24px" />
      )}
    </VerdiMedForklaring>
  )
}

export default Eierandeldetalj
