import { zodResolver } from "@hookform/resolvers/zod"
import { Field, Spinner } from "@kvib/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useKodelisterBygning } from "../../kodelister/useKodelisterBygning"
import useEgenregistreringBruksenhet from "../api/useEgenregistreringBruksenhet"
import { Energikildetype } from "../types/Enums"
import { EnergikilderSchema, energikilderSchema } from "../types/schema"
import Flervalg from "./skjemakomponenter/Flervalg"
import HjelpetekstForFelt from "./skjemakomponenter/HjelpetekstForFelt"
import { InvalidInputAlert } from "./skjemakomponenter/InvalidInputAlert"
import Radioknapper from "./skjemakomponenter/Radioknapper"

const RegistrerEnergikilder = ({
  handleSubmit,
}: {
  handleSubmit: (data: EnergikilderSchema) => void
}) => {
  const { t } = useTranslation("egenregistrering")
  const { data: egenregistreringFraBruksenhet } = useEgenregistreringBruksenhet()
  const form = useForm<EnergikilderSchema>({
    resolver: zodResolver(energikilderSchema),
    defaultValues: {
      energikilder: egenregistreringFraBruksenhet?.energikilder?.map(
        energikilde => energikilde?.data,
      ),
      kildemateriale: {
        kode: egenregistreringFraBruksenhet?.energikilder?.find(
          energikilde => energikilde?.metadata?.kildemateriale,
        )?.metadata?.kildemateriale,
      },
    },
  })

  const onSubmit = form.handleSubmit(data => {
    handleSubmit(data)
  })

  const { data: kodelister, isPending, isSuccess } = useKodelisterBygning()

  if (isPending || !isSuccess) return <Spinner />

  return (
    <form id="energikilder-form" onSubmit={onSubmit}>
      <Field>
        <HjelpetekstForFelt>{t("hjelpetekst_energikilder")}</HjelpetekstForFelt>
        <Flervalg
          name="energikilder"
          options={Energikildetype}
          register={form.register("energikilder")}
        />
        <Radioknapper<EnergikilderSchema>
          label="Kilde"
          beskrivelse="Velg hvilken kilde du har brukt for å komme frem til energikilder."
          control={form.control}
          name="kildemateriale.kode"
          valgmuligheter={kodelister}
          highlightValgtVerdi={true}
        />
        <InvalidInputAlert error={undefined} />
      </Field>
    </form>
  )
}

export default RegistrerEnergikilder
