import { Table, Tbody, Td, Th, Thead, Tr } from "@kvib/react"
import { useTranslation } from "react-i18next"

const CookieTabell = () => {
  const { t } = useTranslation("cookies")
  const cookietabell = t("cookiestabell", { returnObjects: true })
  return (
    <Table striped>
      <Thead>
        <Tr>
          {t("cookiestabell_header", { returnObjects: true }).map(header => (
            <Th key={header}>{header}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {cookietabell.map(cookie => (
          <Tr key={cookie.navn}>
            <Td>{cookie.navn}</Td>
            <Td>{cookie.formal}</Td>
            <Td>{cookie.valgfrihet}</Td>
            <Td>{cookie.utsteder}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default CookieTabell
