import { useTranslation } from "react-i18next"
import { useScreenView } from "../../core/hooks/useScreenview"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import VerdiMedForklaring from "./VerdiMedForklaring"

interface InfoPopoverProps {
  matrikkelnummer: string
}

export const MatrikkelnummerInfoPopover = (props: InfoPopoverProps) => {
  const { t } = useTranslation("nøkkelbegreper")
  const { isDesktop } = useScreenView()

  return (
    <VerdiMedForklaring
      label={t("matrikkelnummer")}
      forklaring={t("matrikkelnummer_forklaring")}
      posthogName="matrikkelnummer_info_click"
    >
      <Brødtekst
        className="ph-no-capture"
        color="gray.500"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        lineHeight="24px"
        fontWeight="bold"
        fontSize={isDesktop ? "m" : "sm"}
      >
        {props.matrikkelnummer}
      </Brødtekst>
    </VerdiMedForklaring>
  )
}
