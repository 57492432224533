import { Heading, HeadingProps } from "@kvib/react"
import { useFontStyles } from "../fontStyles"

interface OverskriftProps extends HeadingProps {
  variant: "h1" | "h2" | "h3" | "h4"
  children: string
}

export const Overskrift = ({ children, variant, ...props }: OverskriftProps) => {
  const { getFontSize, getFontWeight } = useFontStyles()

  const fontSize = getFontSize(variant)
  const fontWeight = getFontWeight(variant)

  return (
    <Heading
      lineHeight="normal"
      fontSize={fontSize}
      as={variant}
      fontWeight={fontWeight}
      color="black"
      {...props}
    >
      {children}
    </Heading>
  )
}
