import { Box, Flex, NumberInputField, NumberInputRoot } from "@kvib/react"
import { Brødtekst } from "../../../../../core/text/components/Brødtekst"
import { Etasje } from "../../../../detaljert-eiendom/bygningsdetaljer/types/Bygning"
import { etasjeplankodeMapping } from "../../../../detaljert-eiendom/bygningsdetaljer/utils/etasjeUtil"
import { Overskrift } from "../../../../../core/text/components/Overskrift"

type Props = {
  etasjer: Etasje[]

  name: "bruksareal.etasjeRegistreringer"
  handleAreaChange: ({
    etasjenummer,
    etasjeplankode,
    areal,
  }: {
    etasjenummer: string
    etasjeplankode: string
    areal: string
  }) => void
}

export const BruksarealPerEtasjeInput = ({ etasjer, handleAreaChange, name }: Props) => {
  return etasjer.map(etasje => {
    const key = `${etasje.etasjeplankode}+${etasje.etasjenummer}`

    return (
      <Box key={key} mt="16px">
        <Flex m={2} gap={2} alignItems="center" whiteSpace="nowrap">
          <Box width="170px">
            <Overskrift variant="h4">{`${etasje.etasjenummer}. ${etasjeplankodeMapping[etasje.etasjeplankode]}`}</Overskrift>
          </Box>
          <NumberInputRoot min={0}>
            <NumberInputField
              name={name}
              onChange={e => {
                handleAreaChange({
                  etasjenummer: etasje.etasjenummer,
                  etasjeplankode: etasje.etasjeplankode,
                  areal: e.target.value,
                })
              }}
            />
          </NumberInputRoot>
          <Brødtekst>m²</Brødtekst>
        </Flex>
      </Box>
    )
  })
}
