import { Button, Flex, Image } from "@kvib/react"
import { usePostHog } from "posthog-js/react"
import { useNavigate } from "react-router"
import manglerEiendommerIllustrasjon from "../../../assets/questionmark_man.svg"
import useHandleLogout from "../../auth/hooks/useHandleLogout"
import InfotekstHvorforDukkerIkkeEiendommenMinOpp from "../../infosider/hjelp-og-stotte/InfotekstHvorforDukkerIkkeEiendommenMinOpp"
import { useScreenView } from "../hooks/useScreenview"
import { FinnerIkkeEiendommerErrorBox } from "./FinnerIkkeEiendommerInfoboks"
import { ErrorType } from "./types/ErrorType"
import { useTranslation } from "react-i18next"

interface Props {
  errorType: Extract<ErrorType, "FANT_IKKE_PERSON" | "FANT_INGEN_EIENDOMMER">
}

const FinnerIkkeEiendommer = (props: Props) => {
  const brukerIkkeFunnet = !!(props.errorType === "FANT_IKKE_PERSON")
  const posthog = usePostHog()
  const navigate = useNavigate()
  const { isDesktop } = useScreenView()
  const { mutate: handleLogout } = useHandleLogout()

  const { t } = useTranslation("ofteStilteSpørsmål")
  const tekster = t("hvorfor_ser_jeg_ikke_eiendommen", {
    returnObjects: true,
  })

  return (
    <FinnerIkkeEiendommerErrorBox tittel={tekster.tittel}>
      <Flex flexDir={isDesktop ? "row" : "column"}>
        <InfotekstHvorforDukkerIkkeEiendommenMinOpp skalViseFnrSomÅrsak={brukerIkkeFunnet} />
        {isDesktop && (
          <Image
            src={manglerEiendommerIllustrasjon}
            p="12px"
            ml="3em"
            mr="auto"
            aria-hidden="true"
            fit="contain"
          />
        )}
      </Flex>
      {brukerIkkeFunnet ? (
        <Button w="fit-content" leftIcon="logout" onClick={() => handleLogout()} iconAriaIsHidden>
          Logg ut av ID-porten
        </Button>
      ) : (
        <Button
          rightIcon="arrow_forward"
          onClick={() => {
            navigate("/hjelp")
            posthog.capture("Les mer om utvalget")
          }}
          iconAriaIsHidden
          w="fit-content"
          mt="24px"
        >
          {tekster.les_mer}
        </Button>
      )}
    </FinnerIkkeEiendommerErrorBox>
  )
}

export default FinnerIkkeEiendommer
