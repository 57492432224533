const keys = {
  "nb-NO": {
    hvorfor_ser_jeg_ikke_eiendommen: {
      tittel: "Det er ikke registrert noen eiendommer på deg",
      avsnitt: [
        "Forventet du å finne eiendom her?",
        "Det kan være flere grunner til at eiendommen din ikke er tilgjengelig i løsningen:",
        "Fødselsnummeret ditt er ikke registrert i Eiendomsregisteret (matrikkelen).",
        "Hvis du ikke er registrert eier i Eiendomsregistreret vil ikke eiendommen vises.",
        "Du er ikke registrert eier eller fester.",
        "Det er kun eiendommer du eier eller fester som vises i løsningen, hvis du leier vil det ikke vises her.",
        "Du eier en leilighet i et borettslag eller et boligselskap.",
        "Borettslagsandeler og aksjeleiligheter vises ikke her.",
        "Du har nettopp kjøpt eller overtatt eiendom.",
        "Det kan ta litt tid før nytt eierskap oppdateres.",
        "Eiendommen eies av et selskap (eller annet juridisk rettssubjekt).",
        "Du får kun innsyn i eiendom eid av deg som privatperson.",
      ],
      les_mer: "Les mer om eiendommene vi viser",
    },
    hvorfor_flere_adresser: {
      tittel: "Hvorfor ser jeg flere adresser?",
      avsnitt: [
        "Mulige årsaker til at du ser flere adresser kan være at bygningen har flere innganger eller at det er flere bygninger på eiendommen som skal ha sin egen adresse.",
      ],
    },
    hvorfor_ikke_adresse: {
      tittel: "Hvorfor har jeg ikke adresse?",
      avsnitt: [
        "Hvis det står at eiendommen ikke har adresse betyr dette at eiendommen ikke er tildelt en offisiell vegadresse. Mulige årsaker kan være at eiendommen ikke er bebygd eller ligger i et område uten veier.",
      ],
    },
    mer_om_adresser_link: "Les mer om adresser",
    betydning_opplysninger_mine_eiendommer_overskrift:
      "Hva betyr opplysningene som vises om mine eiendommer?",
    betydning_opplysninger_bygninger: {
      tittel: "Hva betyr opplysningene som vises om bygningene?",
      avsnitt: [
        "På detaljsiden for hvor eiendom ser du en oversikt over alle registrerte bygninger på eiendommen. For hver bygning kan du se følgende opplysninger:",
      ],
    },
    kartutsnitt_bygning: {
      tittel: "Kartutsnitt med bygningen",
      avsnitt: [
        "Kartutsnittet er sentrert på bygningen hvis bygningskoordinaten ligger innenfor bygningsflaten i kartet. Hvis dette ser feil ut kan det være fordi koordinaten er plassert et annet sted enn bygningen. For å rette dette kan du ta kontakt med kommunen der bygningen ligger.",
      ],
    },
    totalt_bruksareal: {
      tittel: "Totalt bruksareal",
      avsnitt: [
        "Det totale registrerte bruksarealet internt i bygningen. Dette kan inkludere areal for loft- og kjellerbod, innglasset balkong og lignende.",
      ],
    },
    bygningsnummer: {
      tittel: "Bygningsnummer",
      avsnitt: [
        "Et bygningsnummer er bygningens unike nummer og brukes for å identifisere bygningen.",
        "Løsningen viser ikke bygningsendringer som tilbygg og påbygg. Registrerte bygningsendringer kan være synlig i andre tjenester for eksempel på Se eiendom og Norgeskart. Disse er markert med en -1, -2 osv. på slutten av bygningsnummeret.",
      ],
    },
    detaljert_bygningstype: {
      tittel: "Detaljert bygningstype",
      avsnitt: [
        "Alle bygninger har en bygningstype som forklarer bygningens funksjon. Informasjonen er hentet fra Norsk standard for bygningstype (NS3457:1995), som kategoriserer bygninger etter hvilken hovedfunksjon det har.",
      ],
    },
    betydning_opplysninger_seksjon_overskrift: "Hva betyr opplysningene som vises om seksjonen?",
    seksjonsnummer: {
      tittel: "Din seksjon (eks. H0303)",
      avsnitt: [
        "I tillegg til seksjonsnummeret har seksjonen din et bruksenhetsnummer. Bokstaven og de to første tallene angir hvilken etasje leiligheten ligger i. De to siste tallene angir leilighetens nummer i etasjen, som regel satt fra venstre mot høyre.",
      ],
    },
    bruksareal_seksjon: {
      tittel: "Bruksareal",
      avsnitt: [
        "Det totale registrerte bruksarealet på din seksjon. Dette er areal inne i bygningen, og kan inkludere areal for loft- og kjellerbod, innglasset balkong og lignende. Uteområder som hage og parkeringsplass regnes ikke med i bruksarealet.",
      ],
    },
    inngang_seksjon: {
      tittel: "Inngang",
      avsnitt: ["Hvilken etasje inngangsdøren til bruksenheten ligger i."],
    },
    forklaring_matrikkelenhetstypene: {
      tittel: "Hva er matrikkelenhetstypene?",
      avsnitt: [
        "Alle eiendommer må være registrert med en matrikkelenhetstype som beskriver eiendommens fysiske eller formelle egenskaper, slik som utstrekning eller måten eiendommen kan eies på. De ulike matrikkelenhetstypene er:",
      ],
    },
    grunneiendom_forklaring: {
      tittel: "Grunneiendom",
      avsnitt: [
        "Eiendom som er avgrenset med eiendomsgrenser på jordoverflaten og strekker seg så langt ned og opp som eiendomsretten rekker. En grunneiendom har typisk en eller flere grunneiere og er eksempelvis en landbrukseiendom eller boligeiendom. En grunneiendom har kun eget gårds- og bruksnummer, ikke festenummer og seksjonsnummer. Grunneiendom er den vanligste matrikkelenheten i Norge.",
      ],
    },
    festegrunn_forklaring: {
      tittel: "Festegrunn",
      avsnitt: [
        "Del av grunneiendom eller jordsameie som noen har festerett til eller som kan festes bort (feste betyr å leie), eller som noen har en tilsvarende eksklusiv og langvarig bruksrett til. Når man fester eier man ikke grunnen selv. Et eksempel kan være et naust eller bolighus som ikke blir disponert av grunneieren selv, men av den som fester. Enten så fester man et areal hvor grensene er avklart, eller punktfeste som ikke har en klar avgrensing. En festegrunn har både gårds- og bruksnummer, og et festenummer i tillegg.",
      ],
    },
    seksjon_forklaring: {
      tittel: "Eierseksjon",
      avsnitt: [
        "En eierseksjon er den del av en eiendom som er seksjonert (delt). Den som eier en eierseksjon, eier dermed en eierandel av eiendommen. Hele eiendommen er i sameie med de andre seksjonseierne. Et typisk eksempel er en boligblokk med flere leiligheter, der man eier sin egen seksjon av bygget, for eksempel en leilighet. En eierseksjon har et seksjonsnummer i matrikkelnummeret, dette er med på å indikere at det er en eierseksjon.",
      ],
    },
    anleggseiendom_forklaring: {
      tittel: "Anleggseiendom",
      avsnitt: [
        "En bygning, konstruksjon, eller et avgrenset volum som er utbygd eller kan utbygges. Eiendommen strekker seg ut over eller inn under en annen eiendom. Et parkeringsanlegg som befinner seg under bakken og strekker seg under en annen eiendom er et typisk eksempel på en anleggseiendom.",
      ],
    },
    jordsameie_forklaring: {
      tittel: "Jordsameie",
      avsnitt: [
        "Et grunnareal som er eies av flere grunneiendommer. Jordsameie er ikke inndelt etter hva hver grunneiendom eier, men alt eies i fellesskap av alle grunneiendommene. Grunneiendommene som er eiere i jordsameier, er ofte gårder (bruk). Jordsameie har en historisk opprinnelse. Et eksempel på jordsameier er utmarksområder, gjerne opp mot fjellene. Jordsameie har eget gårds- og bruksnummer hvis det er registret i matrikkelen. I et jordsameie kan ikke en av gårdeierene selge sin ideelle andel, uten at gården overdras samtidig.",
      ],
    },
    annen_type_forklaring: {
      tittel: "Annen type",
      avsnitt: [
        "Eiendommen er av eldre type og er tildelt matrikkelnummer etter tidligere regelverk. Etter tidligere regelverk har det vært mulig å registrere for eksempel bruksretter som egne enheter i matrikkelen og de vil vises som “Annen type“ i dag. Eksempeler på slike varige bruksretter som kan være gitt eget gårds- og bruksnummer, er skog, beite, fiske og vannfall.",
      ],
    },
    forklaring_bygningsstatusene: {
      tittel: "Hva betyr bygningsstatusene som vises om bygningene?",
      avsnitt: [
        "En bygning har en eller flere bygningsstatuser som beskriver hvordan bygget er registrert for bruk, bygging eller riving i Eiendomsregisteret (matrikkelen). Statusen avhenger blant annet av hvilket regelverk som gjaldt da bygningen ble oppført. I hovedsak samsvarer bygningsstatusene med reglene i plan- og bygningsloven. Bygninger som er avlyst, revet, brent, flyttet eller hvis bygningsnummeret er utgått, vises ikke i løsningen.",
        "I oversikten finner du de ulike statusene og hva de betyr.",
        "Mener du statusen er feil og har dokumentasjon på en annen status, kan du ta kontakt med kommunen for veiledning og oppdatering.",
      ],
    },
    rammetillatelse: {
      tittel: "Rammetillatelse",
      avsnitt: [
        "Forhåndsgodkjenning fra kommunen som setter de overordnede rammene for et byggeprosjekt. En rammetillatelse er avhengig av en igangsettingstillatelse før bygging kan starte.",
      ],
    },
    igangsettingstillatelse: {
      tittel: "Igangsettingstillatelse",
      avsnitt: ["Tillatelse fra kommunen til å starte bygging."],
    },
    midlertidig_brukstillatelse: {
      tittel: "Midlertidig brukstillatelse",
      avsnitt: [
        "Tillatelse fra kommunen som gir lov til å ta i bruk et byggverk før alt arbeid er fullført.",
      ],
    },
    ferdigattest: {
      tittel: "Ferdigattest",
      avsnitt: ["Det endelige beviset på at byggeprosjektet er lovlig utført og klart for bruk."],
    },
    tatt_i_bruk: {
      tittel: "Tatt i bruk",
      avsnitt: [
        "Statuskode ofte brukt for bygninger som regnes som ferdigstilt og kan tas i bruk.",
      ],
    },
    melding_registrer_tiltak: {
      tittel: "Meldingssak registrer tiltak",
      avsnitt: [
        "En tillatelse fra kommunen til å starte bygging av et søknadspliktig tiltak. Statusen er utgått og brukes ikke lenger i nye byggeprosjekter.",
      ],
    },
    melding_tiltak_fullført: {
      tittel: "Meldingssak tiltak fullført",
      avsnitt: [
        "Et vedtak fra kommunen på at bygging anses lovlig utført og klart for bruk. Statusen er utgått og brukes ikke lenger i nye byggeprosjekter.",
      ],
    },
    fritatt_søknadsplikt: {
      tittel: "Fritatt for søknadsplikt",
      avsnitt: [
        "Brukes for byggeprosjekter som ikke er søknadspliktige, men som skal registreres i Eiendomsregisteret (matrikkelen).",
      ],
    },
    ikke_pliktig_registrert: {
      tittel: "Ikke pliktig registrert",
      avsnitt: [
        "Brukes for bygninger som faller utenfor de vanlige kravene til søknad eller godkjenning.",
      ],
    },
    godkjent_for_rivning_brenning: {
      tittel: "Bygning godkjent for rivning / brenning",
      avsnitt: ["En tillatelse fra kommunen til å rive eller igangsette brenning av en bygning."],
    },
  },

  "nn-NO": {
    hvorfor_ser_jeg_ikke_eiendommen: {
      tittel: "Kvifor ser eg ikkje eigendommen min?",
      avsnitt: [" ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " "],
    },
    hvorfor_flere_adresser: {
      tittel: "Kvifor ser eg fleire adresser?",
      avsnitt: [""],
    },
    hvorfor_ikke_adresse: {
      tittel: "Kvifor har eg ikkje adresse?",
      avsnitt: [""],
    },
    mer_om_adresser_link: "Les meir om adresser",
    betydning_opplysninger_mine_eiendommer_overskrift:
      "Kva betyr opplysningane som vises om mine eigendommar?",
    betydning_opplysninger_bygninger: {
      tittel: "Kva betyr opplysningane som vises om bygningene?",
      avsnitt: [" "],
    },
    kartutsnitt_bygning: {
      tittel: "Kartutsnitt med bygningen",
      avsnitt: [" "],
    },
    totalt_bruksareal: {
      tittel: "Totalt bruksareal",
      avsnitt: [" "],
    },
    bygningsnummer: {
      tittel: "Bygningsnummer",
      avsnitt: [" ", " "],
    },
    detaljert_bygningstype: {
      tittel: "Detaljert bygningstype",
      avsnitt: [" "],
    },
    betydning_opplysninger_seksjon_overskrift: "Kva betyr opplysningane som vises om seksjonen?",
    seksjonsnummer: {
      tittel: "Din seksjon (eks. H0303)",
      avsnitt: [""],
    },
    bruksareal_seksjon: {
      tittel: "Bruksareal",
      avsnitt: [""],
    },
    inngang_seksjon: {
      tittel: "Inngang",
      avsnitt: [""],
    },
    forklaring_matrikkelenhetstypene: {
      tittel: "Kva er matrikkelenheitstypane?",
      avsnitt: [""],
    },
    grunneiendom_forklaring: {
      tittel: "Grunneigendom",
      avsnitt: [""],
    },
    festegrunn_forklaring: {
      tittel: "Festegrunn",
      avsnitt: [""],
    },
    seksjon_forklaring: {
      tittel: "Eierseksjon",
      avsnitt: [""],
    },
    anleggseiendom_forklaring: {
      tittel: "Anleggseigendom",
      avsnitt: [""],
    },
    jordsameie_forklaring: {
      tittel: "Jordsameie",
      avsnitt: [""],
    },
    annen_type_forklaring: {
      tittel: "Annen type",
      avsnitt: [""],
    },
    forklaring_bygningsstatusene: {
      tittel: "Kva betyr bygningsstatusane som vises om bygningane?",
      avsnitt: ["", "", ""],
    },
    rammetillatelse: {
      tittel: "Rammetillatelse",
      avsnitt: [""],
    },
    igangsettingstillatelse: {
      tittel: "Igangsettingstillatelse",
      avsnitt: [""],
    },
    midlertidig_brukstillatelse: {
      tittel: "Midlertidig brukstillatelse",
      avsnitt: [""],
    },
    ferdigattest: {
      tittel: "Ferdigattest",
      avsnitt: [""],
    },
    tatt_i_bruk: {
      tittel: "Tatt i bruk",
      avsnitt: [""],
    },
    melding_registrer_tiltak: {
      tittel: "Meldingssak registrer tiltak",
      avsnitt: [""],
    },
    melding_tiltak_fullført: {
      tittel: "Meldingssak tiltak fullført",
      avsnitt: [""],
    },
    fritatt_søknadsplikt: {
      tittel: "Fritatt for søknadsplikt",
      avsnitt: [""],
    },
    ikke_pliktig_registrert: {
      tittel: "Ikkje pliktig registrert",
      avsnitt: [""],
    },
    godkjent_for_rivning_brenning: {
      tittel: "Bygning godkjent for rivning / brenning",
      avsnitt: [""],
    },
  },
}

export const ofteStilteSpørsmål = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
