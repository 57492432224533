import { z } from "zod"
import {
  AvløpKeysEnum,
  EnergikildeKeysEnum,
  Kildekode,
  OppvarmingKeysEnum,
  VannforsyningKeysEnum,
} from "./Enums"

export type IkonForFelt =
  | "calendar_today"
  | "square_foot"
  | "bolt"
  | "heat"
  | "water_drop"
  | "valve"
  | "help"

export function hentIkonForFelt(felt: Felt): IkonForFelt {
  switch (felt) {
    case "byggeår":
      return "calendar_today"
    case "bruksareal":
      return "square_foot"
    case "energikilder":
      return "bolt"
    case "oppvarming":
      return "heat"
    case "vannforsyning":
      return "water_drop"
    case "avløp":
      return "valve"
    default:
      return "help"
  }
}
export type Felt =
  | EgenregFelt<ByggeårSchema>
  | EgenregFelt<BruksarealSchema>
  | EgenregFelt<EnergikilderSchema>
  | EgenregFelt<OppvarmingSchema>
  | EgenregFelt<VannforsyningSchema>
  | EgenregFelt<AvløpSchema>

type EgenregFelt<T> = keyof Omit<T, "kildemateriale">

const preprocessOptionalToUndefined = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess(input => {
    if (
      input === "" ||
      Number.isNaN(input) ||
      input === false ||
      input === null ||
      (Array.isArray(input) && input.length === 0)
    ) {
      return undefined
    }
    return input
  }, schema)

const validateByggeår = z
  .number({
    message: "Vennligst oppgi et gyldig byggeår",
    required_error: "Vennligst oppgi et gyldig byggeår",
  })
  .nonnegative("Vennligst oppgi et gyldig byggeår")
  .min(1000, "Vennligst oppgi et gyldig byggeår")
  .max(new Date().getFullYear(), "Byggeår kan ikke være frem i tid")

const validateEtasjeRegistrering = z.object({
  bruksareal: z
    .number({
      required_error: "Vennligst oppgi et gyldig bruksareal for etasjen",
    })
    .nonnegative("Bruksareal kan ikke være negativt")
    .min(1, { message: "Bruksareal må være større enn 0" }),

  etasjebetegnelse: z.object({
    etasjeplanKode: z.string(),
    etasjenummer: z.number().int().positive(),
  }),
})

const validateBruksareal = z
  .object({
    totaltBruksareal: preprocessOptionalToUndefined(
      z
        .number({
          required_error: "Vennligst oppgi totalt bruksareal",
        })
        .nonnegative("Totalt bruksareal kan ikke være negativt")
        .min(1, { message: "Totalt bruksareal må være større enn 0" }),
    ),
    etasjeRegistreringer: preprocessOptionalToUndefined(
      z.array(validateEtasjeRegistrering).optional(),
    ),
  })
  .refine(
    data => data.totaltBruksareal !== undefined || (data.etasjeRegistreringer?.length ?? 0) > 0,
    {
      message: "Du må enten oppgi totalt bruksareal eller bruksareal per etasje",
      path: ["bruksareal"],
    },
  )

const kildematerialeSchema = z.object({
  kildemateriale: z.object({
    kode: z.nativeEnum(Kildekode, { message: "Vennligst velg et kildemateriale" }),
  }),
})

export const byggeårSchema = z
  .object({
    byggeår: validateByggeår,
  })
  .merge(kildematerialeSchema)

export const bruksarealSchema = z
  .object({
    bruksareal: validateBruksareal,
  })
  .merge(kildematerialeSchema)
  .refine(
    e => {
      if (!e.bruksareal.etasjeRegistreringer?.some(e => e.bruksareal !== undefined)) return true
      return (
        e.bruksareal.totaltBruksareal ===
        e.bruksareal.etasjeRegistreringer?.reduce((acc, curr) => acc + curr.bruksareal, 0)
      )
    },
    {
      message: "Totalt bruksareal må være lik summen av bruksarealene for alle etasjer",
      path: ["bruksareal.totaltBruksareal"],
    },
  )

export const oppvarmingSchema = z
  .object({
    oppvarming: preprocessOptionalToUndefined(
      z
        .enum(OppvarmingKeysEnum, {
          required_error: "Vennligst oppgi minst én oppvarmingstype",
        })
        .array(),
    ),
  })
  .merge(kildematerialeSchema)

export const energikilderSchema = z
  .object({
    energikilder: preprocessOptionalToUndefined(
      z
        .enum(EnergikildeKeysEnum, {
          required_error: "Vennligst oppgi minst én energkilde",
        })
        .array(),
    ),
  })
  .merge(kildematerialeSchema)

export const vannforsyningSchema = z
  .object({
    vannforsyning: preprocessOptionalToUndefined(
      z.enum(VannforsyningKeysEnum, {
        required_error: "Vennligst oppgi bygningens vannforsyning",
      }),
    ),
  })
  .merge(kildematerialeSchema)

export const avløpSchema = z
  .object({
    avløp: preprocessOptionalToUndefined(
      z.enum(AvløpKeysEnum, {
        required_error: "Vennligst oppgi bygningens avløp",
      }),
    ),
  })
  .merge(kildematerialeSchema)

export type ByggeårSchema = z.infer<typeof byggeårSchema>
export type BruksarealSchema = z.infer<typeof bruksarealSchema>
export type EnergikilderSchema = z.infer<typeof energikilderSchema>
export type OppvarmingSchema = z.infer<typeof oppvarmingSchema>
export type VannforsyningSchema = z.infer<typeof vannforsyningSchema>
export type AvløpSchema = z.infer<typeof avløpSchema>
