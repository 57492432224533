import { Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import VerdiMedForklaring from "../../components/VerdiMedForklaring"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"

const AntallTeigerDetalj = ({ antallTeiger }: { antallTeiger: number | undefined }) => {
  const { t } = useTranslation("detaljertInnsyn")

  return antallTeiger ? (
    <VerdiMedForklaring
      label={t("antall_teiger_overskrift")}
      posthogName="antall_teiger_info_clicked"
      forklaring={t("antall_teiger_forklaring") as string}
    >
      <FeltverdiForEiendomsdetaljer
        fieldValue={t("antall_teiger", { count: antallTeiger }) as string}
      />
    </VerdiMedForklaring>
  ) : (
    <Skeleton w="100%" h="24px" />
  )
}

export default AntallTeigerDetalj
