import { t } from "i18next"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { Arealmerknadskode } from "../bygningsdetaljer/utils/arealmerknadUtil"
import Arealmerknad from "./Arealmerknad"
import TeigMedFlereMatrikkelenheterDetalj from "./TeigMedFlereMatrikkelenheterDetalj"
import { Skeleton } from "@kvib/react"

const Merknader = ({
  teigdetaljer,
  arealmerknader,
}: {
  teigdetaljer: boolean | undefined
  arealmerknader: Arealmerknadskode[] | undefined
}) => {
  if (!teigdetaljer && !arealmerknader) return <Skeleton w="100%" />
  return (
    <>
      <Overskrift color="gray.600" variant="h4" mb="8px">
        {t("teig_tittel", { ns: "detaljertInnsyn" })}
      </Overskrift>
      {arealmerknader && arealmerknader.length > 0 ? (
        <Arealmerknad arealmerknader={arealmerknader} />
      ) : null}
      {teigdetaljer ? (
        <TeigMedFlereMatrikkelenheterDetalj key="teigMedFlereMatrikkelenheter" />
      ) : null}
    </>
  )
}

export default Merknader
