import { Box, Flex } from "@kvib/react"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { EgenregistreringBruksenhet } from "../types/EgenregistreringBygning"
import { Felt } from "../types/schema"
import { hentBruksarealForEtasjeregistrering, hentEgenregistrertVerdi } from "../utils"
import EgenregistreringDialog from "./modaler/EgenregistreringDialog"
import Registreringsinfo from "./Registreringsinfo"

const EgenregistrertVerdi = ({
  egenregistreringForBruksenhet,
  felt,
}: {
  egenregistreringForBruksenhet: EgenregistreringBruksenhet
  felt: Felt
}) => {
  const egenregistrertVerdi = hentEgenregistrertVerdi(felt, egenregistreringForBruksenhet)

  return (
    <Flex p="12px" bg="gray.100" justifyContent="space-between" gap="5" w="500px">
      <Flex flexDir="column">
        <Brødtekst fontWeight="bold">{egenregistrertVerdi}</Brødtekst>
        {felt === "bruksareal" &&
          egenregistreringForBruksenhet.bruksareal?.data?.etasjeRegistrertBruksareal &&
          egenregistreringForBruksenhet.bruksareal?.data?.etasjeRegistrertBruksareal.length > 0 && (
            <Box>
              {egenregistreringForBruksenhet.bruksareal.data.etasjeRegistrertBruksareal.map(
                registrering => (
                  <Brødtekst
                    key={
                      registrering.bruksareal +
                      registrering.etasjebetegnelse.etasjenummer +
                      registrering.etasjebetegnelse.etasjeplanKode
                    }
                  >
                    {hentBruksarealForEtasjeregistrering(registrering)}
                  </Brødtekst>
                ),
              )}
            </Box>
          )}
        <Registreringsinfo feltKey={felt} />
      </Flex>

      <EgenregistreringDialog
        felt={felt}
        egenregistreringForBruksenhet={egenregistreringForBruksenhet}
      />
    </Flex>
  )
}

export default EgenregistrertVerdi
