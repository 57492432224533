import { zodResolver } from "@hookform/resolvers/zod"
import { Field, Spinner } from "@kvib/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useKodelisterBygning } from "../../kodelister/useKodelisterBygning"
import useEgenregistreringBruksenhet from "../api/useEgenregistreringBruksenhet"
import { Vannforsyningstype } from "../types/Enums"
import { VannforsyningSchema, vannforsyningSchema } from "../types/schema"
import HjelpetekstForFelt from "./skjemakomponenter/HjelpetekstForFelt"
import { InvalidInputAlert } from "./skjemakomponenter/InvalidInputAlert"
import Radioknapper from "./skjemakomponenter/Radioknapper"

const RegistrerVannforsyning = ({
  handleSubmit,
}: {
  handleSubmit: (data: VannforsyningSchema) => void
}) => {
  const { t } = useTranslation("egenregistrering")
  const { data: egenregistreringFraBruksenhet } = useEgenregistreringBruksenhet()

  const form = useForm<VannforsyningSchema>({
    resolver: zodResolver(vannforsyningSchema),
    defaultValues: {
      vannforsyning: egenregistreringFraBruksenhet?.vannforsyning?.data,
      kildemateriale: {
        kode: egenregistreringFraBruksenhet?.vannforsyning?.metadata.kildemateriale,
      },
    },
  })
  const { data: kodelister, isPending, isSuccess } = useKodelisterBygning()

  if (isPending || !isSuccess) return <Spinner />

  const onSubmit = form.handleSubmit(data => {
    handleSubmit(data)
  })

  const valgmuligheter = Object.entries(Vannforsyningstype).map(([key, label]) => ({
    kode: key,
    presentasjonsnavn: label,
  }))

  return (
    <form id="vannforsyning-form" onSubmit={onSubmit}>
      <Field>
        <HjelpetekstForFelt>{t("hjelpetekst_vannforsyning")}</HjelpetekstForFelt>
        <Radioknapper<VannforsyningSchema>
          name="vannforsyning"
          label={t("registrer_felt_for_bruksenheten.vannforsyning", { ns: "skjemaoverskrifter" })}
          control={form.control}
          valgmuligheter={valgmuligheter}
        />
        <InvalidInputAlert error={form.formState.errors.vannforsyning} />
        <Radioknapper<VannforsyningSchema>
          name="kildemateriale.kode"
          label="Kilde"
          beskrivelse="Velg hvilken kilde du har brukt for å komme frem til vannforsyning."
          control={form.control}
          valgmuligheter={kodelister}
          highlightValgtVerdi={true}
        />
      </Field>
    </form>
  )
}

export default RegistrerVannforsyning
