import { format } from "date-fns"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { useMetadata } from "../hooks/useMetadata"
import { Felt } from "../types/schema"
import { useKodelisterBygning } from "../../kodelister/useKodelisterBygning"

const Registreringsinfo = ({ feltKey }: { feltKey: Felt }) => {
  const { data: kodelister } = useKodelisterBygning()

  const metadata = useMetadata(feltKey)
  if (!metadata) return null

  const registrertAv = (metadata: { registrertAvDeg: boolean }) => {
    return metadata.registrertAvDeg ? "deg" : "annen eier"
  }

  const registreringstidspunkt = (metadata: { registreringstidspunkt: string }) => {
    return metadata.registreringstidspunkt
      ? format(new Date(metadata.registreringstidspunkt), "dd.MM.yyyy")
      : ""
  }

  const registreringstidspunktFormatted = registreringstidspunkt(metadata)

  return (
    registreringstidspunktFormatted && (
      <>
        <Brødtekst litenVariant as="i" mt="12px" mb="12px">
          {`Registrert av ${registrertAv(metadata)} ${registreringstidspunktFormatted}`}
        </Brødtekst>
        {metadata.kildemateriale && kodelister && (
          <>
            <Brødtekst>
              <b>Kilde</b>
            </Brødtekst>
            <Brødtekst litenVariant>
              {kodelister.find(k => k.kode === metadata.kildemateriale)?.presentasjonsnavn}
            </Brødtekst>
          </>
        )}
      </>
    )
  )
}

export default Registreringsinfo
