import { Box, FormLabel, Radio, RadioGroup, Stack } from "@kvib/react"
import { Control, Controller, FieldValues, Path } from "react-hook-form"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../../core/text/components/Overskrift"

const Radioknapper = <T extends FieldValues>({
  label,
  beskrivelse,
  control,
  name,
  valgmuligheter,
  highlightValgtVerdi = false,
}: {
  label: string
  beskrivelse?: string
  control: Control<T>
  name: Path<T>
  valgmuligheter: Valgmulighet[]
  highlightValgtVerdi?: boolean
}) => {
  return (
    <Box mt="32px">
      <FormLabel>
        <Overskrift variant="h4">{label}</Overskrift>
      </FormLabel>
      {beskrivelse && <Brødtekst>{beskrivelse}</Brødtekst>}

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup
            size="md"
            value={field.value}
            onChange={(value: unknown) => field.onChange(value)} // TODO - Bytte moduleResolution til "bundler" så man får intellisense på chakra props
          >
            <Stack mt="12px" mb="12px" gap="0">
              {valgmuligheter.map(valgmulighet => (
                <Box
                  key={valgmulighet.kode}
                  p="6px"
                  borderRadius="md"
                  bg={
                    highlightValgtVerdi && field.value === valgmulighet.kode ? "green.50" : "white"
                  }
                  borderColor={
                    highlightValgtVerdi && field.value === valgmulighet.kode
                      ? "green.400"
                      : "gray.200"
                  }
                >
                  <Radio gap="8px" value={valgmulighet.kode} onBlur={field.onBlur}>
                    {valgmulighet.beskrivelse ? (
                      <>
                        <Brødtekst as="b">{valgmulighet.presentasjonsnavn}</Brødtekst>
                        <Brødtekst>{valgmulighet.beskrivelse}</Brødtekst>
                      </>
                    ) : (
                      <Brødtekst>{valgmulighet.presentasjonsnavn}</Brødtekst>
                    )}
                  </Radio>
                </Box>
              ))}
            </Stack>
          </RadioGroup>
        )}
      />
    </Box>
  )
}

type Valgmulighet = {
  kode: string
  presentasjonsnavn: string
  beskrivelse?: string
}

export default Radioknapper
