const keys = {
  "nb-NO": {
    tab_tittel: "Om tjenesten",
    overskrift: "Om tjenesten",
    oversikt_over_dine_eiendommer: {
      tittel: "",
      avsnitt: [""],
    },
    informasjon_om_eiendommen: {
      tittel: "Informasjon om eiendommen",
      avsnitt: ["For hver eiendom vil du se følgende informasjon:"],
    },
  },
  "nn-NO": {
    tab_tittel: "Om tenesta",
    overskrift: "Om tenesta",
    oversikt_over_dine_eiendommer: {
      tittel: "Oversikt over dine eigedommar",
      avsnitt: [
        "Min eiendom er ei teneste frå Kartverket der du kan finne registrerte opplysingar om dine eigedommar. Eigedommane som blir viste i løysinga er dei du er registrert som eigar eller festar av i Eigedomsregisteret (matrikkelen). Burettslagandelar og aksjeleilegheiter blir ikkje vist i dagens løysing. Tenesta er under utvikling og utvalet kan bli endra.",
      ],
    },
    informasjon_om_eiendommen: {
      tittel: "Informasjon om eigedommane",
      avsnitt: [
        "Du får sjå viktige opplysingar om eigedommen, om bygningane som er registrerte på den, og om brukseiningane du eig.",
        "Det er ikkje all informasjon fra Eigedomsregisteret (matrikkelen) som finst på Min eiendom. For å sjå meir av informasjonen som ligg i Eigedomsregisteret (matrikkelen) kan du søke opp eigendommen din på seeiendom.kartverket.no, eller ta kontakt med kommunen din for å bestille matrikkelbrev.",
      ],
    },
  },
}

export const omTjenesten = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
