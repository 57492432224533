import { useTranslation } from "react-i18next"
import VerdiMedForklaring from "../../components/VerdiMedForklaring"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"

const TeigMedFlereMatrikkelenheterDetalj = () => {
  const { t } = useTranslation("detaljertInnsyn")

  return (
    <VerdiMedForklaring
      label={t("teig_med_flere_matrikkelenheter_overskrift")}
      posthogName="teigdetaljer_info_clicked"
      forklaring={t("teig_med_flere_matrikkelenheter_forklaring") as string}
    >
      <FeltverdiForEiendomsdetaljer
        fieldValue={t("teig_med_flere_matrikkelenheter_tekst") as string}
      />
    </VerdiMedForklaring>
  )
}

export default TeigMedFlereMatrikkelenheterDetalj
