import { zodResolver } from "@hookform/resolvers/zod"
import { Field, FormLabel, Spinner } from "@kvib/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useKodelisterBygning } from "../../kodelister/useKodelisterBygning"
import useEgenregistreringBruksenhet from "../api/useEgenregistreringBruksenhet"
import { ByggeårSchema, byggeårSchema } from "../types/schema"
import HjelpetekstForFelt from "./skjemakomponenter/HjelpetekstForFelt"
import InputfeltTall from "./skjemakomponenter/InputfeltTall"
import { InvalidInputAlert } from "./skjemakomponenter/InvalidInputAlert"
import Radioknapper from "./skjemakomponenter/Radioknapper"
import { Overskrift } from "../../../core/text/components/Overskrift"

const RegistrerByggeår = ({ handleSubmit }: { handleSubmit: (data: ByggeårSchema) => void }) => {
  const { t } = useTranslation("egenregistrering")
  const { data: egenregistreringFraBruksenhet } = useEgenregistreringBruksenhet()
  const byggeårForm = useForm<ByggeårSchema>({
    resolver: zodResolver(byggeårSchema),
    defaultValues: {
      byggeår: egenregistreringFraBruksenhet?.byggeår?.data,
      kildemateriale: {
        kode: egenregistreringFraBruksenhet?.byggeår?.metadata.kildemateriale,
      },
    },
  })
  const onSubmit = byggeårForm.handleSubmit(data => {
    handleSubmit(data)
  })

  const { data: kodelister, isPending, isSuccess } = useKodelisterBygning()

  if (isPending || !isSuccess) return <Spinner />

  return (
    <form id="byggeår-form" onSubmit={onSubmit}>
      <Field>
        <HjelpetekstForFelt>
          {t("hjelpetekst_byggeår", { ns: "egenregistrering" })}
        </HjelpetekstForFelt>
        <FormLabel>
          <Overskrift variant="h4">
            {t("registrer_felt_for_bruksenheten.byggeår", { ns: "skjemaoverskrifter" })}
          </Overskrift>
        </FormLabel>

        <InputfeltTall register={byggeårForm.register("byggeår", { valueAsNumber: true })} />
        <InvalidInputAlert error={byggeårForm.formState.errors.byggeår} />
        <Radioknapper<ByggeårSchema>
          label="Kilde"
          beskrivelse="Velg hvilken kilde du har brukt for å komme frem til byggeår."
          control={byggeårForm.control}
          name="kildemateriale.kode"
          valgmuligheter={kodelister}
          highlightValgtVerdi={true}
        />
        <InvalidInputAlert error={byggeårForm.formState.errors.kildemateriale?.kode} />
      </Field>
    </form>
  )
}

export default RegistrerByggeår
