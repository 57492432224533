export enum Vannforsyningstype {
  OffentligVannverk = "Offentlig vannverk",
  TilknyttetPrivatVannverk = "Tilknyttet privat vannverk",
  AnnenPrivatInnlagtVann = "Annen privat innlagt vann",
  AnnenPrivatIkkeInnlagtVann = "Annen privat ikke innlagt vann",
}

export enum Avløpstype {
  OffentligKloakk = "Offentlig kloakk",
  PrivatKloakk = "Privat kloakk",
  IngenKloakk = "Ingen kloakk",
}

export const Energikildetype = {
  Biobrensel: "Biobrensel",
  Elektrisitet: "Elektrisitet",
  Fjernvarme: "Fjernvarme",
  Gass: "Gass",
  OljeParafin: "Flytende brensel",
  Solenergi: "Solenergi",
  Varmepumpe: "Varmepumpe",
  AnnenEnergikilde: "Annen energikilde",
} as const

export const Oppvarmingstype = {
  Elektrisk: "Elektrisk",
  Sentralvarme: "Sentralvarme",
  AnnenOppvarming: "Annen oppvarming",
} as const

export const VannforsyningKeysEnum = Object.keys(Vannforsyningstype) as [
  keyof typeof Vannforsyningstype,
]

export enum Kildekode {
  Selvrapportert = "Selvrapportert",
  Salgsoppgave = "Salgsoppgave",
  Byggesaksdokumenter = "Byggesaksdokumenter",
  AnnenDokumentasjon = "AnnenDokumentasjon",
}

export type Kildemateriale = {
  kode: Kildekode
  presentasjonsnavn: string
  beskrivelse: string
}

export const AvløpKeysEnum = Object.keys(Avløpstype) as [keyof typeof Avløpstype]
export const OppvarmingKeysEnum = Object.keys(Oppvarmingstype) as [keyof typeof Oppvarmingstype]
export const EnergikildeKeysEnum = Object.keys(Energikildetype) as [keyof typeof Energikildetype]
