import { Alert, AlertDescription } from "@kvib/react"
import { FieldError } from "react-hook-form"

export const InvalidInputAlert = ({ error }: { error: FieldError | undefined }) => {
  return (
    error && (
      <Alert mb="12px" mt="12px" status="error">
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    )
  )
}
