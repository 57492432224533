import {
  Button,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTrigger,
  Spinner,
} from "@kvib/react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { formaterStorForbokstav } from "../../../../../utils/formateringUtils"
import { useScreenView } from "../../../../core/hooks/useScreenview"
import useOpprettEgenregistrering from "../../api/useOpprettEgenregistering"
import useErTomVerdi from "../../hooks/useErTomVerdi"
import { EgenregistreringBruksenhet } from "../../types/EgenregistreringBygning"
import {
  AvløpSchema,
  BruksarealSchema,
  ByggeårSchema,
  EnergikilderSchema,
  Felt,
  OppvarmingSchema,
  VannforsyningSchema,
  hentIkonForFelt,
} from "../../types/schema"
import { hentEgenregistrertVerdi, ikkeRegistrertVerdi } from "../../utils"
import { EgenregistreringKnapp } from "../EgenregistreringKnapp"
import { IkonOgFeltLabel } from "../IkonOgFeltLabel"
import RegistrerAvløp from "../RegistrerAvløp"
import RegistrerByggeår from "../RegistrerByggeår"
import RegistrerEnergikilder from "../RegistrerEnergikilder"
import RegistrerOppvarming from "../RegistrerOppvarming"
import RegistrerVannforsyning from "../RegistrerVannforsyning"
import RegistrerBruksareal from "../skjemakomponenter/bruksareal/RegistrerBruksareal"

interface EgenregistreringModalProps {
  felt: Felt
  egenregistreringForBruksenhet: EgenregistreringBruksenhet
}

const EgenregistreringDialog = ({
  felt,
  egenregistreringForBruksenhet,
}: EgenregistreringModalProps) => {
  const { mutate: sendEgenregistrering, isPending } = useOpprettEgenregistrering()
  const { bygningId, bruksenhetId } = useParams()
  const { isDesktop } = useScreenView()
  const { t } = useTranslation(["egenregistrering", "nøkkelbegreper"])
  const [isOpen, setIsOpen] = useState(false)

  const handleSubmit = (
    data:
      | ByggeårSchema
      | EnergikilderSchema
      | BruksarealSchema
      | OppvarmingSchema
      | AvløpSchema
      | VannforsyningSchema,
  ) => {
    sendEgenregistrering(
      {
        bygningId: bygningId!,
        bruksenhetId: bruksenhetId!,
        key: felt,
        egenregistrering: data,
      },
      {
        onSuccess: () => setIsOpen(false),
      },
    )
  }

  const getFieldContent = (field: Felt) => {
    switch (field) {
      case "byggeår":
        return <RegistrerByggeår handleSubmit={handleSubmit} />
      case "bruksareal":
        return <RegistrerBruksareal handleSubmit={handleSubmit} />
      case "energikilder":
        return <RegistrerEnergikilder handleSubmit={handleSubmit} />
      case "oppvarming":
        return <RegistrerOppvarming handleSubmit={handleSubmit} />
      case "vannforsyning":
        return <RegistrerVannforsyning handleSubmit={handleSubmit} />
      case "avløp":
        return <RegistrerAvløp handleSubmit={handleSubmit} />
      default:
        return null
    }
  }

  const isTomVerdi = useErTomVerdi()
  const tekstForEgenregistreringsknapp = (egenregistrertVerdi: string): string => {
    if (egenregistrertVerdi === ikkeRegistrertVerdi) {
      return formaterStorForbokstav(t("tekst_registrer_verdi_knapp"))
    }

    return formaterStorForbokstav(t("tekst_endre_verdi_knapp"))
  }

  const egenregistrertVerdi = hentEgenregistrertVerdi(felt, egenregistreringForBruksenhet)

  return (
    <DialogRoot
      closeOnInteractOutside={false}
      size="xl"
      open={isOpen}
      onOpenChange={(e: { open: boolean }) => setIsOpen(e.open)} // TODO - Bytt moduleResoultion til "bundler" for å plukke opp typene fra chakra-ui
    >
      <DialogBackdrop />

      <DialogTrigger asChild>
        <EgenregistreringKnapp
          data-cy={`egenregistrering-${felt}`}
          icon={isTomVerdi(felt) ? "add" : "edit"}
          ml="auto"
          onClick={() => setIsOpen(true)}
        >
          {tekstForEgenregistreringsknapp(egenregistrertVerdi as string)}
        </EgenregistreringKnapp>
      </DialogTrigger>

      <DialogContent p={isDesktop ? "16px" : "16px 0 16px 0"}>
        <DialogHeader>
          <IkonOgFeltLabel felt={felt} icon={hentIkonForFelt(felt)} />
          <DialogCloseTrigger />
        </DialogHeader>

        <DialogBody>{getFieldContent(felt)}</DialogBody>

        <DialogFooter gap={4}>
          <Button
            maxW="auto"
            children={isPending ? <Spinner /> : "Send inn"}
            type="submit"
            form={`${felt}-form`}
            colorScheme="green"
          />
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  )
}

export default EgenregistreringDialog
