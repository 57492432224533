import { Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { DetaljertEiendomResponse } from "../../eiendom/detaljert-eiendom/types/DetaljertEiendom"
import useTeigpolygoner from "../../interaktivtKart/api/useTeigpolygoner"
import { InteraktivtKart } from "../../interaktivtKart/InteraktivtKart"
import FantIkkeKartutstnittBox from "./FantIkkeKartutstnittBox"

const KartutsnittForDetaljertEiendom = ({
  isPending,
  eiendom,
}: {
  isPending: boolean
  eiendom: DetaljertEiendomResponse | undefined
}) => {
  const { t } = useTranslation("eiendomskort")

  const { data: teigpolygoner } = useTeigpolygoner(eiendom?.matrikkelnummer)

  if (isPending) {
    return <Skeleton height="400px" />
  }

  const representasjonspunkt = eiendom?.koordinat

  if (!representasjonspunkt) {
    return (
      <FantIkkeKartutstnittBox
        tekst={t("mangler_kartutsnitt_for_eiendommen_forklaring")}
        imageProps={{
          height: "100%",
          pt: "1rem",
        }}
        boxProxs={{
          bg: "green.50",
          borderRadius: "8",
        }}
      />
    )
  }

  return <InteraktivtKart teigpolygoner={teigpolygoner ?? undefined} />
}

export default KartutsnittForDetaljertEiendom
