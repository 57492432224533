import { Divider, Stack } from "@kvib/react"
import { usePostHog } from "posthog-js/react"
import { ReactNode } from "react"
import { useParams } from "react-router"
import Section from "../../../core/components/layout/Section"
import { finnerIngenPathsSomMatcher } from "../../../core/error-handling/types/finnerIngenPathSomPatcher"
import KartutsnittForDetaljertEiendom from "../../../kartutsnitt/components/KartutsnittForDetaljertEiendom"
import { isBortfestetEiendom } from "../api/eiendom"
import { useEiendom } from "../api/useEiendom"
import { AdresseOverskrift } from "./AdresseOverskrift"
import Bygningsdetaljer from "./Bygningsdetaljer"
import EierforholdDetalj from "./Eierforhold"
import FestegrunnerPåEiendomAccordion from "./FestegrunnerPåEiendomAccordion"
import FlereOpplysninger from "./FlereOpplysninger"
import { KommuneOverskrift } from "./KommuneOverskrift"
import Merknader from "./Merknader"
import NøkkelinfoOmEiendommen from "./NøkkelinfoOmEiendommen"

const StackedSection = ({ children }: { children: ReactNode }) => (
  <>
    <Divider mt="12px" mb="12px" />
    <Stack mt="20px">{children}</Stack>
  </>
)

const DetaljertEiendom = () => {
  const { data: eiendom, isPending: isEiendommerPending } = useEiendom()
  const posthog = usePostHog()
  const { eiendomsIndex } = useParams()
  const commonPaddingForSection = ["30px", "40px"]

  if (!isEiendommerPending && !eiendom) {
    throw finnerIngenPathsSomMatcher
  }

  const eiendomErBortfestet = eiendom && isBortfestetEiendom(eiendom)
  const skalViseBygningerPåEiendom =
    eiendom && !eiendomErBortfestet && eiendom.bygningerPåEiendom?.length > 0
  const skalViseFestegrunnerPåEiendom =
    eiendom && !eiendomErBortfestet && eiendom.festegrunner?.length > 0
  const skalViseTeigInfo =
    eiendom &&
    (eiendom.teigMedFlereMatrikkelenheter ||
      (eiendom.areal?.arealmerknader && eiendom.areal.arealmerknader.length > 0))

  if (!localStorage.getItem(`${eiendomsIndex}`)) {
    posthog.capture("Antall bygninger", {
      antall_bygninger: !eiendomErBortfestet && eiendom?.bygningerPåEiendom.length,
    })
    localStorage.setItem(`${eiendomsIndex}`, "true")
  }

  return (
    <>
      <Section pt="12px" pb={commonPaddingForSection}>
        <KartutsnittForDetaljertEiendom eiendom={eiendom} isPending={isEiendommerPending} />
        <Stack mt="20px">
          <KommuneOverskrift kommunenavn={eiendom?.kommunenavn} />
          <AdresseOverskrift eiendom={eiendom} />
        </Stack>
        <StackedSection>
          <EierforholdDetalj eierforhold={eiendom?.eierforhold} />
        </StackedSection>
        <StackedSection>
          <NøkkelinfoOmEiendommen eiendom={eiendom} />
        </StackedSection>
        {skalViseTeigInfo && (
          <StackedSection>
            <Merknader
              teigdetaljer={eiendom?.teigMedFlereMatrikkelenheter}
              arealmerknader={eiendom?.areal?.arealmerknader}
            />
          </StackedSection>
        )}
        {skalViseFestegrunnerPåEiendom && (
          <StackedSection>
            <FestegrunnerPåEiendomAccordion festegrunner={eiendom?.festegrunner} />
          </StackedSection>
        )}
      </Section>
      {skalViseBygningerPåEiendom && (
        <Section bg="green.50" pt={commonPaddingForSection} pb={commonPaddingForSection}>
          <Bygningsdetaljer bygningerPåEiendom={eiendom?.bygningerPåEiendom} />
        </Section>
      )}
      <Section pt={commonPaddingForSection} pb={commonPaddingForSection} bg="white">
        <FlereOpplysninger matrikkelnummer={eiendom?.matrikkelnummer} />
      </Section>
    </>
  )
}

export default DetaljertEiendom
