import { getBreakpointCondition, useKvibContext, useMediaQuery } from "@kvib/react"

type DeviceTypes = "isDesktop" | "isTablet" | "isMobile"

type ScreenTypes = {
  [Device in DeviceTypes]: boolean
}

export const useScreenView = (): ScreenTypes => {
  const system = useKvibContext()

  const isDesktop = useMediaQuery(getBreakpointCondition(system, "lg"))
  const isTablet = useMediaQuery(getBreakpointCondition(system, "mdToLg"))
  const isMobile = useMediaQuery(getBreakpointCondition(system, "mdDown"))

  return { isDesktop, isTablet, isMobile }
}
