import { zodResolver } from "@hookform/resolvers/zod"
import { Field, Spinner } from "@kvib/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useKodelisterBygning } from "../../kodelister/useKodelisterBygning"
import useEgenregistreringBruksenhet from "../api/useEgenregistreringBruksenhet"
import { Oppvarmingstype } from "../types/Enums"
import { OppvarmingSchema, oppvarmingSchema } from "../types/schema"
import Flervalg from "./skjemakomponenter/Flervalg"
import HjelpetekstForFelt from "./skjemakomponenter/HjelpetekstForFelt"
import { InvalidInputAlert } from "./skjemakomponenter/InvalidInputAlert"
import Radioknapper from "./skjemakomponenter/Radioknapper"

const RegistrerOppvarming = ({
  handleSubmit,
}: {
  handleSubmit: (data: OppvarmingSchema) => void
}) => {
  const { t } = useTranslation(["egenregistrering", "skjemaoverskrifter"])
  const { data: egenregistreringFraBruksenhet } = useEgenregistreringBruksenhet()

  const form = useForm<OppvarmingSchema>({
    resolver: zodResolver(oppvarmingSchema),
    defaultValues: {
      oppvarming: egenregistreringFraBruksenhet?.oppvarming?.map(
        oppvarmingstype => oppvarmingstype?.data,
      ),
      kildemateriale: {
        kode: egenregistreringFraBruksenhet?.oppvarming?.find(
          oppvarmingstype => oppvarmingstype?.metadata?.kildemateriale,
        )?.metadata?.kildemateriale,
      },
    },
  })

  const onSubmit = form.handleSubmit(data => {
    handleSubmit(data)
  })

  const { data: kodelister, isPending, isSuccess } = useKodelisterBygning()

  if (isPending || !isSuccess) return <Spinner />

  return (
    <form id="oppvarming-form" onSubmit={onSubmit}>
      <Field>
        <HjelpetekstForFelt>{t("hjelpetekst_oppvarmingstyper")}</HjelpetekstForFelt>
        <Flervalg
          name="oppvarming"
          options={Oppvarmingstype}
          register={form.register("oppvarming")}
        />
        <Radioknapper<OppvarmingSchema>
          label="Kilde"
          beskrivelse="Velg hvilken kilde du har brukt for å komme frem til oppvarmingstype."
          control={form.control}
          name="kildemateriale.kode"
          valgmuligheter={kodelister}
          highlightValgtVerdi={true}
        />
        <InvalidInputAlert error={undefined} />
      </Field>
    </form>
  )
}

export default RegistrerOppvarming
