import { List, ListItem, Stack } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { Brødtekst } from "../../core/text/components/Brødtekst"
const InfotekstHvorforDukkerIkkeEiendommenMinOpp = ({
  skalViseFnrSomÅrsak,
}: {
  skalViseFnrSomÅrsak?: boolean
}) => {
  const { t } = useTranslation("ofteStilteSpørsmål")
  const avsnitt = t("hvorfor_ser_jeg_ikke_eiendommen", {
    returnObjects: true,
  }).avsnitt

  return (
    <Stack>
      <Brødtekst>
        {avsnitt[0]} <br /> {avsnitt[1]}
      </Brødtekst>
      <List gap={4} mt="1em" ml="1em" listStyle="disc outside">
        {skalViseFnrSomÅrsak && (
          <ListItem>
            <Brødtekst fontWeight="bold"> {avsnitt[2]} </Brødtekst> {avsnitt[3]}
          </ListItem>
        )}
        <ListItem>
          <Brødtekst fontWeight="bold"> {avsnitt[4]} </Brødtekst> {avsnitt[5]}
        </ListItem>
        <ListItem>
          <Brødtekst fontWeight="bold"> {avsnitt[6]} </Brødtekst> {avsnitt[7]}
        </ListItem>
        <ListItem>
          <Brødtekst fontWeight="bold">{avsnitt[8]} </Brødtekst> {avsnitt[9]}
        </ListItem>
        <ListItem>
          <Brødtekst fontWeight="bold">{avsnitt[10]} </Brødtekst> {avsnitt[11]}
        </ListItem>
      </List>
    </Stack>
  )
}

export default InfotekstHvorforDukkerIkkeEiendommenMinOpp
