import { KvibProvider } from "@kvib/react"
import { PostHogProvider } from "posthog-js/react"
import { createRoot } from "react-dom/client"
import App from "./App"
import { getEnv } from "./environments"
import "./faro-config"
import "./i18next-config"
import { konverterFraMinutterTilMillisekunder } from "./utils/konverterFraMinutterTilMillisekunder"

const root = createRoot(document.getElementById("root") as HTMLDivElement)

const env = getEnv()

root.render(
  <PostHogProvider
    apiKey="phc_U4kQNaQjNbpak0bTq7oNLNMdgdzF8BfssbDUTGDnXV2"
    options={{
      api_host: "https://eu.i.posthog.com",
      autocapture: false,
      capture_pageview: false,
      session_idle_timeout_seconds: 60 * 10,
      opt_out_capturing_by_default: true,
      debug: env === "localhost" || env === "dev",

      session_recording: {
        session_idle_threshold_ms: konverterFraMinutterTilMillisekunder(3),
        maskCapturedNetworkRequestFn: req => {
          // Masker API-endepunkter som kan inneholde matrikkelenhetsId i URLen
          const regex = /\/\d+$/

          if (regex.test(req.name)) {
            req.name = req.name.replace(regex, "/*")

            return req
          }

          // Filtrer ut faro-relaterte requests, da disse er uinteressante i session recording
          if (req.name.includes("faro")) {
            return null
          }

          return req
        },
      },
    }}
  >
    <KvibProvider>
      <App />
    </KvibProvider>
  </PostHogProvider>,
)
