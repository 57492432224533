import { Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import VerdiMedForklaring from "../../components/VerdiMedForklaring"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"

const EiendomsarealDetalj = ({
  areal,
  erEierseksjon,
}: {
  areal: number | undefined
  erEierseksjon: boolean
}) => {
  const { t } = useTranslation(["detaljertInnsyn", "nøkkelbegreper"])

  const forklaringKey = erEierseksjon
    ? "eksklusivt_uteareal_forklaring"
    : "eiendomsareal_forklaring"
  const overskriftKey = erEierseksjon
    ? "eksklusivt_uteareal_overskrift"
    : "eiendomsareal_overskrift"
  const ikkeRegistrertKey = erEierseksjon ? "ikke_registrert" : "ikke_beregnet"

  const arealVerdi =
    areal !== null && areal !== undefined && areal !== 0
      ? t("areal", { ns: "nøkkelbegreper", val: areal })
      : t(ikkeRegistrertKey, { ns: "nøkkelbegreper" })

  return (
    <VerdiMedForklaring
      label={t(overskriftKey)}
      posthogName="eiendomsareal_detalj_info_clicked"
      forklaring={t(forklaringKey)}
    >
      {areal !== null && areal !== undefined ? (
        <FeltverdiForEiendomsdetaljer fieldValue={arealVerdi} />
      ) : (
        <Skeleton w="60%" h="24px" />
      )}
    </VerdiMedForklaring>
  )
}

export default EiendomsarealDetalj
