import { useQuery } from "@tanstack/react-query"
import { konverterFraMinutterTilMillisekunder } from "../../../utils/konverterFraMinutterTilMillisekunder"
import { ApiError } from "../../core/error-handling/ApiError"
import { api } from "../../shared-api/router"
import { Kildemateriale } from "../registrering/types/Enums"

export const useKodelisterBygning = () => {
  const kodelisteQuery = useQuery<Kildemateriale[], ApiError>({
    queryKey: ["kodelisterBygning"],
    staleTime: konverterFraMinutterTilMillisekunder(15),
    queryFn: api.kodelisterFraBygning.hentKodelisterBygning,
    throwOnError: err => err.type !== "UVENTET_FEIL",
  })

  return kodelisteQuery
}
