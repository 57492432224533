import {
  AccordionIcon,
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  Box,
  Spacer,
} from "@kvib/react"
import { usePostHog } from "posthog-js/react"
import { ReactNode } from "react"
import { useFontStyles } from "../../../../core/text/fontStyles"

export const AccordionItemBygningskort = ({
  children,
  accordionTittel,
  value,
}: {
  children: ReactNode
  accordionTittel: string
  value: string
}) => {
  const { getFontSize, getFontWeight } = useFontStyles()
  const borderColor = "#E3E0E0"
  const posthog = usePostHog()

  return (
    <AccordionItem
      borderBottomWidth="1px"
      borderTopWidth="0px"
      borderBottomColor={borderColor}
      borderTopColor={borderColor}
      value={value}
    >
      <h4>
        <AccordionItemTrigger textAlign="left" onClick={() => posthog.capture(accordionTittel)}>
          <Box
            wordBreak="break-word"
            fontSize={getFontSize("Brødtekst")}
            whiteSpace="normal"
            fontWeight={getFontWeight("h4")}
            color="black"
          >
            {accordionTittel}
          </Box>
          <Spacer />
          <AccordionIcon />
        </AccordionItemTrigger>
      </h4>
      <AccordionItemContent p="1" pb="1rem">
        {children}
      </AccordionItemContent>
    </AccordionItem>
  )
}
