import { useTranslation } from "react-i18next"
import { useScreenView } from "../../core/hooks/useScreenview"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { getMatrikkelenhetstype } from "../eiendomsoversikt/utils/eiendomUtils"
import VerdiMedForklaring from "./VerdiMedForklaring"

const MatrikkeltypePopover = ({ matrikkelenhetstype }: { matrikkelenhetstype: string }) => {
  const { t } = useTranslation(["nøkkelbegreper"])
  const { isDesktop } = useScreenView()
  const matrikkelenhetstypeAriaLabel = getMatrikkelenhetstype(matrikkelenhetstype)

  return (
    <VerdiMedForklaring
      label={t("type_eiendom")}
      forklaring={t("matrikkelenhetstype_forklaring_begrep")}
      posthogName="matrikkelenhetstype_info_click"
    >
      <Brødtekst
        color="gray.500"
        aria-labelledby={matrikkelenhetstypeAriaLabel}
        lineHeight="24px"
        fontSize={isDesktop ? "m" : "sm"}
        fontWeight="bold"
      >
        {getMatrikkelenhetstype(matrikkelenhetstype)}
      </Brødtekst>
    </VerdiMedForklaring>
  )
}

export default MatrikkeltypePopover
