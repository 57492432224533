import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@kvib/react"
import { Brødtekst } from "../../core/text/components/Brødtekst"

import { usePostHog } from "posthog-js/react"
import { useState } from "react"
import { Trans } from "react-i18next"
import { useLocation } from "react-router"
import { EksternLenke } from "../../core/text/components/EksternLenke"
import { TittelAvsnitt } from "../../localization/translations"
import { NøstetAccordion } from "./NøstetAccordion"

interface ForklaringAccordionProps<T> {
  translations: T[] | string
}

const ForklaringAccordion = <
  T extends {
    tittel: string
    avsnitt: string[]
    lenker?: { lenke: string; tekst: string }[]
    statuser?: TittelAvsnitt[]
    matrikkelenhetstyper?: TittelAvsnitt[]
    arealmerknader?: TittelAvsnitt[]
  },
>(
  props: ForklaringAccordionProps<T>,
) => {
  const { translations: datafelter_accordion } = props

  const posthog = usePostHog()

  // TODO: Dette er litt snodig, og jeg foreslår å flytte infoen vi lenker til ut av accordionet
  const { hash } = useLocation()
  const [defaultItem, setDefaultItem] = useState<string[] | undefined>(
    hash ? ["En eller flere eiendommer mangler"] : undefined,
  )

  return (
    <Accordion
      collapsible
      multiple
      borderTopColor="green.500"
      borderBottomColor="green.500"
      value={defaultItem}
      onClick={() => setDefaultItem(undefined)}
    >
      {Array.isArray(datafelter_accordion) &&
        datafelter_accordion.map((datafelt, index) => (
          <AccordionItem key={datafelt.tittel} id={index.toString()} value={datafelt.tittel}>
            <AccordionButton color="green.500" onClick={() => posthog.capture(datafelt.tittel)}>
              <Box flex="1" textAlign="left">
                {datafelt.tittel}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {datafelt.avsnitt.map(avsnitt => (
                <Trans key={avsnitt}>
                  <Brødtekst>{avsnitt}</Brødtekst>
                  <br />
                </Trans>
              ))}

              {datafelt.lenker?.map(lenke => (
                <Box key={lenke.tekst}>
                  <EksternLenke m="10px" tilAdresse={lenke.lenke}>
                    {lenke.tekst}
                  </EksternLenke>
                  <br />
                </Box>
              ))}

              {datafelt.statuser && (
                <NøstetAccordion
                  items={datafelt.statuser.map(status => ({
                    tittel: status.tittel,
                    avsnitt: status.avsnitt,
                  }))}
                />
              )}

              {datafelt.matrikkelenhetstyper && (
                <NøstetAccordion
                  items={datafelt.matrikkelenhetstyper.map(matrikkelenhetstype => ({
                    tittel: matrikkelenhetstype.tittel,
                    avsnitt: matrikkelenhetstype.avsnitt,
                  }))}
                />
              )}

              {datafelt.arealmerknader && (
                <NøstetAccordion
                  items={datafelt.arealmerknader.map(arealmerknad => ({
                    tittel: arealmerknad.tittel,
                    avsnitt: arealmerknad.avsnitt,
                  }))}
                />
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  )
}

export default ForklaringAccordion
