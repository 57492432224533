import { Card, CardBody, CardHeader, CardProps } from "@kvib/react"
import { ReactNode } from "react"
import { Overskrift } from "../../core/text/components/Overskrift"

interface InfoCardProps extends CardProps {
  children: ReactNode
  cardheader: string
}
export const InfoCard = (props: InfoCardProps) => {
  const { children, cardheader } = props
  return (
    <Card p="18px" {...props}>
      <CardHeader pb="0">
        <Overskrift variant="h2">{cardheader}</Overskrift>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  )
}
