import { Link, LinkProps } from "@kvib/react"
import { usePostHog } from "posthog-js/react"
import { useFontStyles } from "../fontStyles"

interface LenkeProps extends LinkProps {
  children: string
  tilAdresse: string
  preventTracking?: boolean
}

export const EksternLenke = ({
  children,
  tilAdresse,
  preventTracking = false,
  ...props
}: LenkeProps) => {
  const { getFontSize, getFontWeight } = useFontStyles()
  const posthog = usePostHog()

  const fontSize = getFontSize("Lenke")
  const fontWeight = getFontWeight("Lenke")

  return (
    <Link
      external
      target="_blank"
      href={tilAdresse}
      onClick={() =>
        !preventTracking && posthog.capture("Ekstern lenke", { link_name: children, tilAdresse })
      }
      fontSize={fontSize}
      fontWeight={fontWeight}
      {...props}
    >
      {children}
    </Link>
  )
}
