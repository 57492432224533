import { Button, Container, Flex, Heading, Image } from "@kvib/react"
import { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import eiendom from "../../../../assets/eiendom.svg"
import { useHandleLogin } from "../../../auth/hooks/useHandleLogin"
import { useScreenView } from "../../hooks/useScreenview"
import { Brødtekst } from "../../text/components/Brødtekst"

export const Landingsside = (): ReactElement => {
  const handleLogin = useHandleLogin()
  const { isMobile } = useScreenView()
  const { t } = useTranslation("landingsside")

  const Hus = () => <Image src={eiendom} aria-hidden="true" h="100%" w="auto" objectFit="cover" />

  return (
    <Container
      bg="green.100"
      minH="calc(100vh - 188px)"
      pos="relative"
      p={{ base: "0 24px", sm: "0 40px", md: "0 80px", lg: "0 120px", xl: "0 160px" }}
      pt={{ base: "48px", sm: "64px", md: "72px", lg: "96px", xl: "144px" }}
      display="flex"
      justifyContent={{ base: "flex-start", xl: "center" }}
      minW={{ base: "300px", sm: "100%" }}
    >
      <Flex flexDir="column" gap={{ base: "16px", md: "24px", lg: "48px" }}>
        <Flex alignItems="flex-end" gap="14px">
          <Image src={eiendom} aria-hidden="true" h={{ base: "36px", md: "46px" }} />
          <Heading
            as="h1"
            fontSize={isMobile ? "3xl" : "5xl"}
            color="green.500"
            fontWeight="bold"
            lineHeight={{ base: "22px", md: "36px" }}
          >
            {t("tittel")}
          </Heading>
        </Flex>
        <Flex
          flexDir={{ base: "column", md: "row" }}
          flexWrap="wrap"
          gap={{ base: "24px", lg: "40px" }}
          justifyContent="space-between"
        >
          <Brødtekst
            fontSize={{ base: "16px", lg: "18px" }}
            maxW={{ base: "100%", sm: "500px", md: "548px" }}
          >
            {t("beskrivelse")}
          </Brødtekst>
          <Button
            iconAriaIsHidden
            _hover={{ bg: "green.400", color: "white" }}
            data-cy="logg-inn-knapp"
            onClick={handleLogin}
            variant="secondary"
            rightIcon="chevron_right"
            bg="{colors.whiteAlpha.900}"
            zIndex="1"
            border="none"
            color="{colors.gray.800}"
            w="fit-content"
            size={{ base: "md", md: "lg" }}
            p={{ base: "22px", md: "32px", lg: "40px" }}
          >
            {isMobile ? t("loggInnKnappMobil") : t("loggInnKnapp")}
          </Button>
        </Flex>
        <Flex
          opacity=".1"
          pos="absolute"
          bottom="0"
          right="0"
          width={{ base: "100%", lg: "60%" }}
          height="40%"
          maxH="380px"
          overflow="hidden"
        >
          <Flex h="100%">
            <Hus />
            <Hus />
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}
