import { Button } from "@kvib/react"
import { useScreenView } from "../../core/hooks/useScreenview"
import { Koordinat } from "../../eiendom/detaljert-eiendom/bygningsdetaljer/types/Bygning"
import { hentLenkeTilNorgeskart } from "./utils"

export const NorgeskartKnapp = ({
  koordinat,
  children,
}: {
  koordinat: Koordinat
  children: string
}) => {
  const { isMobile } = useScreenView()
  const handleNavigateToNorgeskart = () => {
    window.open(`${hentLenkeTilNorgeskart(koordinat)}`, "_blank")
  }

  return (
    <Button
      className="ph-no-capture"
      zIndex={1}
      rightIcon="open_in_new"
      variant="primary"
      onClick={handleNavigateToNorgeskart}
      colorPalette="blue"
      position="absolute"
      iconAriaIsHidden
      right={2}
      bottom={2}
      size={isMobile ? "sm" : "md"}
    >
      {children}
    </Button>
  )
}
