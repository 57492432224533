const keys = {
  "nb-NO": {
    egenregistrering_sidetittel: "Egenregistrerte opplysninger",
    info_om_registrering: {
      tittel: "Informasjon om registrering på din eiendom",
      avsnitt: [
        "Du skal registrere på din bruksenhet. Det kan være hele eller deler av en bygning. Din registrering vil gjelde for det du eier.",
        "Registrer opplysninger der det mangler eller er feil. Hvis det allerede er registrert opplysninger i Eiendomsregisteret (matrikkelen), ber vi deg deg sjekke om disse stemmer. Dersom opplysningene i Eiendomsregisteret stemmer trenger du ikke registrere.",
      ],
    },
    matrikkelverdi_undertittel: "Fra Eiendomsregisteret (matrikkelen)",
    hjelpetekst_avløp:
      "Avløpskoden beskriver systemet for håndtering av brukt vann fra bruksenheten. Det gjelder både hvordan vann fra vask og dusj, og vann fra toalett, blir håndtert.",
    hjelpetekst_bruksareal:
      "Fyll inn totalt bruksareal tilknyttet din bruksenhet. Bruksareal inkluderer alt areal innenfor ytterveggene. Dersom du har innglasset balkong inngår dette i bruksarealet. Separat bod (feks. loft- og kjellerbod) skal ikke registreres her.",
    vil_registrere_areal_per_etasje_tittel: "Fordel det totale arealet over flere etasjer",
    bygningen_mangler_registrerte_etasjer: "Bygningen har ingen registrerte etasjer",
    vil_registrere_bruksareal_per_etasje_hjelpetekst:
      "Dersom du har bruksareal i flere etasjer og er kjent med fordelingen per etasje, kan du registrere det her. ",
    legg_til_etasjer_accordion_tittel: "Legg til bruksareal i andre etasjer i bygningen",
    registrerte_etasjer_overskrift: "Huk av de etasjene i bygget hvor du vil fylle inn bruksareal",
    hjelpetekst_bruksareal_ingen_registrerte_etasjer:
      "Bruksenheten din har ingen registrert etasje",
    overskrift_fyll_inn_areal_per_etasje: "Registrer bruksareal per etasje i bygningen",
    hjelpetekst_byggeår:
      "Byggeåret er det året bruksenheten var ferdigstilt og kunne tas i bruk første gang. Rehabilitering, oppussing og påbygning i ettertid endrer ikke byggeåret.",
    hjelpetekst_energikilder:
      "Dette er den eller de kildene som gir energi til å varme opp bruksenheten.",
    hjelpetekst_oppvarmingstyper:
      "Dette er hovedmåten varme leveres på i bruksenheten. Velg den eller de som gir mest varme i bruksenheten hele året.",
    hjelpetekst_flervalg: {
      AnnenOppvarming: " (f.eks. peis, kamin, ovn)",
      Sentralvarme: " (f.eks. varme fra fjernvarmeanlegg, vannbåren varme)",
      Elektrisk: " (f.eks. panelovn, gulvvarme, luft-luft varmepumpe)",
      OljeParafin: " (f.eks. olje, parafin)",
      Biobrensel: " (f.eks. ved, pellets)",
    },
    omtvistet:
      "En eller flere av grensene til teigen er omtvistet, eller teigen er registrert som omtvistet. Arealet vil være usikkert inntil den eller de uavklarte grensene er avklart.",
    noen_fiktive_hjelpelinjer:
      "En eller flere (men ikke alle) av grensene til teigen er av typen fiktiv hjelpelinje. Arealet som beregnes vil derfor være usikkert.",
    hjelpelinje_vannkant:
      "En eller flere av grensene til teigen er av type vannkant. Arealet som beregnes vil derfor være usikkert.",
    hjelpelinje_vegkant:
      "En eller flere av grensene til teigen er av type vegkant. Arealet som beregnes vil derfor være usikkert.",
    har_punktfester:
      "En eller flere (men ikke alle) av grensene til teigen er av type punktfeste fordi det er registrert punktfester på eiendommen. Arealet til punktfestene beregnes inn i arealet til eiendommen og eiendommens areal vil derfor være usikkert.",
    ukjent_arealmerknad: "Ukjent arealmerknad. ",
    hjelpetekst_vannforsyning:
      "Vannforsyning beskriver om og hvordan bruksenheten får tilgang til rent vann. Dersom du henter vann fra bekk eller brønn uten tilkobling til bruksenheten benyttes 'annen privat men ikke innlagt vann'.",
    tekst_registrer_verdi_knapp: "registrer",
    tekst_endre_verdi_knapp: "endre",
    tekst_fjern_verdi_knapp: "fjern",
    eierseksjon_tittel: "Du skal kun registrere på din bruksenhet",
  },
  "nn-NO": {
    info_om_registrering: {
      tittel: "",
      avsnitt: ["", ""],
    },
    matrikkelverdi_undertittel: "",
    hjelpetekst_avløp: "",
    hjelpetekst_bruksareal: "",
    hjelpetekst_byggeår: "",
    hjelpetekst_energikilder: "",
    hjelpetekst_oppvarmingstyper: "",
  },
} as const

export const egenregistrering = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
