import {
  Flex,
  HStack,
  InputGroup,
  InputProps,
  NumberInputField,
  NumberInputRoot,
  Stack,
} from "@kvib/react"
import { UseFormRegisterReturn } from "react-hook-form"

interface InputfeltTallProps extends InputProps {
  register: UseFormRegisterReturn<string>
}

const InputfeltTall = (props: InputfeltTallProps) => {
  const { name, variant, register } = props
  return (
    <Flex w="fit-content" gap={8}>
      <Stack>
        <InputGroup>
          <NumberInputRoot min={0} variant={variant}>
            <HStack>
              <NumberInputField
                {...register}
                value={name}
                w="fit-content"
                css={{
                  _disabled: {
                    color: "black",
                  },
                }}
              />
            </HStack>
          </NumberInputRoot>
        </InputGroup>
      </Stack>
    </Flex>
  )
}

export default InputfeltTall
