import { Box, Flex, PopoverHeader } from "@kvib/react"
import { useTranslation } from "react-i18next"
import PopoverikonMedTooltip from "../../../../core/components/PopoverikonMedTooltip"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"
import VerdiMedForklaring from "../../../components/VerdiMedForklaring"
import { Bygning, Bygningsnøkkelinfo } from "../types/Bygning"

const Bygningsstatus = ({
  bygning,
  attributt,
}: {
  bygning: Bygning
  attributt: Bygningsnøkkelinfo
}) => {
  const { t } = useTranslation(["nøkkelbegreper", "bygningskort"])
  const bygningsstatuser = attributt
    .evaluate(bygning)
    ?.toString()
    .split(",")
    .map(item => item.trim())
    .filter(item => item)
  const [nåværendeStatus, tidligereStatuser] = bygningsstatuser
    ? [bygningsstatuser[0], bygningsstatuser.slice(1)]
    : [t("ikke_registrert", { ns: "nøkkelbegreper" }), []]

  const popoverContent = (
    <>
      <PopoverHeader pl={0} pt="0">
        <b>{t("historiske_bygningsstatuser_overskrift", { ns: "bygningskort" })}</b>
      </PopoverHeader>
      {tidligereStatuser.length === 0
        ? t("ingen_historiske_bygningsstatuser", { ns: "bygningskort" })
        : tidligereStatuser.map((status, index) => <Box key={status + index}>{status}</Box>)}
    </>
  )

  return (
    <VerdiMedForklaring
      label={attributt.title}
      posthogName="bygningsstatus_info_clicked"
      forklaring={attributt.forklaringAvAttributt}
    >
      <Flex alignItems="center" p={0}>
        <Brødtekst fontWeight="bold">{nåværendeStatus}</Brødtekst>
        <PopoverikonMedTooltip
          tooltipLabel={t("historiske_bygningsstatuser_tooltip", { ns: "bygningskort" })}
          tooltipAriaLabel={t("historiske_bygningsstatuser_arialabel", {
            ns: "bygningskort",
          })}
          iconButtonAriaLabel={t("historiske_bygningsstatuser_arialabel", {
            ns: "bygningskort",
          })}
          icon="history"
        >
          {popoverContent}
        </PopoverikonMedTooltip>
      </Flex>
    </VerdiMedForklaring>
  )
}

export default Bygningsstatus
