import { apiRoute } from "../../../environments"
import { ApiError } from "../../core/error-handling/ApiError"
import { ErrorResponse } from "../../shared-api/types/ApiResponseTypes"
import { Kildemateriale } from "../registrering/types/Enums"

export const kodelisterBygningRouter = {
  hentKodelisterBygning: async () => {
    const kodelisterBygning = await fetch(`${apiRoute}/kildematerialkode`)

    if (!kodelisterBygning.ok) {
      const body: ErrorResponse = await kodelisterBygning.json()
      throw new ApiError(body)
    }

    const kildematrialekodeliste: Array<Kildemateriale> = await kodelisterBygning.json()

    return kildematrialekodeliste
  },
}
