import { useScreenView } from "../hooks/useScreenview"

type FontSizeMap = {
  [key in ScreenView]: string
}

type FontSize = {
  h1: FontSizeMap
  h2: FontSizeMap
  h3: FontSizeMap
  h4: FontSizeMap
  Brødtekst: FontSizeMap
  Lenke: FontSizeMap
  LitenBrødtekst: FontSizeMap
}

type FontWeightMap = {
  [key in ScreenView]: string
}

type FontWeight = {
  h1: FontWeightMap
  h2: FontWeightMap
  h3: FontWeightMap
  h4: FontWeightMap
  Brødtekst: FontWeightMap
  LitenBrødtekst: FontWeightMap
  Lenke: FontWeightMap
}

const FontSizeValues: FontSize = {
  h1: { Desktop: "3xl", Mobile: "2xl", Tablet: "2xl" },
  h2: { Desktop: "2xl", Mobile: "xl", Tablet: "xl" },
  h3: { Desktop: "xl", Mobile: "lg", Tablet: "lg" },
  h4: { Desktop: "md", Mobile: "md", Tablet: "md" },
  Brødtekst: { Desktop: "md", Mobile: "md", Tablet: "md" },
  LitenBrødtekst: { Desktop: "sm", Mobile: "sm", Tablet: "sm" },
  Lenke: { Desktop: "md", Mobile: "md", Tablet: "md" },
}

const FontWeightValues: FontWeight = {
  h1: { Desktop: "bold", Mobile: "bold", Tablet: "bold" },
  h2: { Desktop: "bold", Mobile: "bold", Tablet: "bold" },
  h3: { Desktop: "bold", Mobile: "bold", Tablet: "bold" },
  h4: { Desktop: "bold", Mobile: "bold", Tablet: "bold" },
  Brødtekst: { Desktop: "regular", Mobile: "regular", Tablet: "regular" },
  LitenBrødtekst: { Desktop: "regular", Mobile: "regular", Tablet: "regular" },
  Lenke: { Desktop: "regular", Mobile: "regular", Tablet: "regular" },
}

export const useFontStyles = () => {
  const screenView = useGetCurrentScreenview()

  const getFontSize = (fontSizeType: keyof FontSize): string => {
    return FontSizeValues[fontSizeType][screenView]
  }

  const getFontWeight = (fontWeightType: keyof FontWeight): string => {
    return FontWeightValues[fontWeightType][screenView]
  }

  return {
    getFontSize,
    getFontWeight,
  }
}

interface ScreenTypes {
  Desktop: "Desktop"
  Tablet: "Tablet"
  Mobile: "Mobile"
}

type ScreenView = keyof ScreenTypes

const useGetCurrentScreenview = (): ScreenView => {
  const { isDesktop, isMobile, isTablet } = useScreenView()

  if (isDesktop) {
    return "Desktop"
  } else if (isTablet) {
    return "Tablet"
  } else if (isMobile) {
    return "Mobile"
  } else {
    return "Mobile"
  }
}
