import { Box, Button, Popover, PopoverTrigger } from "@kvib/react"
import { usePostHog } from "posthog-js/react"
import { ReactElement, useRef } from "react"
import PopoverInfo from "./PopoverInfo"
import { Brødtekst } from "../../core/text/components/Brødtekst"

interface Props {
  label: string
  forklaring: string
  posthogName?: string
  children: ReactElement
}

const VerdiMedForklaring = ({ label, forklaring, posthogName, children }: Props) => {
  const posthog = usePostHog()

  const elementRef = useRef<HTMLButtonElement>(null)

  return (
    <Popover colorPalette="blue">
      <Box
        onClick={e => {
          e.stopPropagation()
          if (posthogName) posthog.capture(posthogName)
        }}
        onKeyDown={e => {
          e.stopPropagation()
          if (posthogName) posthog.capture(posthogName)
        }}
        w="fit-content"
      >
        <PopoverTrigger asChild colorPalette="green">
          <Button
            variant="ghost"
            alignItems="center"
            p="1"
            m="-1"
            h="auto"
            ref={elementRef}
            rightIcon="info"
            _hover={{
              // TODO: Skal vi bare fjerne denne ikon-fillen på hover?
              "& > div > span > div > span:last-of-type": {
                fontVariationSettings: "'FILL' 1 !important",
              },
            }}
          >
            <Brødtekst litenVariant color="gray.500" lineHeight="20px">
              {label}
            </Brødtekst>
          </Button>
        </PopoverTrigger>
        {children}
        <PopoverInfo>{forklaring}</PopoverInfo>
      </Box>
    </Popover>
  )
}

export default VerdiMedForklaring
