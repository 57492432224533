import { Button, Icon } from "@kvib/react"
import { useQueryErrorResetBoundary } from "@tanstack/react-query"
import i18n from "i18next"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useRouteError } from "react-router"
import faro from "../../../faro-config"
import { useHandleLogin } from "../../auth/hooks/useHandleLogin"
import { useScreenView } from "../hooks/useScreenview"
import { ApiError } from "./ApiError"
import { ErrorDisplay } from "./ErrorDisplay"
import FinnerIkkeEiendommer from "./FinnerIkkeEiendommer"

const ErrorHandler = ({ apiError }: { apiError?: ApiError }) => {
  const { isDesktop } = useScreenView()
  const iconSize = isDesktop ? 200 : 100
  const iconColor = "#6C79804D"

  const handleLogin = useHandleLogin()
  const routerError = useRouteError() as ApiError
  const { t } = useTranslation("errormeldinger")

  const error = apiError || routerError

  const { reset } = useQueryErrorResetBoundary()
  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      faro.api.pushError(error)
    }
  }, [error])

  switch (error.type) {
    case "IKKE_AUTENTISERT":
      return (
        <ErrorDisplay
          alertTitle={getErrorTitle(error)}
          alertMessage={error.userErrorMessage}
          icon={<Icon ariaIsHidden size={iconSize} color={iconColor} icon="update" />}
          actionButton={
            <Button
              onClick={() => {
                handleLogin()
                reset()
              }}
            >
              {t("logg_inn_på_nytt")}
            </Button>
          }
        />
      )
    case "FANT_IKKE_INNHOLD":
      return (
        <ErrorDisplay
          alertTitle={getErrorTitle(error)}
          alertMessage={error.userErrorMessage}
          icon={<Icon ariaIsHidden size={iconSize} color={iconColor} icon="search_off" />}
          actionButton={
            <Button
              onClick={() => {
                reset()
                navigate("/")
              }}
            >
              {t("tilbake_til_start")}
            </Button>
          }
        />
      )
    case "EGENREGISTRERING_IKKE_TILGJENGELIG":
      return (
        <ErrorDisplay
          alertTitle={getErrorTitle(error)}
          alertMessage={error.userErrorMessage}
          icon={<Icon ariaIsHidden size={iconSize} color={iconColor} icon="house_with_shield" />}
          actionButton={
            <Button
              onClick={() => {
                reset()
                navigate("/")
              }}
            >
              {t("tilbake_til_start")}
            </Button>
          }
        />
      )
    case "FANT_INGEN_EIENDOMMER":
      return <FinnerIkkeEiendommer errorType={error.type} />

    case "FANT_IKKE_PERSON":
      return <FinnerIkkeEiendommer errorType={error.type} />

    case "UVENTET_FEIL":
      return (
        <ErrorDisplay
          alertTitle={getErrorTitle(error)}
          alertMessage={error.userErrorMessage}
          icon={<Icon ariaIsHidden icon="error" color={iconColor} size={iconSize} />}
        />
      )
    default:
      return (
        <ErrorDisplay
          alertTitle={getErrorTitle(error)}
          alertMessage={t("ukjent_feil_melding")}
          icon={<Icon ariaIsHidden icon="error" color={iconColor} size={iconSize} />}
        />
      )
  }
}

const getErrorTitle = (errorType: ApiError): string => {
  switch (errorType.type) {
    case "IKKE_AUTENTISERT":
      return i18n.t("utløpt_session", { ns: "errormeldinger" })
    case "UVENTET_FEIL":
      return i18n.t("intern_systemfeil", { ns: "errormeldinger" })
    case "FANT_IKKE_INNHOLD":
      return i18n.t("fant_ikke_innhold_tittel", { ns: "errormeldinger" })
    case "EGENREGISTRERING_IKKE_TILGJENGELIG":
      return "Egenregistering er ikke tilgjengelig"
    default:
      return i18n.t("ukjent_feil", { ns: "errormeldinger" })
  }
}

export default ErrorHandler
