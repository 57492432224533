export const hentBygningstype = {
  "1": "Bolig",
  "2": "Industri og lagerbygning",
  "3": "Kontor- og forretningsbygning",
  "4": "Samferdsels- og kommunikasjonsbygning",
  "5": "Hotell-  og restaurantbygning",
  "6": "Kultur- og forskningsbygning",
  "7": "Helsebygning",
  "8": "Fengsel, beredskapsbygning mv.",
  "11": "Enebolig",
  "12": "Tomannsbolig",
  "13": "Rekkehus, kjedehus, andre småhus",
  "14": "Stort boligbygg",
  "15": "Bygning for bofellesskap",
  "16": "Fritidsbolig",
  "17": "Koie, seterhus og lignende",
  "18": "Garasje/uthus til bolig",
  "19": "Annen boligbygning",
  "21": "Industribygning",
  "22": "Energiforsyningsbygning",
  "23": "Lagerbygning",
  "24": "Fiskeri- og landbruksbygning",
  "31": "Kontorbygning",
  "32": "Forretningsbygning",
  "41": "Ekspedisjonsbygning, terminal",
  "42": "Telekommunikasjonsbygning",
  "43": "Garasje- og hangarbygning",
  "44": "Veg- og trafikktilsynsbygning",
  "51": "Hotellbygning",
  "52": "Bygning for overnatting",
  "53": "Restaurantbygning",
  "61": "Skolebygning",
  "62": "Universitet- og høgskolebygning",
  "64": "Museums- og biblioteksbygning",
  "65": "Idrettsbygning",
  "66": "Kulturhus",
  "67": "Bygning for religiøse aktiviteter",
  "71": "Sykehus",
  "72": "Sykehjem",
  "73": "Primærhelsebygning",
  "81": "Fengselsbygning",
  "82": "Beredskapsbygning",
  "83": "Monument",
  "84": "Offentlig toalett",
  "111": "Enebolig",
  "112": "Enebolig med hybelleilighet, sokkelleilighet o.l.",
  "113": "Våningshus",
  "121": "Tomannsbolig, vertikaldelt",
  "122": "Tomannsbolig, horisontaldelt",
  "123": "Våningshus, tomannsbolig, vertikaldelt",
  "124": "Våningshus, tomannsbolig, horisontaldelt",
  "131": "Rekkehus",
  "133": "Kjedehus inkl. atriumhus",
  "135": "Terrassehus",
  "136": "Andre småhus med 3 boliger eller flere",
  "141": "Store frittliggende boligbygg på 2 etasjer",
  "142": "Store frittliggende boligbygg på 3 og 4 etasjer",
  "143": "Store frittliggende boligbygg på 5 etasjer eller over",
  "144": "Store sammenbygde boligbygg på 2 etasjer",
  "145": "Store sammenbygde boligbygg på 3 og 4 etasjer",
  "146": "Store sammenbygde boligbygg på 5 etasjer og over",
  "151": "Bo- og servicesenter",
  "152": "Studenthjem/studentboliger",
  "159": "Annen bygning for bofellesskap",
  "161": "Fritidsbygning (hytter, sommerhus o.l.)",
  "162": "Helårsbolig benyttet som fritidsbolig",
  "163": "Våningshus benyttet som fritidsbolig",
  "171": "Seterhus, sel, rorbu o.l.",
  "172": "Skogs- og utmarkskoie, gamme",
  "181": "Garasje, uthus, anneks knyttet til bolig",
  "182": "Garasje, uthus, anneks knyttet til fritidsbolig",
  "183": "Naust, båthus, sjøbu",
  "193": "Boligbrakker",
  "199": "Annen boligbygning (f.eks. sekundærbolig reindrift)",
  "211": "Fabrikkbygning",
  "212": "Verkstedbygning",
  "214": "Bygning for renseanlegg",
  "216": "Bygning for vannforsyning, bl.a. pumpestasjon",
  "219": "Annen industribygning",
  "221": "Kraftstasjon (>15 000 kVA)",
  "223": "Transformatorstasjon (>10 000 kVA)",
  "229": "Annen energiforsyningsbygning",
  "231": "Lagerhall",
  "232": "Kjøle- og fryselager",
  "233": "Silobygning",
  "239": "Annen lagerbygning",
  "241":
    "Hus for dyr, fôrlager, strølager, frukt- og grønnsakslager, landbrukssilo, høy-/korntørke",
  "243": "Veksthus",
  "244": "Driftsbygning for fiske og fangst, inkl. oppdrettsanlegg",
  "245": "Naust/redskapshus for fiske",
  "248": "Annen fiskeri- og fangstbygning",
  "249": "Annen landbruksbygning",
  "311": "Kontor- og administrasjonsbygning, rådhus",
  "312": "Bankbygning, posthus",
  "313": "Mediebygning",
  "319": "Annen kontorbygning",
  "321": "Kjøpesenter, varehus",
  "322": "Butikkbygning",
  "323": "Bensinstasjon",
  "329": "Annen forretningsbygning",
  "330": "Messe- og kongressbygning",
  "411": "Ekspedisjonsbygning, flyterminal, kontrolltårn",
  "412": "Jernbane- og T-banestasjon",
  "415": "Godsterminal",
  "416": "Postterminal",
  "419": "Annen ekspedisjons- og terminalbygning",
  "429": "Telekommunikasjonsbygning",
  "431": "Parkeringshus",
  "439": "Annen garasje- hangarbygning",
  "441": "Trafikktilsynsbygning",
  "449": "Annen veg- og trafikktilsynsbygning",
  "511": "Hotellbygning",
  "512": "Motellbygning",
  "519": "Annen hotellbygning",
  "521": "Hospits, pensjonat",
  "522": "Vandrerhjem, feriehjem/-koloni, turisthytte",
  "523": "Appartement",
  "524": "Campinghytte/utleiehytte",
  "529": "Annen bygning for overnatting",
  "531": "Restaurantbygning, kafébygning",
  "532": "Sentralkjøkken, kantinebygning",
  "533": "Gatekjøkken, kioskbygning",
  "539": "Annen restaurantbygning",
  "611": "Lekepark",
  "612": "Barnehage",
  "613": "Barneskole",
  "614": "Ungdomsskole",
  "615": "Kombinert barne- og ungdomsskole",
  "616": "Videregående skole",
  "619": "Annen skolebygning",
  "621": "Universitets- og høgskolebygning med integrerte funksjoner, auditorium, lesesal o.a.",
  "623": "Laboratoriebygning",
  "629": "Annen universitets-, høgskole- og forskningsbygning",
  "641": "Museum, kunstgalleri",
  "642": "Bibliotek, mediatek",
  "643": "Zoologisk og botanisk hage",
  "649": "Annen museums- og bibliotekbygning",
  "651": "Idrettshall",
  "652": "Ishall",
  "653": "Svømmehall",
  "654": "Tribune og idrettsgarderobe",
  "655": "Helsestudio",
  "659": "Annen idrettsbygning",
  "661": "Kinobygning, teaterbygning, opera/konserthus",
  "662": "Samfunnshus, grendehus",
  "663": "Diskotek",
  "669": "Annet kulturhus",
  "671": "Kirke, kapell",
  "672": "Bedehus, menighetshus",
  "673": "Krematorium, gravkapell, bårehus",
  "674": "Synagoge, moské",
  "675": "Kloster",
  "679": "Annen bygning for religiøse aktiviteter",
  "719": "Sykehus",
  "721": "Sykehjem",
  "722": "Bo- og behandlingssenter, aldershjem",
  "723": "Rehabiliteringsinstitusjon, kurbad",
  "729": "Annet sykehjem",
  "731": "Klinikk, legekontor/-senter/-vakt",
  "732": "Helse- og sosialsenter, helsestasjon",
  "739": "Annen primærhelsebygning",
  "819": "Fengselsbygning",
  "821": "Politistasjon",
  "822": "Brannstasjon, ambulansestasjon",
  "823": "Fyrstasjon, losstasjon",
  "824": "Stasjon for radarovervåkning av fly- og/eller skipstrafikk",
  "825": "Tilfluktsrom/bunker",
  "829": "Annen beredskapsbygning",
  "830": "Monument",
  "840": "Offentlig toalett",
} as const

export type Bygningstypekode = keyof typeof hentBygningstype
