import { formaterMedKommaOgOg } from "../../../../../utils/formateringUtils"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"
import VerdiMedForklaring from "../../../components/VerdiMedForklaring"
import { Bygning, Bygningsnøkkelinfo } from "../types/Bygning"

export const FelterIBygningskort = ({
  attributt,
  bygning,
}: {
  attributt: Bygningsnøkkelinfo
  bygning: Bygning
}) => {
  const verdier = attributt.evaluate(bygning)
  const formattedVerdier = Array.isArray(verdier) ? formaterMedKommaOgOg(verdier) : verdier

  return (
    <VerdiMedForklaring label={attributt.title} forklaring={attributt.forklaringAvAttributt}>
      <Brødtekst
        className={attributt.id === "bygningsnummer" ? "ph-no-capture" : undefined}
        fontWeight="bold"
      >
        {formattedVerdier}
      </Brødtekst>
    </VerdiMedForklaring>
  )
}
