import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@kvib/react"
import posthog from "posthog-js"
import { Brødtekst } from "../../core/text/components/Brødtekst"

interface NøstetAccordionProps {
  items: { tittel: string; avsnitt: string[] }[]
}

export const NøstetAccordion = ({ items }: NøstetAccordionProps) => (
  <Accordion collapsible bg="white" mt="12px">
    {items.map(item => (
      <AccordionItem
        key={item.tittel}
        value={item.tittel}
        onClick={() => posthog.capture(item.tittel)}
      >
        <AccordionButton>
          <Box flex="1" textAlign="left">
            {item.tittel}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {item.avsnitt.map(avsnitt => (
            <Brødtekst key={avsnitt}>{avsnitt}</Brødtekst>
          ))}
        </AccordionPanel>
      </AccordionItem>
    ))}
  </Accordion>
)
