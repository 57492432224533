import { Flex, FlexProps, Icon, Stack } from "@kvib/react"
import { useScreenView } from "../../../../core/hooks/useScreenview"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../../core/text/components/Overskrift"
import { Vegadresse } from "../../../eiendomsoversikt/types/Eiendom"
import { formaterFlereVegadresserForBygning } from "../../../eiendomsoversikt/utils/adresseForEiendomUtils"
import { bygningstypeIkonMapper } from "../../../eiendomsoversikt/utils/eiendomUtils"
import { Bygningstypekode, hentBygningstype } from "../utils/bygningstypeUtil"

interface Props extends FlexProps {
  bygningstype: string
  vegadresse?: string | Vegadresse[]
  erSeksjon: boolean
  bruksenhetsnummer?: string
}

const BygningstypeOgVegadresseOverskrift = ({
  erSeksjon,
  bruksenhetsnummer,
  bygningstype,
  vegadresse,
  ...props
}: Props) => {
  const { isDesktop } = useScreenView()
  const iconSize = isDesktop ? 50 : 45
  const iconWrapper = `${iconSize + 6}px`

  const title = erSeksjon
    ? `Seksjon i ${hentBygningstype[bygningstype.substring(0, 2) as Bygningstypekode].toLowerCase()}`
    : hentBygningstype[bygningstype.substring(0, 2) as Bygningstypekode]

  return (
    <Flex gap="12px" alignItems="center" w="100%" {...props}>
      <Flex
        bg="green.100"
        borderRadius="50%"
        minW={iconWrapper}
        maxW={iconWrapper}
        minH={iconWrapper}
        maxH={iconWrapper}
        justifyContent="center"
      >
        <Icon
          icon={bygningstypeIkonMapper(bygningstype.substring(0, 2))}
          size={iconSize}
          color="#156630"
          ariaIsHidden
        />
      </Flex>
      <Stack gap="2px" textAlign="left">
        <Overskrift
          variant="h3"
          lineClamp={4}
          whiteSpace="normal"
          wordBreak="break-word"
          h="fit-content"
        >
          {title}
        </Overskrift>
        <Brødtekst className="ph-no-capture" litenVariant>
          {Array.isArray(vegadresse) ? formaterFlereVegadresserForBygning(vegadresse) : vegadresse}
          {erSeksjon && bruksenhetsnummer ? `, ${bruksenhetsnummer}` : ""}
        </Brødtekst>
      </Stack>
    </Flex>
  )
}

export default BygningstypeOgVegadresseOverskrift
