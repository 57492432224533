const keys = {
  "nb-NO": {
    tab_tittel: "Hjelp og støtte",
    hva_betyr_datafeltene: {
      tittel: "Hva betyr datafeltene?",
      avsnitt: [
        "Hva betyr for eksempel bruksenhetstype eller matrikkelnummer? Her finner du ordforklaringer og veiledning om hva de ulike datafeltene betyr.",
      ],
    },
    les_mer_om_datafeltene: "Les mer om datafeltene",

    overskrift: "Hjelp og støtte",

    om_utvalget: {
      tittel: "Hvilke eiendommer finner jeg på Min eiendom?",
      avsnitt: [
        "Eiendommene som vises i løsningen er de du er registrert som eier eller fester av i Eiendomsregisteret (matrikkelen). Tinglyste eierforhold er hentet fra grunnboken. Per dags dato vil ikke borettslagsandeler og aksjeleiligheter vises.",
        "Tjenesten er fortsatt under utvikling og utvalget kan endres i fremtiden.",
      ],
    },
    mer_om_eiendomsregisteret_link: "Les mer om Eiendomsregisteret (matrikkelen)",

    kartutsnitt_eiendommen: {
      tittel: "Kartutsnitt av eiendommen",
      avsnitt: [
        "Grensene kan ha ulik nøyaktighet og det kan være feil og mangler i det du ser som din eiendom. Hvis du ikke ser kartutsnitt kan det være fordi eiendommen mangler teig.",
      ],
    },
    mer_om_eiendomsgrenser_link: "Les mer om eiendomsgrenser",
    matrikkelenhetstype: {
      tittel: "Matrikkelenhetstype",
      avsnitt: [
        "Matrikkelenhetstype sier noe om eiendommens egenskaper. Det finnes fem ulike matrikkelenhetstyper: grunneiendom, festegrunn, eierseksjon, anleggseiendom og jordsameie.",
      ],
    },
    bruksnavn: {
      tittel: "Bruksnavn",
      avsnitt: [
        "Noen eiendommer har et bruksnavn. Navnet kan komme fra reguleringsplan, utbygger, nåværende eller tidligere eier.",
      ],
    },
    mer_om_bruksnavn_link: "Les mer om stedsnavn og bruksnavn",
    adresse: {
      tittel: "Adresse",
      avsnitt: ["Adressen er den/de offisielle vegadressen(e) til bygningen(e) på eiendommen."],
    },
    matrikkelnummer: {
      tittel: "Matrikkelnummer",
      avsnitt: [
        "Matrikkelnummeret er et unikt nummer som består av kommunenummer, gårdsnummer, bruksnummer, og eventuelt festenummer og/eller seksjonsnummer.",
        "For å se mer av informasjonen som ligger i Eiendomsregisteret (matrikkelen) kan du ta kontakt med kommunen din og bestille matrikkelbrev. Eller du kan søke opp eiendommen din på seeiendom.kartverket.no",
      ],
    },
    feilOgMangler: "Feil og mangler",
    feilOgManglerForklaringer: [
      {
        tittel: "En eller flere eiendommer mangler",
        avsnitt: [
          "Det kan være flere grunner til at eiendommen din ikke vises.<br />",
          "<li><strong>Du er ikke registrert eier eller fester.</strong> Hvis du ikke er registrert eier eller fester i Eiendomsregisteret (matrikkelen) vil ikke eiendommen dukke opp.</li>",
          "<li><strong>Du eier en leilighet i et borettslag eller et boligselskap.</strong> Borettslagsandeler og aksjeleiligheter er ikke registrert i Eiendomsregisteret (matrikkelen).</li>",
          "<li><strong>Eiendommen eies ikke av en privatperson.</strong> Min Eiendom er for øyeblikket ikke tilgjengelig for juridiske rettssubjekter. Det vil si selskaper, foreninger og organisasjoner.</li>",
          "<li><strong>Du har nettopp kjøpt eller overtatt eiendom.</strong> Har du nylig kjøpt eller overtatt en eiendom kan det ta tid før Eiendomsregisteret (matrikkelen) er oppdatert.</li>",
          "<li><strong>Du er registrert med løpenummer, ikke fødselsnummer, i grunnboken.</strong> Løpenummer ble historisk brukt i grunnboken for manglende fødselsnummer, særlig i tiden før 1964. Dersom du tror dette gjelder for deg, kan du ta kontakt med kundesenteret.</li>",
        ],
        lenker: [
          {
            tekst: "Les mer om hvordan du tar kontakt med Kartverket",
            lenke: "https://www.kartverket.no/om-kartverket/kontakt-oss",
          },
        ],
      },
      {
        tittel: "Opplysningene om eiendommen stemmer ikke",
        avsnitt: [
          "Hvis du ser feil i eiendomsinformasjonen, for eksempel adresse, grenser, eller bygningsopplysninger kan du be om retting hos kommunen. Hvorvidt feilen kan rettes eller ikke, kravene til dokumentasjon og kostnad for retting, reguleres blant annet av matrikkelloven § 26.",
          "Ta kontakt med kommunen hvor eiendommen ligger for spørsmål om dette.",
        ],
        lenker: [
          {
            tekst: "Les mer om hva du kan gjøre hvis du ser feil i eiendomsopplysningene",
            lenke:
              "https://www.kartverket.no/eiendom/eiendomsgrenser/matrikkelen-norgeseiendomsregister",
          },
        ],
      },
      {
        tittel: "Jeg ser eiendommer jeg ikke eier",
        avsnitt: [
          "Hvis du ser flere eiendommer enn du eier bør du først sjekke om du er registrert som tinglyst eier eller fester. Dette kan du finne ut av ved å søke opp eiendommen på seeiendom.kartverket.no.",
          "Dersom du er registrert som tinglyst eier eller fester, og dette ikke stemmer, kan du ta kontakt med kundesenteret for å få hjelp. Merk at om du nylig har solgt eiendommen kan det ta en stund før Min eiendom oppdateres.",
          "Dersom du er registrert som tinglyst eier eller fester, og dette ikke stemmer, kan du endre eierskapet ved tinglysing.",
          "Merk at om du nylig har solgt eiendommen kan det ta en stund før Min eiendom oppdateres.",
        ],
        lenker: [
          {
            tekst: "Les mer om hvordan du tinglyser endring av eierskap",
            lenke: "https://www.kartverket.no/eiendom/overfore-eiendom",
          },
        ],
      },

      {
        tittel: "Eiendommen er oppført med flere adresser",
        avsnitt: [
          "Mulige årsaker til at du ser flere adresser kan være at bygningen har flere innganger eller at det er flere bygninger på eiendommen som skal ha sin egen adresse.",
        ],
        lenker: [
          { tekst: "Les mer om adresser", lenke: "https://www.kartverket.no/eiendom/adressering" },
        ],
      },
      {
        tittel: "Eiendommen mangler adresse",
        avsnitt: [
          "Hvis det står at eiendommen ikke har adresse betyr dette at eiendommen ikke er tildelt en offisiell vegadresse. Mulige årsaker kan være at eiendommen ikke er bebygd eller ligger i et område uten veier.",
        ],
        lenker: [
          { tekst: "Les mer om adresser", lenke: "https://www.kartverket.no/eiendom/adressering" },
        ],
      },
    ],
    mer_om_matrikkelbrev_link: "Les mer om matrikkelbrev",
    eiendomsareal: {
      tittel: "Eiendomsareal",
      avsnitt: [
        "Arealet beregnes på grunnlag av registrerte eiendomsgrenser i matrikkelen. Det kan være mangler i grenseinformasjonen som gjør at arealet ikke stemmer, er usikkert eller det ikke er mulig å beregne areal. For eierseksjoner uten registrert eksklusivt uteareal vil det ikke være areal.",
      ],
    },
    eierandel: {
      tittel: "Eierandel",
      avsnitt: [
        "En eierandel sier hvor mye av en eiendom du er registrert tinglyst eier (eller fester) av. Eierandelen er oppgitt som en brøk der 1/1 betyr at du eier 100% av eiendommen. Eierandelen hentes fra grunnboken. Hvis eierforholdet ikke er tinglyst vil ikke eierandelene vises.",
      ],
    },
    bygningstyper: {
      tittel: "Bygningstyper på eiendommen",
      avsnitt: [
        "På detaljsiden for eiendommen din kan du se de ulike type bygninger som finnes på eiendommen. Bygningstypen er definert utifra bygningens hovedfunksjon. Dette defineres i Norsk standard for bygningstype (NS3457:1995). For bygninger med flere typer bruk, vil den største delen av bygningen som regel definere bygningstypen.",
      ],
    },
    får_opp_feil_eiendommer: {
      tittel: "Eiendommene jeg får opp er feil",
      avsnitt: [
        "Hvis du ser flere eiendommer enn du eier, eller noen av dine eiendommer mangler, må du først finne ut om eiendommen er tinglyst eller ikke. Dette kan du finne ut av ved å søke opp eiendommen på seeiendom.kartverket.no.",
        "Hvis eiendommen er tinglyst - ",
        "Hvis eiendommen ikke er tinglyst - ",
      ],
    },
    mer_om_overføring_eiendom_link: "Les mer om overføring av fast eiendom",
    mer_om_føring_av_eier_link: "Les mer om føring av eier og fester",
    kontakt_kundesenter_feil_eiendom_link:
      "Ta kontakt med kundesenteret hvis du ser feil eiendommer",
    feil_opplysninger_om_eiendom: {
      tittel: "Opplysningene om eiendommen er feil",
      avsnitt: [
        "Hvis du ser feil i eiendomsinformasjonen, for eksempel adresse, grenser, eller bygningsopplysninger kan du kreve retting hos kommunen ",
        ". For at kommunen skal behandle kravet må du kunne dokumentere opplysningene du ønsker å endre. Kravene til dokumentasjonen kan variere avhengig av hvilke opplysninger du ønsker å rette. Hver sak må vurderes konkret av den aktuelle kommunen.",
        "Ta kontakt med kommunen hvor eiendommen ligger for spørsmål om dette.",
      ],
    },
    matrikkellov_par_26_link: "(se matrikkelloven § 26)",
    fant_du_ikke_svar: {
      tittel: "Fant du ikke svar på det du lurte på eller har du tilbakemeldinger?",
      avsnitt: [
        "Du kan finne mer informasjon om Eiendomsregisteret (matrikkelen) på våre nettsider.",
        "Ta kontakt med kundesenteret vårt dersom du har spørsmål eller tilbakemeldinger til løsningen. ",
      ],
    },
    kundesenteret_link: "Kontaktinformasjon og åpningstider for kundesenteret",
    kontakt_kundesenteret: {
      tittel: "Kontakt kundesenteret",
      avsnitt: [
        "Ta kontakt med kundesenteret vårt dersom du har spørsmål eller tilbakemeldinger til Min eiendom.",
      ],
    },
    kontakt_kundesenteret_aria: "Kontakt kundesenteret",
    gaa_til_seeiendom_aria: "Gå til seeiendom.kartverket.no",
    leter_du_etter_annen_eiendom: {
      tittel: "Ønsker du opplysninger om andre eiendommer?",
      avsnitt: [
        "På Se eiendom kan du søke opp alle eiendommer og se offentlige opplysninger. Her vil du også finne opplysninger om næringseiendommer og borettslag.",
      ],
    },
  },

  "nn-NO": {
    tab_tittel: "Hjelp og støtte",
    hva_betyr_datafeltene: {
      tittel: "Kva tyder datafelta?",
      avsnitt: [
        "Kva tyder til dømes bruksenheitstype eller matrikkelnummer? Les meir om kva datafelta som kan dukke opp for eigedomen din tyder.",
      ],
    },
    les_mer_om_datafeltene: "Les meir om datafelta",
    overskrift: "Hjelp og støtte",
    om_utvalget: {
      tittel: "Om utvalet",
      avsnitt: [
        "Eigedomane og opplysningane om dei er henta frå Eigedomsregisteret (matrikkelen) som inneheld informasjon om eigedomsgrenser, areal, bygningar, bustader og adresser.",
        "Eigedomane som vert viste i løysinga er dei du er registrert som eigar eller festar av i Eigedomsregisteret (matrikkelen). Burettslagsandelar og aksjeleilegheiter vert ikkje viste i dagens løysing. Tenesta er under utvikling og utvalet kan bli endra.",
      ],
    },
    mer_om_eiendomsregisteret_link: "Les meir om Eigedomsregisteret (matrikkelen)",
    kartutsnitt_eiendommen: {
      tittel: "Kartutsnitt av eigedommen",
      avsnitt: [
        "Grensene kan ha ulik nøyaktigheit og det kan vere feil og manglar i det du ser som din eigedom. Om du ikkje ser kartutsnitt kan det vere fordi eigedomen manglar teig.",
      ],
    },
    mer_om_eiendomsgrenser_link: "Les meir om eigedomsgrenser",
    matrikkelenhetstype: {
      tittel: "Matrikkeleiningstype",
      avsnitt: [
        "Matrikkeleiningstypen seier noko om eigedommens eigenskapar. Det finst fem ulike matrikkelenheitstyper: grunneigedom, festegrunn, eigarseksjon, anleggseigedom og jordsameige.",
      ],
    },
    bruksnavn: {
      tittel: "Bruksnamn",
      avsnitt: [
        "Nokre eigedomar har eit bruksnamn. Namnet kan komme frå reguleringsplan, utbyggjar, noverande eller tidlegare eigar.",
      ],
    },
    mer_om_bruksnavn_link: "Les meir om stadnamn og bruksnamn",
    adresse: {
      tittel: "Adresse",
      avsnitt: ["Adressa er den/dei offisielle vegadressa(ne) til bygninga(ne) på eigedomen."],
    },
    matrikkelnummer: {
      tittel: "Matrikkelnummer",
      avsnitt: [
        "Matrikkelnummeret er eit unikt nummer som består av kommunenummer, gardsnummer, bruksnummer, og eventuelt festenummer og/eller seksjonsnummer.",
        "For å sjå meir av informasjonen som ligg i Eigedomsregisteret (matrikkelen) kan du ta kontakt med kommunen din og bestille matrikkelbrev. Eller du kan søkje opp eigedomen din på seeiendom.kartverket.no",
      ],
    },
    feilOgMangler: "Feil og manglar",
    feilOgManglerForklaringer: [
      {
        tittel: "Ein eller fleire eigedomar manglar",
        avsnitt: [
          "Ein eller fleire eigedomar manglar",
          "Det kan vere fleire grunnar til at eigedomen din ikkje visast.",
          "Du er ikkje registrert eigar eller festar. Om du ikkje er registrert eigar eller festar i Eigedomsregisteret (matrikkelen) vil ikkje eigedomen dukke opp.",
          "Du eig ein leilegheit i eit borettslag eller eit bustadselskap. Borettslagsandelar og aksjeleilegheiter er ikkje registrerte i Eigedomsregisteret (matrikkelen).",
          "Du har nett kjøpt eller overteke eigedom. Har du nyleg kjøpt eller overteke ein eigedom kan det ta tid før Eigedomsregisteret (matrikkelen) er oppdatert.",
          "Eigedomen eigast ikkje av ein privatperson. Min Eigedom er for augeblikket ikkje tilgjengeleg for juridiske rettssubjekt. Det vil seie selskap, foreiningar og organisasjonar.",
        ],
      },
      {
        tittel: "Opplysningane om eigedomen stemmer ikkje",
        avsnitt: [
          "Om du ser feil i eigedomsinformasjonen, til dømes adresse, grenser, eller bygningsopplysningar kan du krevje retting hos kommunen (sjå matrikkellova § 26). For at kommunen skal handsame kravet må du kunne dokumentere opplysningane du ønskjer å endre. Krava til dokumentasjonen kan variere avhengig av kva opplysningar du ønskjer å rette. Kvar sak må vurderast konkret av den aktuelle kommunen.",
          "Ta kontakt med kommunen der eigedomen ligg for spørsmål om dette.",
        ],
        lenker: [
          {
            tekst: "Les meir om kva du kan gjere om du ser feil i eigedomsopplysningane",
            lenke:
              "https://www.kartverket.no/eiendom/eiendomsgrenser/matrikkelen-norgeseiendomsregister",
          },
        ],
      },
      {
        tittel: "Eg ser eigedomar eg ikkje eig",
        avsnitt: [
          "Om du ser fleire eigedomar enn du eig bør du først sjekke om du er registrert som tinglyst eigar eller festar. Dette kan du finne ut av ved å søkje opp eigedomen på seeiendom.kartverket.no.",
          "Dersom du er registrert som tinglyst eigar eller festar, og dette ikkje stemmer, kan du ta kontakt med kundesenteret for å få hjelp. Merk at om du nyleg har selt eigedomen kan det ta ei stund før Min eigedom vert oppdatert.",
          "Dersom du ikkje er tinglyst eigar eller festar men framleis ser eigedomen her, kan du ta kontakt med kundesenteret for å få dette retta.",
        ],
      },
      {
        tittel: "Eigedomen er oppført med fleire adresser",
        avsnitt: [
          "Om du ser fleire eigedomar enn du eig bør du først sjekke om du er registrert som tinglyst eigar eller festar. Dette kan du finne ut av ved å søkje opp eigedomen på http://seeiendom.kartverket.no. ",
          "Dersom du er registrert som tinglyst eigar eller festar, og dette ikkje stemmer, kan du ta kontakt med kundesenteret for å få hjelp. Merk at om du nyleg har selt eigedomen kan det ta ei stund før Min eigedom vert oppdatert.",
          "Dersom du ikkje er tinglyst eigar eller festar men framleis ser eigedomen her, kan du ta kontakt med kundesenteret for å få dette retta.",
        ],
      },
      {
        tittel: "Eigedomen manglar adresse",
        avsnitt: [
          "Om det står at eigedomen ikkje har adresse tyder dette at eigedomen ikkje er tildelt ein offisiell vegadresse. Moglege årsaker kan vere at eigedomen ikkje er bygd eller ligg i eit område utan vegar.",
        ],
        lenker: [
          { tekst: "Les meir om adresser", lenke: "https://www.kartverket.no/eiendom/adressering" },
        ],
      },
    ],
    mer_om_matrikkelbrev_link: "Les meir om matrikkelbrev",
    eiendomsareal: {
      tittel: "Eigedomsareal",
      avsnitt: [
        "Arealet vert rekna ut frå registrerte eigedomsgrenser i matrikkelen. Det kan vere manglar i grenseinformasjonen som gjer at arealet ikkje stemmer, er usikkert eller det ikkje er mogleg å rekne ut areal. For eigarseksjonar utan registrert eksklusivt uteareal vil det ikkje vere areal.",
      ],
    },
    eierandel: {
      tittel: "Eigardel",
      avsnitt: [
        "Ein eigardel seier kor mykje av ein eigedom du er registrert tinglyst eigar (eller festar) av. Eigardelen er oppgjeven som ein brøk der 1/1 tyder at du eig 100% av eigedomen. Eigardelen vert henta frå grunnboka. Om eigarforholdet ikkje er tinglyst vil ikkje eigardelane visast.",
      ],
    },
    bygningstyper: {
      tittel: "Bygningstyper på eigedomen",
      avsnitt: [
        "På detaljsida for eigedomen din kan du sjå dei ulike type bygningar som finst på eigedomen. Bygningstypen er definert ut frå bygninga sin hovudfunksjon. Dette vert definert i Norsk standard for bygningstype (NS3457:1995). For bygningar med fleire typar bruk vil den største delen av bygninga som regel definere bygningstypen.",
      ],
    },
    får_opp_feil_eiendommer: {
      tittel: "Eigedomane eg får opp er feil",
      avsnitt: [
        "Om du ser fleire eigedomar enn du eig, eller nokon av dine eigedomar manglar, må du først finne ut om eigedomen er tinglyst eller ikkje. Dette kan du finne ut av ved å søkje opp eigedomen på seeiendom.kartverket.no.",
        "Om eigedomen er tinglyst - ",
        "Om eigedomen ikkje er tinglyst - ",
      ],
    },
    mer_om_overføring_eiendom_link: "Les meir om overføring av fast eigedom",
    mer_om_føring_av_eier_link: "Les meir om føring av eigar og festar",
    kontakt_kundesenter_feil_eiendom_link: "Ta kontakt med kundesenteret om du ser feil eigedomar",
    feil_opplysninger_om_eiendom: {
      tittel: "Opplysningane om eigedomen er feil",
      avsnitt: [
        "Om du ser feil i eigedomsinformasjonen, til dømes adresse, grenser, eller bygningsopplysningar kan du krevje retting hos kommunen ",
        ". For at kommunen skal handsame kravet må du kunne dokumentere opplysningane du ønskjer å endre. Krava til dokumentasjonen kan variere avhengig av kva opplysningar du ønskjer å rette. Kvar sak må vurderast konkret av den aktuelle kommunen.",
        "Ta kontakt med kommunen der eigedomen ligg for spørsmål om dette.",
      ],
    },
    matrikkellov_par_26_link: "(sjå matrikkellova § 26)",
    fant_du_ikke_svar: {
      tittel: "Fann du ikkje svar på det du lurte på eller har du tilbakemeldingar?",
      avsnitt: [
        "Du kan finne meir informasjon om Eigedomsregisteret (matrikkelen) på nettsidene våre.",
        "Ta kontakt med kundesenteret vårt om du har spørsmål eller tilbakemeldingar til løysinga.",
      ],
    },
    kundesenteret_link: "Kontaktinformasjon og opningstider for kundesenteret",
    kontakt_kundesenteret: {
      tittel: "Kontakt kundesenteret",
      avsnitt: [
        "Ta kontakt med kundesenteret vårt dersom du har spørsmål eller tilbakemeldingar til Min eigedom.",
      ],
    },
    kontakt_kundesenteret_aria: "Kontakt kundesenteret",
    gaa_til_seeiendom_aria: "Gå til seeiendom.kartverket.no",
    leter_du_etter_annen_eiendom: {
      tittel: "Leitar du etter nokon andre si eigedom?",
      avsnitt: [
        "På Sjå eigedom kan du søkje opp alle eigedomar og sjå offentlege opplysningar. Her vil du også finne opplysningar om aksjeleilegheiter, næringseigedomar og borettslagsandelar.",
      ],
    },
  },
}

export const hjelpOgStøtte = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
