import { apiRoute } from "../../../../environments"
import { ApiError } from "../../../core/error-handling/ApiError"
import {
  EgenregistreringBruksenhet,
  EgenregistreringBygning,
} from "../types/EgenregistreringBygning"
import {
  AvløpSchema,
  BruksarealSchema,
  ByggeårSchema,
  EnergikilderSchema,
  OppvarmingSchema,
  VannforsyningSchema,
} from "../types/schema"
import { OpprettEgenregistreringDTO } from "./useOpprettEgenregistering"

export const egenregistreringRouter = {
  kanEgenregistrere: () => {
    const url = `${apiRoute}/kanEgenregistrere`

    return new Promise<boolean | null>((resolve, reject) => {
      fetch(url)
        .then(async response => {
          if (!response.ok) {
            resolve(null)
            return
          }
          const kanEgenregistrere: {
            isEnabled: boolean
          } = await response.json()
          resolve(kanEgenregistrere.isEnabled)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  sendEgenregistrering: async <
    T extends
      | ByggeårSchema
      | EnergikilderSchema
      | BruksarealSchema
      | OppvarmingSchema
      | AvløpSchema
      | VannforsyningSchema,
  >(
    dto: OpprettEgenregistreringDTO<T>,
  ) => {
    const url = `${apiRoute}/egenregistrering/${dto.bygningId}`
    const { egenregistrering, key } = dto

    const buildRequestBody = (
      key: Felt,
      bruksenhetId: string,
      egenregistrering:
        | ByggeårSchema
        | EnergikilderSchema
        | BruksarealSchema
        | OppvarmingSchema
        | AvløpSchema
        | VannforsyningSchema,
    ) => {
      // Må håndtere nøstet skjema her, og sørge for at ikke bruksareal-keyen dobles opp
      if (key === "bruksareal" && "bruksareal" in egenregistrering) {
        return {
          bruksenhetId,
          [key]: {
            ...(egenregistrering.bruksareal.etasjeRegistreringer
              ? { etasjeRegistreringer: egenregistrering.bruksareal.etasjeRegistreringer }
              : {}),
            ...(egenregistrering.bruksareal.totaltBruksareal
              ? { totaltBruksareal: egenregistrering.bruksareal.totaltBruksareal }
              : {}),
            kildemateriale: egenregistrering.kildemateriale,
          },
        }
      }

      return {
        bruksenhetId,
        [key]: egenregistrering,
      }
    }

    const body = buildRequestBody(key, dto.bruksenhetId, egenregistrering)

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })

    if (!res.ok) {
      const errorBody = await res.json()
      throw new ApiError(errorBody)
    }

    return res.text()
  },

  hentEgenregistrering: async (bygningId: string) => {
    const url = `${apiRoute}/egenregistrering/${bygningId}`
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!res.ok) {
      const body = await res.json()
      throw new ApiError(body)
    }

    const egenregistrering: EgenregistreringBygning = await res.json()

    return egenregistrering
  },
  hentEgenregistreringBruksenhet: async (bygningId: string, bruksenhetId: string) => {
    const url = `${apiRoute}/egenregistrering/${bygningId}/${bruksenhetId}`
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!res.ok) {
      const body = await res.json()
      throw new ApiError(body)
    }

    const egenregistrering: EgenregistreringBruksenhet = await res.json()

    return egenregistrering
  },
}
