import { Flex, IconProps } from "@kvib/react"
import { formaterStorForbokstav } from "../../../../utils/formateringUtils"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { Felt, IkonForFelt } from "../types/schema"
import CircularIcon from "./skjemakomponenter/CircularIcon"

interface Props extends IconProps {
  felt: Felt
  icon: IkonForFelt
  iconSize?: number
}

export const IkonOgFeltLabel = ({ felt, iconSize, icon }: Props) => {
  return (
    <Flex alignItems="center">
      <CircularIcon iconSize={iconSize} icon={icon} />
      <Overskrift variant="h3">{formaterStorForbokstav(felt)}</Overskrift>
    </Flex>
  )
}
