import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router"
import { ApiError } from "../../../core/error-handling/ApiError"
import { api } from "../../../shared-api/router"
import {
  AvløpSchema,
  BruksarealSchema,
  ByggeårSchema,
  EnergikilderSchema,
  OppvarmingSchema,
  VannforsyningSchema,
} from "../types/schema"
import { toaster } from "@kvib/react"

export interface OpprettEgenregistreringDTO<
  T extends
    | ByggeårSchema
    | EnergikilderSchema
    | BruksarealSchema
    | OppvarmingSchema
    | AvløpSchema
    | VannforsyningSchema,
> {
  bygningId: string
  bruksenhetId: string
  key: Felt
  egenregistrering: T
}

const useOpprettEgenregistrering = <
  T extends
    | ByggeårSchema
    | EnergikilderSchema
    | BruksarealSchema
    | OppvarmingSchema
    | AvløpSchema
    | VannforsyningSchema,
>() => {
  const { bygningId, bruksenhetId } = useParams()
  const queryClient = useQueryClient()
  return useMutation<string, ApiError, OpprettEgenregistreringDTO<T>>({
    mutationFn: api.egenregistrering.sendEgenregistrering,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["egenregistrering-bruksenhet", bygningId!, bruksenhetId!],
      })
    },
    onError: err => {
      toaster.create({ title: err.userErrorMessage, type: "error" })
    },
  })
}

export default useOpprettEgenregistrering
